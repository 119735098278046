import React from 'react'
import '../styles/event.css';
import GitaDhan from '../images/gita-daan-on-gita-jayanti_876565911f58612.jpg';
import radhaRaniKitchen from '../images/radharanikitchen.jpeg';
import calender from '../images/calendar.png';

export default function Events() {
  return (
    <div className='eventdiv'>
            <h1 className='eventheading'>Events</h1>
            <div className="eventrow">
            <div className="eventcolumn">
                    <div className='eventcolumnimage'>
                         <img className='eventimage' src={GitaDhan} alt="GitaDhan"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Gita Daan on Gita Jayanti</h3>
                        <p className='eventpara'>Ladies from all walks of life are welcome to learn more about ancient Vedic philosophy and culture.</p>
                        
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>
                    <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />December 2023</h5>     
            </div>

         

            <div className="eventcolumn">
                     <div className='eventcolumnimage'>
                         <img className='eventimage' src={radhaRaniKitchen} alt="radhaRaniKitchen"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Bhumi Puja</h3>
                        <p className='eventpara'>Bhumi Puja, also known as Bhoomi Poojan, is a traditional vedic ritual performed to seek blessings from Mother Earth before the commencement of any construction project.  </p>
                        
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div>
                    <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />Donation </h5>  
            </div> 

            
            <div className="eventcolumn">
                     <div className='eventcolumnimage'>
                         <img className='eventimage' src={radhaRaniKitchen} alt="radhaRaniKitchen"/>
                    </div>
                    <div className='eventcolumndescription'>
                        <h3>Bhumi Puja</h3>
                        <p className='eventpara'>Bhumi Puja, also known as Bhoomi Poojan, is a traditional vedic ritual performed to seek blessings from Mother Earth before the commencement of any construction project.  </p>
                       
                        {/* <Link to="/" style={{textDecoration:"none"}}>See details ❱> */}
                    </div> 
                    <h5 className='calenderheading'><img src={calender} className='calenderimg' alt="" />Donation </h5> 
            </div> 

            </div>
           
    </div>
  )
}
