import React from 'react'
import proposedplan from '../images/proposed plan.png';
import '../styles/proposedplan.css';

export default function ProposedPlan() {
  return (
    <div className='proposedplandiv'>
        <h1 className='proposedplanheading'>Proposed Plan</h1>
        <img data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000" className='proposedplanimage' src={proposedplan} alt="proposedplan" />
    </div>
  )
}
