import React, { useState, useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";
import "./../styles/socialMedia.css";
import { EffectCoverflow, Pagination } from "swiper/modules";


const firebaseConfig = {
  apiKey: "AIzaSyDh3E2F7oRTmxQHijwawr91tBYwxcd-tHU",
  authDomain: "nilachaldhamadmin.firebaseapp.com",
  projectId: "nilachaldhamadmin",
  storageBucket: "nilachaldhamadmin.appspot.com",
  messagingSenderId: "215659133248",
  appId: "1:215659133248:web:1fae766139c724a5b522a5",
  measurementId: "G-MEMWGC45FP"
};

const PhotoScreen = () => {
  const [imageUrls, setImageUrls] = useState([]);
  console.log(imageUrls);
  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const storageRef = ref(storage, "DonationPage-Gallery");

    listAll(storageRef)
      .then((result) => {
        const urlsPromises = result.items.map((imageRef) =>
          getDownloadURL(imageRef)
        );
        return Promise.all(urlsPromises);
      })
      .then((urls) => {
        setImageUrls(urls);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);

  return (
    <div className="socialMediaBack">
      <h2 className="headingOfSocialMedia">Social Media Gallery</h2>
      <div className="imagesSocial">
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          modules={[EffectCoverflow, Pagination]}
          className="mySwiper"
          autoplay={{ delay: 1000 }}
          speed={800}
          // loop={true}
        >
          {imageUrls.map((url, index) => (
            <SwiperSlide key={index}>
              <img key={index} src={url} alt={`Image ${index}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="imagesSocial">
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 220,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          modules={[EffectCoverflow, Pagination]}
          className="mySwiper"
          autoplay={{ delay: 1000 }}
          speed={800}
          // loop={true}
        >
          {imageUrls.map((url, index) => (
            <SwiperSlide key={index}>
              <img key={index} src={url} alt={`Image ${index}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="imagesSocial">
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 360,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          modules={[EffectCoverflow, Pagination]}
          className="mySwiper"
          autoplay={{ delay: 1000 }}
          speed={800}
          // loop={true}
        >
          {imageUrls.map((url, index) => (
            <SwiperSlide key={index}>
              <img key={index} src={url} alt={`Image ${index}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="imagesSocial">
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 120,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          modules={[EffectCoverflow, Pagination]}
          className="mySwiper"
          autoplay={{ delay: 1000 }}
          speed={800}
          // loop={true}
        >
          {imageUrls.map((url, index) => (
            <SwiperSlide key={index}>
              <img key={index} src={url} alt={`Image ${index}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default PhotoScreen;
