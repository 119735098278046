import React from 'react'
import Festival from "../../components/Festival";
import holy from '../../images/holy.jpg';

export default function WorldHolyName() {
  return (
    <div>
      <Festival
        heading="World Holy Name Festival"
        bold="The governing body of ISKCON launched the celebration of World Holy Name Festival in 1966. It is a unique way for celebrating the birthday of Srila Prabhupada, founder of ISKCON."
        img={holy}
        p1="For the very first time, it was celebrated on 26th September 1965. It marks the time when great Srila Prabhupada arrived in the United States. He introduced the holy name of Lord Krishna. He promoted the chanting of several sacred names of Lord Krishna."
        p2="In 2008, all the devotees felt that one day isn’t sufficient for worshiping the sacred names of the Supreme Lord Krishna. As a result, they decided to lengthen the celebration for one week. Now, World Holy Name Festival lasts up to one week every year."
        p3=" Time and day may vary as it is scheduled according to the lunar calendar. Every year World Holy Name Festival is filled with lots of joy and special events."
        p4="Usually, the World Holy Name Festival is celebrated between 28th September and 2nd October by the entire ISKCON community. It is believed that you can bring peace, success, and harmony into your life by participating in this sacred festival. If you want the blessings of Lord Krishna, then don’t miss the chance to join ISKCON Dwarka this year in this celebration."
        p5=""
        p6=""
      />
    </div>
  )
}
