import React, { useState } from "react";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import firebase from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebaseConfig";
import firebase1 from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "../styles/uploadimage.css";
import sliderimage from "../images/sliderimage.png";
import photogalleryimage from '../images/photogalleryimage.png';
import videogalleryimage from '../images/videogallery.png';
import constructionimage from '../images/constructionimage.png';
import loadingImage from "../images/harekrishna.gif";
import blogimage from '../images/blogimage.png';
import schoolactivityimage from '../images/schoolactivityimage.png';

const ImageUpload = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [file, setFile] = useState(null);
  const [title, settitle] = useState("");
  const [heading1, setheading1] = useState("");
  const [heading2, setheading2] = useState("");
  const [paragraph1, setparagraph1] = useState("");
  const [paragraph2, setparagraph2] = useState("");

  const navigate = useNavigate();

  const SliderImageUpload = async () => {
    if (!file) {
      alert("Please select an image.");
      return;
    }

    if (file) {
      setLoading(true);
      setMessage("Wait Until Your Data is Submitted!...")

      const storage = getStorage(firebase);
      const storageRef = ref(storage, `Nilachaldham-Slider/${file.name}`);
      try {
        await uploadBytes(storageRef, file);
        setMessage({ type: 'success', text: 'Data submitted successfully!' });
        setTimeout(() => {
          setLoading(false);
        }, 5000);
        setFile(null);
      } catch (error) {
        setMessage({ type: 'error', text: 'Failed to submit data. Please try again.' });
         setTimeout(() => {
         setLoading(false);
         }, 5000);
      }
    } 
  };

  const PhotoGalleryImageUpload = async () => {
    if (!file) {
      alert("Please select an image.");
      return;
    }

    if (file) {

      setLoading(true);
      setMessage("Wait Until Your Data is Submitted!...")

      const storage = getStorage(firebase);
      const storageRef = ref(storage, `Nilachaldham-Gallery/${file.name}`);
      try {
        await uploadBytes(storageRef, file);
        setMessage({ type: 'success', text: 'Data submitted successfully!' });
        setTimeout(() => {
          setLoading(false);
        }, 5000);
        setFile(null);
      } catch (error) {
        setMessage({ type: 'error', text: 'Failed to submit data. Please try again.' });
        setTimeout(() => {
          setLoading(false);
        }, 5000);
      }
    }
  };

  const VideoGalleryImageUpload = async () => {
    if (!file) {
      alert("Please select an image.");
      return;
    }

    if (file) {
      setLoading(true);
      setMessage("Wait Until Your Data is Submitted!...")

      const storage = getStorage(firebase);
      const storageRef = ref(storage, `Nilachaldham-Video/${file.name}`);
      try {
        await uploadBytes(storageRef, file);
        setMessage({ type: 'success', text: 'Data submitted successfully!' });
        setTimeout(() => {
          setLoading(false);
        }, 5000);
        setFile(null);
      } catch (error) {
        setMessage({ type: 'error', text: 'Failed to submit data. Please try again.' });
        setTimeout(() => {
          setLoading(false);
        }, 5000);
      }
    }
  };

  const ConstructionWorkImageUpload = async () => {
    if (!file) {
      alert("Please select an image.");
      return;
    }

    if (file) {

      setLoading(true);
      setMessage("Wait Until Your Data is Submitted!...")

      const storage = getStorage(firebase);
      const storageRef = ref(storage, `Nilachaldham-ConstructionWork/${file.name}`);
      try {
        await uploadBytes(storageRef, file);
        setMessage({ type: 'success', text: 'Data submitted successfully!' });
        setTimeout(() => {
          setLoading(false);
        }, 5000);
        setFile(null);
      } catch (error) {
        setMessage({ type: 'error', text: 'Failed to submit data. Please try again.' });
        setTimeout(() => {
          setLoading(false);
        }, 5000);
      }
    }
  };

  const SchoolActivitiesImageUpload = async () => {
    if (!file) {
      alert("Please select an image.");
      return;
    }

    if (file) {

      setLoading(true);
      setMessage("Wait Until Your Data is Submitted!...")

      const storage = getStorage(firebase);
      const storageRef = ref(storage, `Nilachaldham-ConstructionWork/${file.name}`);
      try {
        await uploadBytes(storageRef, file);
        setMessage({ type: 'success', text: 'Data submitted successfully!' });
        setTimeout(() => {
          setLoading(false);
        }, 5000);
        setFile(null);
      } catch (error) {
        setMessage({ type: 'error', text: 'Failed to submit data. Please try again.' });
        setTimeout(() => {
          setLoading(false);
        }, 5000);
      }
    }
  };

  const handleSubmitBlogsection = async (e) => {
    e.preventDefault();
    try {
      const datetoday = new Date();
      const firestore = firebase1.firestore();
      const storage = firebase1.storage();
      if (!file) {
        alert("Please select an image.");
      }

      setLoading(true);
      setMessage("Wait Until Your Data is Submitted!...")

      const storageRef = storage.ref(`images/${file.name}`);
      const uploadTask = storageRef.put(file);

      const snapshot = await uploadTask;
      const downloadURL = await snapshot.ref.getDownloadURL();

      const timestamp = firebase1.firestore.FieldValue.serverTimestamp();
      const options = { year: "numeric", month: "long", day: "numeric" };

      const submissionData = {
        imageUrl: downloadURL,
        title: title,
        heading1: heading1,
        paraghaph1: paragraph1,
        heading2: heading2,
        paragraph2: paragraph2,
        date: datetoday.toLocaleDateString(undefined, options),
        submittedAt: timestamp,
      };
      await firestore.collection("NilachalDham-BlogData").add(submissionData);

      setMessage({ type: 'success', text: 'Data submitted successfully!' });
      setTimeout(() => {
        setLoading(false);
      }, 5000);

      setFile(null);
      settitle("");
      setheading1("");
      setparagraph1("");
      setheading2("");
      setparagraph2("");
    } catch (error) {
         setMessage({ type: 'error', text: 'Failed to submit data. Please try again.' });
         setTimeout(() => {
          setLoading(false);
        }, 5000);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  return (
    <>
    {
      loading ?
      <section className="loadingimagediv">
        <div className="loadingimageinnerdiv">
        <div className="loadingimageimagediv">
        <img className="loadingimage" src={loadingImage} alt="Loading"/>
        </div>
        <div className="loadingimagemessage">
          <h2 style={{ color: message.type === 'success' ? 'green' : message.type === 'error' ? 'red' :'black'}}>{message.text}</h2>
          </div>
        </div>
      </section>
      :
      <div className="uploadimagediv">
        <div className="logoutbtndiv">
          <div className="logoutbtninnerdiv">
            <button className="uploadimagebtn" onClick={handleLogout} style={{ borderRadius: "5px" }}>
              Logout
            </button>
          </div>
        </div>

        <div className="uploadimagerow">

          <div className="uploadimagecolumn">
            <div className="uploadimagecolumninnerdiv">
              <h3>Slider Section: </h3>
              <input type="file" onChange={handleFileChange} />
              <button className="uploadimagebtn" onClick={SliderImageUpload} style={{ marginTop: "5px", borderRadius: "5px" }}>Upload</button>
            </div>
          </div>

          <div className="uploadimagecolumn">
            <img className="uploadimageimage" src={sliderimage} alt="sliderimage"></img>
          </div>
        </div>

         <div className="uploadimagerow">

          <div className="uploadimagecolumn">
            <div className="uploadimagecolumninnerdiv">
              <h3>Photo Gallery Section: </h3>
              <input type="file" onChange={handleFileChange} />
              <button className="uploadimagebtn" onClick={PhotoGalleryImageUpload} style={{ marginTop: "5px", borderRadius: "5px" }}>Upload</button>
            </div>
          </div>

          <div className="uploadimagecolumn">
            <img className="uploadimageimage" src={photogalleryimage} alt="photogalleryimage"></img>
          </div>
        </div>

         <div className="uploadimagerow">

          <div className="uploadimagecolumn">
            <div className="uploadimagecolumninnerdiv">
              <h3>Video Gallery Section: </h3>
              <input type="file" onChange={handleFileChange} />
              <button className="uploadimagebtn" onClick={VideoGalleryImageUpload} style={{ marginTop: "5px", borderRadius: "5px" }}>Upload</button>
            </div>
          </div>

          <div className="uploadimagecolumn">
            <img className="uploadimageimage" src={videogalleryimage} alt="videogalleryimage"></img>
          </div>
        </div>

         <div className="uploadimagerow">

          <div className="uploadimagecolumn">
            <div className="uploadimagecolumninnerdiv">
              <h3>Construction Work Update Section: </h3>
              <input type="file" onChange={handleFileChange} />
              <button className="uploadimagebtn" onClick={ConstructionWorkImageUpload} style={{ marginTop: "5px", borderRadius: "5px" }}>Upload</button>
            </div>
          </div>

          <div className="uploadimagecolumn">
            <img className="uploadimageimage" src={constructionimage} alt="constructionimage"></img>
          </div>
        </div>

        <div className="uploadimagerow">

          <div className="uploadimagecolumn">
            <div className="uploadimagecolumninnerdiv">
              <h3>School Activities Section: </h3>
              <input type="file" onChange={handleFileChange} />
              <button className="uploadimagebtn" onClick={SchoolActivitiesImageUpload} style={{ marginTop: "5px", borderRadius: "5px" }}>Upload</button>
            </div>
          </div>

          <div className="uploadimagecolumn">
            <img className="uploadimageimage" src={schoolactivityimage} alt="schoolactivityimage"></img>
          </div>
        </div>

       <div className="uploadimagerow">

          <div className="uploadimagecolumn">
          <div className="uploadimagecolumninnerdiv">
              <h3>Blog Section: </h3>
              <label htmlFor="MainHeading">Main Heading</label>
              <input type="text" id="MainHeading" placeholder="Enter Main Heading" value={title} onChange={(e) => settitle(e.target.value)}/>

              <label htmlFor="imageInput">Upload Image:</label>
              <input type="file" id="imageInput" accept="image/*" onChange={handleFileChange} required/>

              <label htmlFor="Heading1">Heading1</label>
              <input type="text"id="Heading1" placeholder="Enter New Heading 1" value={heading1} onChange={(e) => setheading1(e.target.value)}/>

              <label htmlFor="Paragraph1">Paragraph1</label>
              <input type="text"id="Paragraph1" placeholder="Enter New Paragraph 1" value={paragraph1} onChange={(e) => setparagraph1(e.target.value)}/>

              <label htmlFor="Heading2">Heading2</label>
              <input type="text"id="Heading2" placeholder="Enter New Heading 2" value={heading2} onChange={(e) => setheading2(e.target.value)}/>

              <label htmlFor="Paragraph2">Paragraph2</label>
              <input type="text"id="Paragraph2" placeholder="Enter New Paragraph 2" value={paragraph2} onChange={(e) => setparagraph2(e.target.value)}/>
      
              <button className="uploadimagebtn" onClick={handleSubmitBlogsection} style={{ marginTop: "5px", borderRadius: "5px" }} >Upload</button>
           </div>
          </div>

          <div className="uploadimagecolumn">
            <img className="uploadimageimage" src={blogimage} alt="blogimage"></img>
          </div>

        </div>
        </div>


    }
    </>
  );
};

export default ImageUpload;
