import React from 'react'
import Festival from "../../components/Festival";
import kartikanddiwali from '../../images/kartikanddiwali.jpg';

export default function KartikAndDipwali() {
  return (
    <div>
      <Festival
        heading="Kartik and Deepawali"
        bold=""
        img={kartikanddiwali}
        p1="Kartik is the most auspicious month for the Vaishnavas. It is the last month of Chaturmaas.It is also known as Damodarmonth.Kartik (Damodar) is the holiest, the purest of purifiers, and most glorious of all months. Kartik month is particularly dear to Lord Sri Krishna. This month is full of bhaktavatsalya. Any vrata, even the smallest, will yield huge results during this month. The effect of performing a Kartik Vrata lasts for one hundred lifetimes. Kartik or the festival of offering lamps to Lord Krishna, glorifies Lord Krishna’s pastime of being bound with ropes by Mother Yashoda."
        p2="The Padma Purana further recommends the daily offering of a ghee lamp to Krishna during Kartik so in ISKCON temples around the world devotees offer ghee lamps to Krishna either in the morning or evening while singing the Damodarashtakam prayers. These beautiful verses taught to the devotees by Srila Prabhupada, glorify the Damodar pastime of Krishna which occurred during the Kartik month."
        p3="In ISKCON Dwarka, the sacred month of Kartik is celebrated in a very special way. Every evening many devotees gather before the Deities for the sandhya-arati at 7 p.m. Everyone has the opportunity to offer the Deities a ghee lamp while singing the Damodarashtakam prayers. Every night a different speaker speaks on topics related to Krishna. To end each sublime evening during the whole month of Kartik,a feast, which is sponsored by a different devotee or well-wisher, is served to all those present. The mood is so sweet that nobody seems to want to return home."
        p4="Many festivals related to Krishna’s or His devotees pastimes occur during this month: Rasa-lila, Diwali, Govardhan puja, Appearance of Radha-kund, Gopashtami, and the Disappearance of Srila Prabhupada."
        p5=""
        p6=""
      />
    </div>
  )
}
