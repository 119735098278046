import React from "react";
import "../styles/contribution.css";
import nityanandtridashi from "../images/Sri-Nityananda-Trayodasi-jpg.webp";
import flowerfest from "../images/Pushya-Abhishek2023-jpg.webp";
import general from "../images/General-jpg.webp";
import donateeverymonth from "../images/Donate-Every-Month-1-jpg.webp";
import radhaMadhav from "../images/Radha-Madhav-jpg.webp";
import sastradana from "../images/sastradana.png";
import tovp from "../images/ToVP-2-jpg.webp";
import bhogaplate from "../images/Bhoga-Plate-jpg.webp";
import aratiseva from "../images/Arati-Seva-3-jpg.webp";
import daitygarland from "../images/Deity-Garland-2-jpg.webp";
import prasadforrestdent from "../images/Prasad-for-residents-1-jpg.webp";
import annadaan from "../images/Anna-Daan-1-jpg.webp";
import speciallunch from "../images/Special-Lunch-Prasad-3-jpg.webp";
import feedthecow from "../images/Feed-the-cows-1-jpg.webp";
import maintainonecow from "../images/Maintain-one-cow-1-jpg.webp";
import maintainallcow from "../images/Maintain-all-cows-1-jpg.webp";
import ekadashiseva from "../images/Ekadasi1-jpg.webp";
import bhagavadseva from "../images/Bhagavad-Seva-jpg.webp";
import nityaseva from "../images/nitya-seva.webp";
import VideoSection from "./VideoSection";
import donate2 from "../images/donate2.jpg";
import vamanapurana from "../images/vamana-purana.jpg";
import agnipurana from "../images/agni-purana.webp";
import bhagvadgeeta from "../images/donate-srimad-bhagvatam.png";
import narasimha from '../images/narasimha.jpg';
import skanda from '../images/skanda-purana.jpeg';
import padmapurana from '../images/padma-purana.jpeg';
import VishnuDharmottara from '../images/vishnu-dharmottara.png';

export default function Contribution() {
  const handleDonateClick = () => {
    window.location.href = "https://razorpay.me/";
  };

  return (
    <>
      <div className="contributiondiv">
        {/* <div className="upcomingfestivaldiv">
        <h3 className="upcomingfestivalheading">Upcoming Festivals</h3>
        <p className="upcomingfestivalpara">
          Serve and contribute as per your capacity
        </p>
        <div className="upcomingfestivalcolumn">
          <div className="upcomingfestivalinnercolumn1">
            <img
              className="upcomingfestivalimage"
              src={nityanandtridashi}
              alt="nityanandtridashi"
            />
          </div>
          <div className="upcomingfestivalinnercolumn2">
            <div className="upcomingfestivalinnercolumn2inner1">
              <h3 className="upcomingfestivalinnerheading">
                Nityanand Trayodashi
              </h3>
              <p style={{ textAlign: "start" }}>
              Devotees often mark Nityanand Trayodashi by giving charitable donations, contributing to spiritual causes, and supporting initiatives that align with Swami Nityanand's teachings of compassion and service to humanity.
              </p>
            </div>

            <div className="upcomingfestivalinnercolumn2inner2">
              <button  className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
            </div>
          </div>
        </div>

        <div className="upcomingfestivalcolumn">
          <div className="upcomingfestivalinnercolumn1">
            <img
              className="upcomingfestivalimage"
              src={flowerfest}
              alt="flowerfest"
            />
          </div>
          <div className="upcomingfestivalinnercolumn2">
            <div className="upcomingfestivalinnercolumn2inner1">
              <h3 className="upcomingfestivalinnerheading">Flower Festival</h3>
              <p style={{ textAlign: "start" }}>
              During the Flower Festival, attendees generously contribute donations, fostering community spirit and supporting floral initiatives that beautify public spaces.
              </p>
            </div>

            <div className="upcomingfestivalinnercolumn2inner2">
              <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
            </div>
          </div>
        </div>
      </div> */}

        <div className="upcomingfestivaldiv">
          <h3 className="upcomingfestivalheading">General Donation</h3>
          <p className="upcomingfestivalpara">
            Welcome to the divine opportunity of contributing towards the
            construction of a temple dedicated to Lord Krishna at our sacred
            project. By participating in this noble endeavor, you are not just
            assisting in building a physical structure but also aligning
            yourself with profound spiritual benefits as outlined in ancient
            scriptures.
          </p>

          <div className="upcomingfestivalrow">
            <div className="upcomingfestivalcolumn">
              <div className="upcomingfestivalinnercolumn1">
                <img className="upcomingfestivalimage" src={tovp} alt="tovp" />
              </div>
              <div className="upcomingfestivalinnercolumn2">
                <div className="upcomingfestivalinnercolumn2inner1">
                  <h3 className="upcomingfestivalinnerheading">
                    Temple Construction
                  </h3>
                  <p style={{ textAlign: "start" }}>
                    Temple construction relies on the benevolence of donors,
                    whose contributions play a vital role in creating sacred
                    spaces for worship and spiritual enrichment.
                  </p>
                </div>

                <div className="upcomingfestivalinnercolumn2inner2">
                  <button
                    className="upcomingfestivalinnerbutton"
                    onClick={handleDonateClick}
                  >
                    DONATE
                  </button>
                </div>
              </div>
            </div>

            <div className="upcomingfestivalcolumn">
              <div className="upcomingfestivalinnercolumn1">
                <img
                  className="upcomingfestivalimage"
                  src={general}
                  alt="general"
                />
              </div>
              <div className="upcomingfestivalinnercolumn2">
                <div className="upcomingfestivalinnercolumn2inner1">
                  <h3 className="upcomingfestivalinnerheading">
                    Gurukul Construction
                  </h3>
                  <p style={{ textAlign: "start" }}>
                    Make a one-time general donation to support various causes,
                    helping make a positive impact on communities and addressing
                    diverse needs. Your generosity makes a difference in
                    creating positive change.
                  </p>
                </div>

                <div className="upcomingfestivalinnercolumn2inner2">
                  <button
                    className="upcomingfestivalinnerbutton"
                    onClick={handleDonateClick}
                  >
                    DONATE
                  </button>
                </div>
              </div>
            </div>

            <div className="upcomingfestivalcolumn">
              <div className="upcomingfestivalinnercolumn1">
                <img
                  className="upcomingfestivalimage"
                  src={donateeverymonth}
                  alt="donateeverymonth"
                />
              </div>
              <div className="upcomingfestivalinnercolumn2">
                <div className="upcomingfestivalinnercolumn2inner1">
                  <h3 className="upcomingfestivalinnerheading">
                    Gaushala Construction
                  </h3>
                  <p style={{ textAlign: "start" }}>
                    Make a one-time general donation to support various causes,
                    helping make a positive impact on communities and addressing
                    diverse needs. Your generosity makes a difference in
                    creating positive change.
                  </p>
                </div>

                <div className="upcomingfestivalinnercolumn2inner2">
                  <button
                    className="upcomingfestivalinnerbutton"
                    onClick={handleDonateClick}
                  >
                    DONATE
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="upcomingfestivalcolumn">
          <div className="upcomingfestivalinnercolumn1">
            <img
              className="upcomingfestivalimage"
              src={radhaMadhav}
              alt="radhaMadhav"
            />
          </div>
          <div className="upcomingfestivalinnercolumn2">
            <div className="upcomingfestivalinnercolumn2inner1">
              <h3 className="upcomingfestivalinnerheading">
                Nilachaladham Dress Offering
              </h3>
              <p style={{ textAlign: "start" }}>
                Here is the privilege to welcome the Nilachaldham Deities to their
                new home! The Grand Opening…
              </p>
            </div>

            <div className="upcomingfestivalinnercolumn2inner2">
              <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
            </div>
          </div>
        </div> */}

          {/* <div className="upcomingfestivalcolumn">
          <div className="upcomingfestivalinnercolumn1">
            <img
              className="upcomingfestivalimage"
              src={sastradana}
              alt="sastradana"
            />
          </div>
          <div className="upcomingfestivalinnercolumn2">
            <div className="upcomingfestivalinnercolumn2inner1">
              <h3 className="upcomingfestivalinnerheading">Śāstra Dāna</h3>
              <p style={{ textAlign: "start" }}>
                The timeless wisdom of Gita presented with parampara
                explanations by Srila Prabhupada has transformed millions of…
              </p>
            </div>

            <div className="upcomingfestivalinnercolumn2inner2">
              <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
            </div>
          </div>
        </div> */}
        </div>

        {/* <div className="upcomingfestivaldiv">
        <h3 className="upcomingfestivalheading">Deity Seva</h3>
        <p className="upcomingfestivalpara">
          Serve the Lord's deity form incarnation directly
        </p>
        <div className="upcomingfestivalcolumn">
          <div className="upcomingfestivalinnercolumn1">
            <img
              className="upcomingfestivalimage"
              src={bhogaplate}
              alt="bhogaplate"
            />
          </div>
          <div className="upcomingfestivalinnercolumn2">
            <div className="upcomingfestivalinnercolumn2inner1">
              <h3 className="upcomingfestivalinnerheading">Deity Bhoga</h3>
              <p style={{ textAlign: "start" }}>
              Devotees express gratitude through deity bhoga, offering donations to enhance temple services and sustain the sacred rituals, fostering a sense of communal devotion.
                </p>
            </div>

            <div className="upcomingfestivalinnercolumn2inner2">
              <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
            </div>
          </div>
        </div>

        <div className="upcomingfestivalcolumn">
          <div className="upcomingfestivalinnercolumn1">
            <img
              className="upcomingfestivalimage"
              src={aratiseva}
              alt="aratiseva"
            />
          </div>
          <div className="upcomingfestivalinnercolumn2">
            <div className="upcomingfestivalinnercolumn2inner1">
              <h3 className="upcomingfestivalinnerheading">Arati Seva</h3>
              <p style={{ textAlign: "start" }}>
              Arati Seva enthusiasts often extend their devotion through donations, contributing to the upkeep of sacred spaces and ensuring the continuity of spiritual ceremonies.
                </p>
            </div>

            <div className="upcomingfestivalinnercolumn2inner2">
              <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
            </div>
          </div>
        </div>

        <div className="upcomingfestivalcolumn">
          <div className="upcomingfestivalinnercolumn1">
            <img
              className="upcomingfestivalimage"
              src={daitygarland}
              alt="daitygarland"
            />
          </div>
          <div className="upcomingfestivalinnercolumn2">
            <div className="upcomingfestivalinnercolumn2inner1">
              <h3 className="upcomingfestivalinnerheading">Deitya Garland</h3>
              <p style={{ textAlign: "start" }}>
              Devotees express reverence by offering deity garlands, often accompanying it with donations to enhance the sacred atmosphere and sustain religious practices.
                </p>

            </div>

            <div className="upcomingfestivalinnercolumn2inner2">
              <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
            </div>
          </div>
        </div>
      </div> */}

        {/* <div className="prasadamsevadiv">
          <h3 className="upcomingfestivalheading">Prasadam Seva</h3>
          <p className="upcomingfestivalpara">
          Serve prasadam to all the temple residents
          </p>

        <div className="prasadamsevarow">
          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={prasadforrestdent}
                alt="prasadforrestdent"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading">Prasad for Resident</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>

          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={annadaan}
                alt="annadaan"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading">Anna Daan</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>

          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={speciallunch}
                alt="speciallunch"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading">Special Lunch Prasad</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>
        </div>
      </div> */}

        {/* <div className="prasadamsevadiv">
          <h3 className="upcomingfestivalheading">Go Seva</h3>
          <p className="upcomingfestivalpara">
          Serve the cows of Govinda - who is known as the controller of the senses and the cows
          </p>

        <div className="prasadamsevarow">
          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={feedthecow}
                alt="feedthecow"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading">Feed The Cows</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>

          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={maintainonecow}
                alt="maintainonecow"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading">Maintain One Cow</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>

          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={maintainallcow}
                alt="maintainallcow"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading">Maintain All Cow</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>
        </div>
      </div> */}

        {/* <div className="prasadamsevadiv">
          <h3 className="upcomingfestivalheading">Special Seva</h3>
          <p className="upcomingfestivalpara">
          Serve the Lordships and devotees on your selected days
          </p>

        <div className="prasadamsevarow">
          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={ekadashiseva}
                alt="ekadashiseva"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading1">Ekadashi Seva</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>

          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={prasadforrestdent}
                alt="prasadforrestdent"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading1">Priti Bhoj Seva</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>

          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={bhagavadseva}
                alt="bhagavadseva"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading1">Bhagavad Seva</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>

          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={nityaseva}
                alt="nityaseva"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading1">Nitya Seva</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>
        </div>
      </div> */}

        {/* <div className="prasadamsevadiv">
          <h3 className="upcomingfestivalheading">Youth Empowerment</h3>
          <p className="upcomingfestivalpara">
          Youths are the future! Contribute to help us help them stay strong in culture
          </p>

        <div className="prasadamsevarow">
          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={ekadashiseva}
                alt="ekadashiseva"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading1">Weekly Character Development</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>

          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={prasadforrestdent}
                alt="prasadforrestdent"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading1">Spiritual Library For Youth</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>

          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={bhagavadseva}
                alt="bhagavadseva"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading1">Monthly Youth Empowerment Camps</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>

          <div className="prasadamsevacolumn">
            <div className="prasadamsevainnercolumn1">
              <img
                className="prasadamsevaimage"
                src={nityaseva}
                alt="nityaseva"
              />
            </div>
            <div className="prasadamsevainnercolumn2">
              <div className="upcomingfestivalinnercolumn2inner1">
                <h3 className="upcomingfestivalinnerheading1">Annual Educational Trips for Youth</h3>
              </div>

              <div className="upcomingfestivalinnercolumn2inner2">
                <button className="upcomingfestivalinnerbutton" onClick={handleDonateClick}>DONATE</button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      </div>

      <div className="donationdiv">
        <div className="donationrow donationrow1">
          
          <div className="donationcolumn donationcolumn1 ">
            <p className="donationparagraph">
              The Vamana Purana declares, "On building a temple of Sri Madhav,
              one can attain the eternal Vaikunthaloka." Your involvement in
              constructing a place of worship opens the doors to a spiritual
              realm beyond the material world.
            </p>
          </div>
          <div className="donationcolumn">
            <img
              className="donationimage"
              src={vamanapurana}
              alt="donationimage"
            />
          </div>
        </div>
        <div className="donationrow">
          <div className="donationcolumn">
            <img
              className="donationimage"
              src={agnipurana}
              alt="donationimage"
            />
          </div>

          <div className="donationcolumn donationcolumn1 ">
            <p className="donationparagraph">
              The Agni Purana further emphasizes the significance, stating,
              "Having acquired wealth and built a temple with a small portion of
              it, a person acquires piety and gains favors from Hari." Your
              contribution, regardless of its size, is an act of devotion that
              brings divine blessings. The Vamana Purana promises, "Whoever
              constructs or helps to construct a temple will protect eight
              generations of father, grandfathers, and forefathers from falling
              into Hell." Your support becomes a shield for your ancestors,
              offering them spiritual liberation.
            </p>
          </div>
        </div>
        <div className="donationrow  donationrow1">
          
          <div className="donationcolumn donationcolumn1 ">
            <p className="donationparagraph">
              In the Srimad Bhagavatam, we find illuminating verses that
              describe the profound impact of building a temple for the Lord. It
              states, "By building a temple for the Lord, one becomes the ruler
              of the three worlds, and by worshiping and serving the deity, one
              goes to the planet of Lord Brahma." The transcendental benefits
              extend to achieving a form akin to the divine.
            </p>
          </div>
          <div className="donationcolumn">
            <img
              className="donationimage"
              src={bhagvadgeeta}
              alt="donationimage"
            />
          </div>
        </div>
        <div className="donationrow">
          <div className="donationcolumn">
            <img className="donationimage" src={narasimha} alt="donationimage" />
          </div>

          <div className="donationcolumn donationcolumn1 ">
            <p className="donationparagraph">
              The Narasimha Purana assures, "One who builds a beautiful temple
              for Lord Nrsimhadeva will be freed from all sinful reactions and
              will enter the Vaikuntha planets." Your act of constructing a
              temple becomes a path to spiritual purity and transcendence.
            </p>
          </div>
        </div>
        <div className="donationrow  donationrow1">
      
          <div className="donationcolumn donationcolumn1 ">
            <p className="donationparagraph">
              The Skanda Purana emphasizes the merit of sponsoring a temple of
              Lord Vishnu, stating, "Who sponsors a temple of Lord Vishnu gains
              ten million times the merits accrued by Yajna, Austerities,
              Charities, Pilgrimage, and study of Vedas." Your contribution
              multiplies the spiritual merits manifold.
            </p>
          </div>
          <div className="donationcolumn">
            <img className="donationimage" src={skanda} alt="donationimage" />
          </div>
        </div>
        <div className="donationrow">
          <div className="donationcolumn">
            <img className="donationimage" src={padmapurana} alt="donationimage" />
          </div>

          <div className="donationcolumn donationcolumn1 ">
            <p className="donationparagraph">
              The Padma Purana beautifully declares, "Mortals who help to
              construct a temple of Lord Vishnu do not return to this world;
              they return to the abode of Lord Vishnu." Your involvement becomes
              a means to transcend the cycle of birth and death.
            </p>
          </div>
        </div>
        <div className="donationrow  donationrow1">
         
          <div className="donationcolumn donationcolumn1 ">
            <p className="donationparagraph">
              The Vishnu Dharmottara echoes the profound impact, "A person who
              builds a temple of Sri Vishnu will get the same results as
              performing the Rajasuya yagna and Ashwameda yagna." Your
              contribution is equivalent to grand Vedic ceremonies, ensuring a
              glorious residence in heavenly realms for thousands of years. By
              contributing towards temple construction, you make devotional
              service a tangible reality in your life. The intent to build
              temples worldwide is to provide opportunities for all souls to
              acquire spiritual benefits through participation and charity,
              known as "ajnata sukriti" or pious credit acquired unknowingly.
            </p>
          </div>
          <div className="donationcolumn">
            <img className="donationimage" src={VishnuDharmottara} alt="donationimage" />
          </div>
        </div>
      </div>
      {/* <VideoSection paragraph="saurav"/> */}
    </>
  );
}
