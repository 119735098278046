import React from 'react'

export default function LeaveReply() {
  return (
    <div className="commentdiv">
            <h1>Leave a Reply</h1>
            <p>Your email address will not be published. Required fields are marked *</p>

            <label htmlFor="comment" className="comment-label">Comment *</label>
            <textarea id="comment" name="comment" className="comment-textarea"></textarea>


            <div className="input-row">
                   <div className="input-column">
                       <label htmlFor="name" className="comment-label">Name *</label>
                       <input type="text" id="name" name="name" className="comment-input"/>
                   </div>
                   <div className="input-column">
                      <label htmlFor="email" className="comment-label">Email *</label>
                      <input type="email" id="email" name="email" className="comment-input"/>
                   </div>
            </div>

            <label htmlFor="subscribe" className="subscribe-label">
                <input type="checkbox" id="subscribe" name="subscribe"/> Save my name, email, and website in this browser for the next time I comment.
            </label>
           <button type="submit" className="comment-submit-btn">Post Comment</button>
         </div>
  )
}
