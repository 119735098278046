import React from "react";
import "./../styles/gurukulhelp.css";
import { FaPhoneAlt } from "react-icons/fa";
import { LuMessagesSquare } from "react-icons/lu";
import { IoIosHome } from "react-icons/io";

const Gurukulhelp = () => {
  return (
    <section>
      <div className="gurukulhelp">
        <div className="gurukulhelpinner">
          <div className="innergurukul">
            <FaPhoneAlt />
          </div>
          <a href="tel:+91 70880 16910">+91 70880 16910</a>
          <a href="tel:+91 70880 04339">+91 70880 04339</a>
        </div>
        <div className="gurukulhelpinner">
          <div className="innergurukul">
            <LuMessagesSquare />
          </div>
          <a href="bgis.admissions@gmail.com">bgis.admissions@gmail.com</a>
        </div>
        <div className="gurukulhelpinner">
          <div className="innergurukul">
            <IoIosHome />
          </div>
          <p>
            Bhaktivedanta Gurukula and International School Swami Marg, Raman
            Reti, Vrindavan, Mathura Uttar Pradesh, 281121 India
          </p>
        </div>
      </div>
      <section className="tablingdata">
        <h1>Upcoming Admission Test Date</h1>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Venue</th>
              <th>Apply</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2024-01-01</td>
              <td>Conference Room A</td>
              <td>
                <a href="#">Apply Now</a>
              </td>
            </tr>
            <tr>
              <td>2024-02-15</td>
              <td>Event Hall B</td>
              <td>
                <a href="#">Apply Now</a>
              </td>
            </tr>
            <tr>
              <td>2024-03-30</td>
              <td>Outdoor Venue</td>
              <td>
                <a href="#">Apply Now</a>
              </td>
            </tr>
            {/* <!-- Add more rows with random data as needed --> */}
          </tbody>
        </table>
      </section>
    </section>
  );
};

export default Gurukulhelp;
