import React from 'react'
import '../styles/fcrareport.css';
import { FaRegFilePdf } from "react-icons/fa";
import pdf from '../images/pdf.png';
import { Link } from 'react-router-dom';

export default function FCRAReport() {
  return (
    <>
    <div className="reportdiv">
     <div className="reportrow">
          <div className="reportcolumn">
               <div className="reportcolumn-heading"><h5 style={{textAlign:"center"}}><b>CENTRAL INDIA</b></h5></div>
          </div>

          <div className="reportcolumn">
               <div className="reportcolumn-heading"><h5 style={{textAlign:"center"}}><b>ISKON PURI</b></h5></div>
          </div>
     </div>
        <div className="reportrow">
            <div className="reportcolumn">
               <h3 style={{marginTop:"20px"}}>FY-2016-17 :</h3>
               <div className="reportcolumncard">
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" /> FY-2016-17 - 1st Quarter</Link></h4>
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  FY-2016-17 - 2nd Quarter</Link></h4>
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  FY-2016-17 - 3rd Quarter</Link></h4>
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  FY-2016-17 - 4th Quarter</Link></h4>
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  Annual Financal Reports FY-2016-17</Link></h4>
               </div>

               <h3 style={{marginTop:"20px"}}>FY-2017-18 :</h3>
               <div className="reportcolumncard">
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  FY-2017-18 - 1st Quarter</Link></h4>
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  FY-2017-18 - 2nd Quarter</Link></h4>
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  FY-2017-18 - 3rd Quarter</Link></h4>
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  FY-2017-18 - 4th Quarter</Link></h4>
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  Annual Financal Reports FY-2017-18</Link></h4>
               </div>

               <h3 style={{marginTop:"20px"}}>FY-2018-19 :</h3>
               <div className="reportcolumncard">
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  FY-2018-19 - 1st Quarter</Link></h4>
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  FY-2018-19 - 2nd Quarter</Link></h4>
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  FY-2018-19 - 3rd Quarter</Link></h4>
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  FY-2018-19 - 4th Quarter</Link></h4>
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  Annual Financal Reports FY-2018-19</Link></h4>
               </div>

               <h3 style={{marginTop:"20px"}}>FY-2019-20 :</h3>
               <div className="reportcolumncard">
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  FY-2019-20 - 1st Quarter</Link></h4>
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  FY-2019-20 - 2nd Quarter</Link></h4>
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  FY-2019-20 - 3rd Quarter</Link></h4>
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  FY-2019-20 - 4th Quarter</Link></h4>
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  Annual Financal Reports FY-2019-20</Link></h4>
               </div>

               <h3 style={{marginTop:"20px"}}>FY-2020-21 :</h3>
               <div className="reportcolumncard">
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  FY-2020-21 - 1st Quarter</Link></h4>
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  FY-2020-21 - 2nd Quarter</Link></h4>
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  FY-2020-21 - 3rd Quarter</Link></h4>
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  FY-2020-21 - 4th Quarter</Link></h4>
                <h4><Link style={{color:"black",textDecoration:"none"}} to="/"><img className='pdfimage' src={pdf} alt="pdf" />  Annual Financal Reports FY-2020-21</Link></h4>
               </div>
            </div>
        </div>
    </div>
    </>
  )
}
