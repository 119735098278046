import React from 'react';
import "../styles/connectusonline.css";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { IoMdMailOpen } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { BiWorld } from "react-icons/bi";
import { GiBlackBook } from "react-icons/gi";
import { IoPeopleSharp } from "react-icons/io5";
import { TiVendorAndroid } from "react-icons/ti";

const ConnectUsOnlineColumn = () => {
  return (
<section className='connectusonlineSection'>
    <div className="connectusonlineHeading" data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-delay="300">
        <h1>CONNECT WITH US ONLINE</h1>
    </div>

<div className="connectusonlinerow">    
    <div className="connectusonlinecolumn">
      <div className="cardStyles">
        <h3>Temple</h3>
        <div className="buttonContainerStyles">
          <button className="buttonStyles"><FaFacebook/> FACEBOOK</button>
          <button className="buttonStyles"><FaInstagram/> INSTAGRAM</button>
          <button className="buttonStyles"><FaTwitter/> TWITTER</button>
          <button className="buttonStyles"><FaTelegram/> TELEGRAM</button>
          <button className="buttonStyles"><FaYoutube/> YOUTUBE</button>
          <button className="buttonStyles"><IoMdMailOpen/> GET UPDATES</button>
          <button className="buttonStyles"><FaWhatsapp/> WHATSAPP COMMUNITY</button>
        </div>
      </div>
    </div>

    <div className="connectusonlinecolumn">
      <div className="cardStyles">
        <h3>Join our WhatsApp Community</h3>
        <div className="buttonContainerStyles">
          <button className="buttonStyles"><IoPeopleSharp/> DAILY DARSHANS</button>
          <button className="buttonStyles"><IoPeopleSharp/> LECTURES & SNIPPETS</button>
          <button className="buttonStyles"><IoPeopleSharp/> EVENTS & UPDATES</button>
          <button className="buttonStyles"><IoPeopleSharp/> VOLUNTEERING (SEVA)</button>
          <button className="buttonStyles"><IoPeopleSharp/> KIDS (RGCC)</button>
          <button className="buttonStyles"><IoPeopleSharp/> YOUTH</button>
          <button className="buttonStyles"><IoPeopleSharp/> GOVARDHAN ECO VILLAGE</button>
          <button className="buttonStyles"><IoPeopleSharp/> BOOK DISTRIBUTION</button>
        </div>
      </div>
    </div>

    <div className="connectusonlinecolumn">
      <div className="cardStyles">
        <h3>ISKCON Youth Services</h3>
        <div className="buttonContainerStyles">
          <h3>For Boys</h3>
          <button className="buttonStyles"><FaFacebook/> FACEBOOK</button>
          <button className="buttonStyles"><FaInstagram/> INSTAGRAM</button>
          <button className="buttonStyles"><BiWorld/> WEBSITE</button>
        </div>
        <div className="buttonContainerStyles" style={{marginTop:"10px"}}>
          <h3>For Girls</h3>
          <button className="buttonStyles"><FaFacebook/> FACEBOOK</button>
          <button className="buttonStyles"><FaInstagram/> INSTAGRAM</button>
        </div>
      </div>
    </div>

    </div>

    <div className="connectusonlinerow">

    <div className="connectusonlinecolumn">
      <div className="cardStyles">
        <h3>Kids</h3>
        <div className="buttonContainerStyles">
          <button className="buttonStyles"><FaFacebook/> FACEBOOK</button>
          <button className="buttonStyles"><FaInstagram/> INSTAGRAM</button>
          <button className="buttonStyles"><BiWorld/> WEBSITE</button>
        </div>
      </div>
    </div>

    <div className="connectusonlinecolumn">
      <div className="cardStyles">
        <h3>Career</h3>
        <div className="buttonContainerStyles">
          <button className="buttonStyles"><GiBlackBook/> E-COURSES</button>
          <button className="buttonStyles"><GiBlackBook/> E-LEARNING</button>
          <button className="buttonStyles"><GiBlackBook/> BHAKTI COURSES</button>
        </div>
      </div>
    </div>

    <div className="connectusonlinecolumn">
      <div className="cardStyles">
        <h3>Apps</h3>
        <div className="buttonContainerStyles">
          <button className="buttonStyles"><BiWorld/> MIGHTY NETWORK WEBSITE</button>
          <button className="buttonStyles"><TiVendorAndroid/> MIGHTY NETWORK (ANDROID)</button>
          <button className="buttonStyles"><TiVendorAndroid/> MIGHTY NETWORK (IOS)</button>
        </div>
      </div>
    </div>

    </div>
</section>
   
    
  );
};

export default ConnectUsOnlineColumn;
