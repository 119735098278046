import React,{useEffect,useState} from 'react';
import '../styles/constructionwork.css';
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDh3E2F7oRTmxQHijwawr91tBYwxcd-tHU",
    authDomain: "nilachaldhamadmin.firebaseapp.com",
    projectId: "nilachaldhamadmin",
    storageBucket: "nilachaldhamadmin.appspot.com",
    messagingSenderId: "215659133248",
    appId: "1:215659133248:web:1fae766139c724a5b522a5",
    measurementId: "G-MEMWGC45FP"
  };

export default function Gallery() {
  const [slides, setImageUrls] = useState([]);
  console.log(slides);
  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const storageRef = ref(storage, "Nilachaldham-ConstructionWork");
    listAll(storageRef)
      .then((result) => {
        const urlsPromises = result.items.map((imageRef) =>
          getDownloadURL(imageRef)
        );
        return Promise.all(urlsPromises);
      })
      .then((urls) => {
        setImageUrls(urls);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);

  const[model,setmodel]=useState(false);
  const [tempimgSrc,settempimgSrc]=useState('');
  const getImg=(imgSrc)=>{
    settempimgSrc(imgSrc);
    setmodel(true);
  }
  return (
    <>
    <div className={model?"model open":"model"}>
    <img className='modelimage' src={tempimgSrc} alt="" />
    <button className='modelcloseButton' onClick={()=>{setmodel(false)}}>
          &times;
    </button>
    </div>
    <div className='constructionworkgallery'>
  {
    slides.map((item, index) => {
      if (index % 4 === 0) {
        return (
          <div className='row' key={index / 4}>
            {slides.slice(index, index + 4).map((itemInRow, innerIndex) => (
              <div className='constructionworkpics' key={index + innerIndex} onClick={() => { getImg(itemInRow) }} data-aos="flip-left">
                <img src={itemInRow} alt="gallery_img" className='constructionworkgalleryimage'/>
              </div>
            ))}
          </div>
        );
      }
      return null;
    })
  }
</div>

    </>
  )
}
