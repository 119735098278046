import * as React from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import { FaWhatsapp } from "react-icons/fa";
import { motion } from "framer-motion";
import "./../styles/sticky.css";

export default function FloatingActionButtons() {
  return (
    //     <Box className="contributeButton"
    //       sx={{ "& > :not(style)": { m: 1 } }}
    //       style={{
    //         position: "sticky",
    //         bottom: "152px",
    //         right: "5px",
    //         display:'flex',
    //         zIndex: "2",
    //       }}
    //     >
    //       <motion.div
    //         initial="initial"
    //         animate="jump"
    //         variants={jumpVariants}
    //         whileHover={{ scale: 1.1 }}
    //         whileTap={{ scale: 0.9 }}
    //       >
    //         <Fab style={{ backgroundColor: "green" }} aria-label="add">
    //           <motion.a
    //  target="_blank"
    //  rel="noopener noreferrer"
    //  href="https://wa.me/919348845556"
    //  style={{ textDecoration: "none", color: "white" }}
    //           >
    //             <FaWhatsapp style={{ fontSize: " 30px", color: "white" }} />
    //           </motion.a>
    //         </Fab>
    //       </motion.div>
    //       <Fab
    //         style={{ backgroundColor: "orange", fontSize: "15px", color: "white" }}
    //         variant="extended" href="https://razorpay.me/"
    //       >
    //         Contribute Now
    //       </Fab>
    //     </Box>
    <>
      <div className="maindivstickytop">
        <div className="maindivsticky">
        {/* <a href="#">Q <br /> u <br /> i <br /> c <br /> k <br /> <br />  D <br /> o <br /> n <br /> a <br /> t <br /> e</a> */}
          <a href="#">  D <br /> o <br /> n <br /> a <br /> t <br /> e</a>
        </div>
        <div style={{ backgroundColor: "lightgreen" }} className="maindivsticky1">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://wa.me/919348845556"
            style={{ textDecoration: "none", color: "white" }}
          >
            <FaWhatsapp style={{ fontSize: "50px", color: "white" }} />
          </a>
        </div>
      </div>
    </>
  );
}
