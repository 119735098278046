import React from "react";
import About from "./../images/mahabharat.jpg";
import "./../styles/About.css";

const Foodforlife2 = () => {
  return (
    <section>
      <div className="aboutupperright abouting">
        <img
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
          src="https://rukminim2.flixcart.com/image/850/1000/l3929ow0/regionalbooks/b/a/f/shreemad-bhagwat-geeta-original-imageeufsk4ds57y.jpeg?q=90&crop=false"
          alt=""
        />
        <div className="aboutinner">
        <h1 ><a style={{fontWeight:"bold",color:"#B799FF"}} href="https://vedabase.io/en/library/bg/18/5/">Bg. 18.5</a> </h1>
          <p style={{fontWeight:"500"}}>
          <span style={{fontWeight:"bold",fontSize:"28px"}}>The yogīs</span> should perform acts for the advancement of human society. There are many purificatory processes for advancing a human being to spiritual life. <span style={{fontWeight:"bold", color:"#B799FF"}}>yajña-dāna-tapaḥ-karma

na tyājyaṁ kāryam eva tat

yajño dānaṁ tapaś caiva

pāvanāni manīṣiṇām </span>Acts of sacrifice, charity and penance are not to be given up; they must be performed. Indeed, sacrifice, charity and penance purify even the great souls. <span style={{fontWeight:"bold",color:"#B799FF",fontSize:"20px"}}>Bhagavad-gita 18.5</span>.
The marriage ceremony, for example, is considered to be one of these sacrifices. It is called vivāha-yajña. Should a sannyāsī, who is in the renounced order of life and who has given up his family relations, encourage the marriage ceremony? The Lord says here that any sacrifice which is meant for human welfare should never be given up. Vivāha-yajña, the marriage ceremony, is meant to regulate the human mind so that it may become peaceful for spiritual advancement. 
          </p>

        </div>
      </div>
    </section>
  );
};

export default Foodforlife2;
