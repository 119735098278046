import React from "react";
import FoodImg from "./../images/foodforlife.jpg";
import "./../styles/foodforlife.css";
const FoodForLife = () => {
  return (
    <section className="foodforlife">
      <div data-aos="fade-up" className="para">
        <h1>Nilachaldham Besali</h1>
        <p>
          At the heart of our endeavor is the preparation of vegetarian meals by
          dedicated temple devotees. This food, offered to Lord Krishna before
          distribution, becomes a sacred prasadam capable of nourishing both the
          soul and the body. In our pursuit to eliminate malnutrition, we not
          only address a physical need but also acknowledge the spiritual and
          emotional dimensions of well-being. Through 'Nilachaldham,' we aspire
          to contribute not only to the nutritional health of individuals but
          also to the upliftment of their overall quality of life, fostering a
          harmonious balance between body and spirit.
        </p>
      </div>
      <div data-aos="flip-left" className="foodImage">
        <img
          data-aos="flip-left"
          src="https://iskcon-attapur-live.s3.ap-south-1.amazonaws.com/uploads/campaigns/gallery-images/galleryImages-165945178316875091.webp"
          alt=""
        />
      </div>
    </section>
  );
};

export default FoodForLife;
