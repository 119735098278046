import React from "react";
import Donation from "../../components/Donation";
import img1 from "./../../images/templeconstruc1.webp";
import img2 from "./../../images/templeconstruc2.webp";
import Header3 from "../../components/Header3";
const TempleConstructionobject = [
  { title: "5 CFT Sand", price: "₹300" },
  { title: "1 Bag Cement", price: "₹400" },
  { title: "10 CFT Sand", price: "₹500" },
  { title: "100 Bricks", price: "₹750" },
  { title: "20 CFT Sand", price: "₹1000" },
  { title: "4 Bag Cements", price: "₹1600" },
  { title: "300 Bricks", price: "₹2100" },
  { title: "500 Bricks", price: "₹3500" },
  { title: "10 Bag Cement", price: "₹4000" },
  { title: "Half Sq. Foot Area", price: "₹4999" },
  { title: "100 Kg TMT Saria", price: "₹5100" },
  { title: "1000 Bricks", price: "₹7000" },
  { title: "1 Sq. Foot Area", price: "₹9999" },
  { title: "2 Sq. Foot Area", price: "₹19999" },
];

const TempleConstruction = () => {
  return (
    <div>
      <Header3 />
      <Donation
        topheading="Mandir Nirman Seva"
        toppara="“Whoever constructs or helps to construct a temple will protect eight generations of father, grandfathers and forefathers from falling into Hell”
        "
        para1="In India, we have a rich heritage of Spiritual Wisdom, and temples are the base for this. From temples, many sages attain and preach the wisdom that nurtures and protects humanity whenever there is the need of the hour. Temples are integral parts of India, one such sage was Srila Prabhupada the founder Acharya of ISKCON, who spread this Krishna Consciousness wisdom to the Western countries and within 10 years built 108 temples across the world."
        para2="When we build our house we perform Grahpravesh Puja to invoke auspiciousness and attain Krishna’s blessings that give us immense pleasure and satisfaction. Imagine, when you will support and contribute to building Krishna’s temple how much he will be pleased by your deeds. Therefore, if Krishna blessed us with the needful it’s our responsibility to show our gratitude to him.

        Please, donate to build a Temple for Krishna.
        
        "
        li1="It is mentioned in Vishnu Dharmottara, “A person who builds a temple of Sri Vishnu will get the same results as performing the Rajasuya yajna and Ashwameda yajna. O best of the Brahmanas, as many atoms as there are in the temple, for so many thousands of years will he be able to reside in the heavenly planets.”"
        li2="It is stated in Skanda Purana: “Just by starting the construction of a temple for Lord Krishna, sins committed in seven births will be wiped out and one will deliver his forefathers who are suffering in the hellish planets.”"
        li3="It is mentioned in Narsimha Purana, 'The person who builds a beautiful temple of Lord Shri Vishnu gets freed from the consequences of all his sinful deeds and enters Vaikuntha Dham.'"
        img1={img1}
        img2={img2}
      />
      <div>
        <div className="donaLower">
          <h1>You Can Also Donate For Temple Construction!!</h1>
          <div className="donationdona">
            {TempleConstructionobject.map((i, key) => (
              <div
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="5000"
                className="donadona"
                key={key}
              >
                <div className="contentdon">
                  <h2>{i.title}</h2>
                  <p>{i.price}</p>
                </div>
                <button
                  className="btnDona"
                  onClick={() =>
                    (window.location.href = "https://razorpay.me/")
                  }
                >
                  Donation
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TempleConstruction;
