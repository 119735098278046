import React,{useState,useEffect} from "react";
import { Carousel } from "@trendyol-js/react-carousel";
import "./../styles/gurukulboard.css";
import Img1 from "./../images/Achievements.jpg"
import Img2 from "./../images/anniversary.jpeg"
// import Img3 from "./../images/balaji-temple-iskcon-temple.jpg"
import Img4 from "./../images/December.webp";
import donation from '../images/Donate-Every-Month-1-jpg.webp';
import ImageSlider from "./ImageSlider";

import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";
import GurukulBoardImageSlider from "./GurukulBoardSlider";
import SocialMediaGallery from "./SocialMediaGallery";

const firebaseConfig = {
  apiKey: "AIzaSyDh3E2F7oRTmxQHijwawr91tBYwxcd-tHU",
  authDomain: "nilachaldhamadmin.firebaseapp.com",
  projectId: "nilachaldhamadmin",
  storageBucket: "nilachaldhamadmin.appspot.com",
  messagingSenderId: "215659133248",
  appId: "1:215659133248:web:1fae766139c724a5b522a5",
  measurementId: "G-MEMWGC45FP"
};

const GurukulaBoard = () => {
  const [slides, setImageUrls] = useState([]);
  
  useEffect(() => {
    // Initialize Firebase app if not already initialized
    const app = initializeApp(firebaseConfig);

    // Reference to the Firebase Storage folder
    const storage = getStorage(app);
    const storageRef = ref(storage, "Nilachaldham-Slider");

    // Fetch all the images from the folder
    listAll(storageRef)
      .then((result) => {
        const urlsPromises = result.items.map((imageRef) =>
          getDownloadURL(imageRef)
        );
        return Promise.all(urlsPromises);
      })
      .then((urls) => {
        setImageUrls(urls);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);

  return (
    <section>
      <div className="uppergurukulaboard">
        <h1>Gurukula Board</h1>
        <div className="uppergurukulaboardrow">
        <div className="uppergurukulaboardimgdiv uppergurukulaboardparadiv">
          <p>
          The National Institute of Open Schooling (NIOS), is the board of
          education under the Union Government of India. It was established by
          the Ministry of Human Resource Development of the Government of India
          in 1989 to provide education to all segments of society under the
          motive to increase literacy and aimed forward for flexible learning.
          The NIOS is a national board that administers examinations for
          Secondary and Senior Secondary examinations similar to the CBSE and
          the CISCE. It also offers vocational courses after the high school.
          <br />
          Now it is well known that NIOS, like CBSE and CISCE, acts as a
          national level Board of school education. The students who
          successfully complete their courses of study from NIOS are not in a
          disadvantageous position in matters such as applying for competitive
          examinations and seeking admission in higher education courses etc.
          <br />
          NIOS is the largest Open Schooling System in the world (ISO 9001:2008
          Certified) with a cumulative enrolment of over 2.71 million till date.
        </p>
          </div>
          <div className="uppergurukulaboardimgdiv">
              <img className="uppergurukulaboardimg" src={donation} alt="" />
          </div>
         
        </div>
        
      </div>
      <div className="gurukulamedium">
        <p>
          <b>Why choose About Nois:</b>
        </p>
        <p>
          NIOS has introduced several innovative features that are not generally
          available in the formal schooling system. Some such features are :-
        </p>
        <ul>
          <li>Freedom to learn at one’s own place and pace.</li>
          <li>
            Flexibility including (i) round the year admission, (ii) wider
            choice of courses, (iii) examination when learner wants (nine
            chances to appear in examination with facility of credit
            accumulation), and (iv) facility of On Demand Examination.
          </li>
          <li>
            Relevance of Vocational Education courses as these are functional
            and useful in daily life and set the pathway for the world of work.
          </li>
          <li>Option of choosing Vocational subjects with Academic courses.</li>
          <li>Credit accumulation facility.</li>
          <li>
            Transfer of Credits facility from other Boards of School Education.
          </li>
          <li>Personal Contact Programme (PCP)</li>
          <li>Tutor Marked Assignments (TMA)</li>
          <li>ICT and Media support to learners.</li>
          <li>
            Support services in the constituents of NIOS and in the Study
            Centres.
          </li>
        </ul>
        <p>
          Success stories of NIOS-{" "}
          <a href="">
            Success Story
          </a>{" "}
          <br />
          NIOS FAQ in English-
          <a href="">
            English FAQ
          </a>{" "}
          <br />
          NIOS FAQ in Hindi-
          <a href="">
            Hindi FAQ
          </a>
        </p>
      </div>
      <div className="innercaraosalboard">
        <h1>
          Our <span>School</span> Activities !
        </h1>
        {/* <Carousel className="innercaraosal" show={4} slide={2} transition={0.5} autoSwipe={true}>
          
          <div style={{ backgroundColor: "",display:"flex",alignItems:"center",justifyContent:"center",margin:"0px 20px"}}>
            <img style={{width:"100%"}} src={Img1} alt="" />
          </div>
          <div style={{ backgroundColor: "",display:"flex",alignItems:"center",justifyContent:"center",margin:"0px 20px"}}>
            <img style={{width:"100%"}} src={Img2} alt="" /></div>
          <div style={{ backgroundColor: "",display:"flex",alignItems:"center",justifyContent:"center",margin:"0px 20px" }}>
          <img style={{width:"100%"}} src={Img1} alt="" />
          </div>
          <div style={{ backgroundColor: "",display:"flex",alignItems:"center",justifyContent:"center",margin:"0px 20px" }}>
            <img style={{width:"100%"}} src={Img4} alt="" /></div>
        </Carousel> */}
        {/* <div className="gurukulboardrow">
        <GurukulBoardImageSlider slides={slides}/>
        </div> */}
        <SocialMediaGallery/>
        
      </div>
    </section>
  );
};

export default GurukulaBoard;
