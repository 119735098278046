import React from 'react'
import "../styles/downloadwallpaper.css";
import january from '../images/january.jpg';
import february from '../images/february.jpg';
import march from '../images/march.jpg';
import april from '../images/april.jpg';
import may from '../images/may.jpg';
import june from '../images/june.jpg';
import july from '../images/July.jpg';
import august from '../images/August.jpg';
import september from '../images/September.jpg';
import october from '../images/Octomber.jpg';
import november from '../images/November.webp';
import december from '../images/December.webp';
import { Link } from 'react-router-dom';

export default function DownloadWallpaperCalender() {
  return (
   <section className='downloadappsection'>
    <div className="uldiv">
    <ul className="ult_tabmenu">
      <li className="ult_tab_li">
        <Link to="#1609499031013-4" className='link'>
            DESKTOP WALLPAPER 2022
        </Link>
      </li>
      <li className="ult_tab_li">
        <Link to="#1609499031013-4" className='link'>
            MOBILE WALLPAPER 2022
        </Link>
      </li>
      <li className="ult_tab_li">
        <Link to="#1609499031013-4" className='link'>
            DESKTOP WALLPAPER 2023
        </Link>
      </li>
      <li className="ult_tab_li">
        <Link to="#1609499031013-4" className='link'>
            MOBILE WALLPAPER 2023
        </Link>
      </li>
    </ul>
    </div>

    <div className='monthlywalpaperdiv'>
        <div className="monthlywalpaperrow">
            <div className="monthlywalpapercolumn">
               <img className='wallpaperimage' src={january} alt="january" />
               <h2>January</h2> 
            </div>
            <div className="monthlywalpapercolumn">
               <img className='wallpaperimage'  src={february} alt="january" />
               <h2>February</h2> 
            </div>
            <div className="monthlywalpapercolumn">
               <img className='wallpaperimage'  src={march} alt="january" />
               <h2>March</h2> 
            </div>
        </div>

        <div className="monthlywalpaperrow">
            <div className="monthlywalpapercolumn">
               <img className='wallpaperimage'  src={april} alt="january" />
               <h2>April</h2> 
            </div>
            <div className="monthlywalpapercolumn">
               <img className='wallpaperimage'  src={may} alt="january" />
               <h2>May</h2> 
            </div>
            <div className="monthlywalpapercolumn">
               <img className='wallpaperimage' src={june} alt="january" />
               <h2>June</h2> 
            </div>
        </div>

        <div className="monthlywalpaperrow">
            <div className="monthlywalpapercolumn">
               <img className='wallpaperimage'  src={july} alt="january" />
               <h2>July</h2> 
            </div>
            <div className="monthlywalpapercolumn">
               <img className='wallpaperimage'  src={august} alt="january" />
               <h2>August</h2> 
            </div>
            <div className="monthlywalpapercolumn">
               <img className='wallpaperimage' src={september} alt="january" />
               <h2>September</h2> 
            </div>
        </div>

        <div className="monthlywalpaperrow">
            <div className="monthlywalpapercolumn">
               <img className='wallpaperimage'  src={october} alt="january" />
               <h2>October</h2> 
            </div>
            <div className="monthlywalpapercolumn">
               <img className='wallpaperimage'  src={november} alt="january" />
               <h2>November</h2> 
            </div>
            <div className="monthlywalpapercolumn">
               <img className='wallpaperimage' src={december} alt="january" />
               <h2>December</h2> 
            </div>
        </div>
    </div>
     
   </section>
  )
}
