import React from "react";
// import AboutMain from './AboutMain.jsx'
import Gopalji from "./../../images/gopalji.jpg";
import AboutLeft from "./AboutLeft.jsx";
import "./../../styles/About.css";
const GopalKrishna = () => {
  return (
    <div>
      <div className="gopaljiheading">
        <h1>About Gopal Krishna Goswami</h1>
      </div>
      <AboutLeft
        headingLeft="Gopal Krishna Goswami"
        paraLeft="His Holiness Gopal Krishna Goswami Maharaja appeared on this planet on the most auspicious day of Annada Ekadashi, on August 14, 1944, in New Delhi, India. Having been named Gopal Krishna at the time of his appearance, his spiritual master had not changed his name at the time of harinama initiation.

        H. Gopal Krishna Goswami Maharaja, after graduating from the University of Delhi, studied Business Management at the Sorbonne University of Paris on a scholarship from the French Government and later obtained a Masters Degree in Business Administration from the McGill University at Montreal.
        
        Meeting With His Spiritual Preceptor – His Divine Grace A C Bhaktivedanta Swami, Srila Prabhupada Having received an intimation from His grace Mahapurusa das, the then President, ISKCON, Montreal, that His Divine Grace A.C. Bhaktivedanta Swami, Srila Prabhupada, Founder Acarya, ISKCON shall be arriving in Montreal on June 1, 1968, H. H. Gopal Krishna Goswami Maharaja arrived there on May 30. After the kirtana, H. H. Gopal Krishna Goswami Maharaja enquired if he could render some service, and he was immediately sent to clean Srila Prabhupada’s apartment.

        “I was fortunate to receive this as my first assignment in devotional service,” Maharaja said. He cleaned Srila Prabhupada’s apartment’s doors, shelves, and windows. In this way, Gopal Krishna’s service attitude for Srila Prabhupada was manifested even before he met him.
        
        1968 H. Gopal Krishna Goswami Maharaja first met Srila Prabhupada on the evening of June 1, 1968. Within the first two weeks of meeting Srila Prabhupada, he decided to accept him as his spiritual master. In the words of H. H. Gopal Krishna Goswami Maharaja, “When I first met Srila Prabhupada, the motivation to surrender to God was already there in me, and two weeks after meeting him, I knew that Lord Krishna wanted me to surrender to Srila Prabhupada.”
        
        In fact, around December 1967, H. H. Gopal Krishna Goswami Maharaja had started visiting many Hindu temples, gurudwaras, and churches in his search for the Absolute Truth or God. Every Sunday, he would seek out a different church, temple, or gurudwara and spend the whole day there."
        imageLeft={Gopalji}
      />
      <div className="gopaljilower">
        <h1>Establishment Of Guru-Shisya Sambandha</h1>
        <p>
          Srila Prabhupada was always sympathetic and extra-merciful to Indians,
          but young Gopal Krishna was very special of all the Indians who came
          to hear him. While all the other Indians would offer obeisances and
          sit for a few minutes, H. H. Gopal Krishna Goswami Maharaja was the
          only Indian who would come regularly and sit right till the end of the
          lecture.
        </p>
        <p>
          During the three months that Srila Prabhupada stayed and lectured in
          Montreal, H. H. Gopal Krishna Goswami Maharaja spoke,
          <b>
            {" "}
            “Of course, I did not know the importance of hearing at that time,
            but my only small qualification was that I was very respectful to
            Srila Prabhupada from the first day I saw him, and I would stay for
            his lectures till the end and leave only after he had left the
            temple.”
          </b>
        </p>
        <p>
          Around that time, he was working for Pepsi-Cola, doing Marketing
          Research. Srila Prabhupada started taking a great personal interest in
          him and would talk to him almost every day. Since then, both started a
          regular correspondence, with Srila Prabhupada writing as many as three
          long letters every month. Thus, a very intimate guru-shisya
          relationship had already begun to establish.
        </p>
        <p>
          H.H. Gopal Krishna Goswami Maharaja was only 25 years old then! On May
          27, 1969, Srila Prabhupada wrote to say,{" "}
          <b>
            {" "}
            “As your name is already Gopal Krishna, there is no need for
            changing it. You will now be known as Gopal Krishna Das.”{" "}
          </b>
        </p>
        <p>
          Later on, H.H. Gopal Krishna Goswami Maharaja moved into the temple
          and engaged himself fully in the service of Srila Prabhupada. For many
          years, he acted as Srila Prabhupada’s secretary.
        </p>
        <p>
          In 1975, Srila Prabhupada sent H.H. Gopal Krishna Goswami to India.
          Srila Prabhupada made the General Body Commissioner (G.B.C.) of India
          from 1975 to 1976. Srila Prabhupada specifically instructed him to
          print and expand book distribution in India. Also, Srila Prabhupada
          entrusted H.H. Gopal Krishna Goswami Maharaja to oversee various
          important projects, such as Vrindavana, Hyderabad, Besalia, Angul, and
          Mumbai, recognizing his dedicated service and mood of sincere
          surrender.
        </p>
        <p>
          <b>
            {" "}
            “Srila Prabhupada was anxious that book-printing in India should
            expand,”{" "}
          </b>{" "}
          says H. H. Gopal Krishna Goswami Maharaja.{" "}
          <b>
            {" "}
            “During my first two years, he would press me again and again to
            print more and more books.”{" "}
          </b>{" "}
          H.H. Gopal Krishna Goswami Maharaja said,{" "}
          <b>
            {" "}
            “that Srila Prabhupada was especially pleased when he presented him
            copies of the First Canto of Srimad-Bhagavatam in Hindi.“{" "}
          </b>
        </p>
        <p>
          Srila Prabhupada encouraged him to utilize his talents for selling his
          books as widely as possible.
          <b>
            “Now you can utilize your knowledge of marketing for Krishna’s
            service, idam hi pumas tapasya srntasya va, svistasya suktasya ca
            buddhi dattayo. This is the perfection of education."{" "}
          </b>
        </p>
        <p>
          In 1976, Srila Prabhupada appointed H. H. Gopal Krishna Goswami
          Maharaja as G.B.C. for Russia. He made several trips to the Soviet
          Union, a communist state (now called the Commonwealth of Independent
          States), and risking grave dangers, he preached and distributed books
          over there. On the instruction of Srila Prabhupada, he took part in
          the International Book Fair, waging a virtual war against Maya.
        </p>
      </div>
    </div>
  );
};

export default GopalKrishna;
