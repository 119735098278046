import React from "react";
import img1 from "./../../images/wave-city-temple-_1_.webp";
import nitya1 from "./../../images/nitya1.webp"
import nitya2 from "./../../images/nitya2.webp"
import nitya3 from "./../../images/nitya3.webp"
import nitya4 from "./../../images/nitya4.webp"
import nitya5 from "./../../images/nitya5.webp"
import nitya0 from "./../../images/nitya0.webp"
import nitya00 from "./../../images/nitya00.webp"
import "./../../styles/Donation.css";
import Header3 from "../../components/Header3";
const NityaSeva = () => {
  return (
    <section>
      <Header3/>
      <div className="DonationUpper">
        <div className="topheadingDona">
          <h2>Become A</h2>
          <h1>Nitya Sevak</h1>
          <p>Make your Work, A Worship</p>
        </div>
        <div className="donaContent">
          <img data-aos="flip-right" src={nitya0} alt="" />
          <div className="donaContent1">
            <p className="topcontent">
              In festivals, when we visit temples there are lots of activities
              performed, whether it is deities personal service, temple
              decoration, Prasadam distribution, Kirtan seva, and Spiritual
              discourse everything is well managed. Did you ever think, how
              could this possible to run such big temples so smoothly? Of
              course, there is lots of hard work of Monks and temple management
              behind this, but there are also some Unsung Heroes who contribute
              to the Service of Krishna monthly, which need to be appreciated.
            </p>
            <p className="donacontent">
              Those Unsung heroes are our Nitya Sevak. Nitya Sevak is our
              monthly donor who contributes to the service of Radha Madan Mohan
              from their hard-earned money. They are very beloved of Krishna
              because they put Krishna’s service before their needs and Krishna
              also promises in Bhagwad Gita 9.31
            </p>
            <p className="donacontent">
              “He quickly becomes righteous and attains lasting peace. O son of
              Kunti, declare it boldly that My devotee never perishes”
            </p>
            <p className="donacontent">
              <b>Nitya Seva is a monthly donation program where</b> <br />
              <b>
                a devotee like you can monthly contribute in the lotus feet of
                the Lord.
              </b>
            </p>

            {/* <button className="btnDona">Contribution</button> */}
          </div>
        </div>
      </div>
      <div className="donaMiddle">
        <img data-aos="flip-right" src={nitya00} alt="" />
        <div className="matadona">
          <h2>Why to Become Nitya Sevak?</h2>
          <ul>
            <li>
              Nitya seva is our monthly recurring donation program, where
              devotees contribute an amount of their choice on the date of their
              choice as per their capacity. They can also stop this whenever
              they want.
            </li>
            <li>
              Their contribution utilized by the temple in several services like
              Deities personal seva, prasadam distribution, altar decoration,
              preaching, etc. as per the need.
            </li>
            <li>
              Volunteer charity is always the most important method to
              financially support spiritual causes in this world.
            </li>
          </ul>
        </div>
      </div>
      <p className="nityasevapara">
        While you get ample of mercy for engaging in service of the Supreme Lord
        yet we have an interesting package of benefit that are entitled to a
        Nitya Sevak as per the category one falls in to as per the nitya seva
        committed.
      </p>

      <div className="nityasevacontri">
        <h1>How Nitya Seva Contribution Seva is used?</h1>
        <p>
          Monthly contribution by you will be used in below mentioned five
          important sevas thus you will get benefit of five sevas through one
          Nitya Seva
        </p>
        <div className="nityaseva1">
            <div className="nityaseva2">
                <img data-aos="flip-down" src={nitya1} alt="" />
                <h3>Sri Vigraha Seva</h3>
            </div>
            <div className="nityaseva2">
                <img data-aos="flip-down" src={nitya2} alt="" />
                <h3>Sadhu Seva</h3>
            </div>
            <div className="nityaseva2">
                <img data-aos="flip-down" src={nitya3} alt="" />
                <h3>Prasadam Distribution</h3>
            </div>
            <div className="nityaseva2">
                <img data-aos="flip-down" src={nitya4} alt="" />
                <h3>Temple Maintenance</h3>
            </div>
            <div className="nityaseva2">
                <img data-aos="flip-down" src={nitya5} alt="" />
                <h3>Spiritual Education</h3>
            </div>
        </div>
      </div>
    </section>
  );
};

export default NityaSeva;
