import React,{useState,useEffect}from 'react';
import Timing from './Timing';
import '../styles/mobileviewheader.css';
import mobileviewcalender from '../images/mobileviewcalender.png';
import mobileviewcall from '../images/mobileviewcall.png';
import mobileviewmail from '../images/mobileviewmail.png';
import mobileviewlogin from '../images/lock.png';
import {Link} from 'react-router-dom';
import { SlCalender } from "react-icons/sl";
import { MdAddCall } from "react-icons/md";
import { IoMailOpenOutline } from "react-icons/io5";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function MobileViewHeader() {

    const [isCallUsOpen,setisCallUsOpen]=useState(false);
    const [isEmailOpen,setisEmailOpen]=useState(false);
    const phoneNumber = "+(91) 7008-496-872";
    const emailAddress = "info@nilachaldham.in";

    const callusMenu = () => {
      setisCallUsOpen(!isCallUsOpen);
      setisEmailOpen(false);
    };

    const emailMenu = () => {
      setisEmailOpen(!isEmailOpen);
      setisCallUsOpen(false);
    };


    const handleCopyClick = (text) => {
        const tempInput = document.createElement("input");
        tempInput.value = text;
        document.body.appendChild(tempInput);
        tempInput.select();
        tempInput.setSelectionRange(0, 99999);
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        alert(`${text} copied to clipboard`);
      };

  return (
    <>
    <div className='mobileviewheaderdiv'>
      <div className="mobileviewheaderrow">

            <div className="newheaaderrow1column1">
            <Link to="/timing" style={{color:"black"}}> <SlCalender style={{height:"40px",width:"100%",color:"white"}}/></Link>
            </div>

            <div className="newheaaderrow1column1" onClick={callusMenu}>
            <MdAddCall style={{height:"40px",width:"100%",color:"white"}}/>
            </div>

            <div className="newheaaderrow1column1" onClick={emailMenu}>
            <IoMailOpenOutline style={{height:"40px",width:"100%",color:"white"}}/>
            </div>

            <div className="newheaaderrow1column1">
            <Link style={{color:"white"}} to="/login"><AccountCircleIcon style={{height:"40px",width:"100%",color:"white"}}/></Link>
            </div>
      </div>
    </div>
    {
        isCallUsOpen &&
        <>
              <div className="callusdiv" >
              <button className='modelcloseButton1' onClick={()=>{setisCallUsOpen(false)}}>
                &times;
              </button>
                <p className='callusdivpara'>Call Us</p>
                <p className='callusdivpara'>{phoneNumber}</p>
                <button className='mobilecopynumber' onClick={() => handleCopyClick(phoneNumber)}>Copy</button>
              </div>
      </> 
      }
      {
        isEmailOpen && 
        <>
              <div className="emaildiv" >
              <button className='modelcloseButton1' onClick={()=>{setisEmailOpen(false)}}>
                &times;
              </button>
                 <p className='emaildivpara'>Email Us</p>
                 <p className='emaildivpara'>{emailAddress}</p>
                 <button className='emailcopybutton' onClick={() => handleCopyClick(emailAddress)}>Copy</button>
              </div>
        </>      
      }
    </>
  )
}
