import React from 'react';
import Downloadapp from '../images/Radha Krishna Holi.jpeg';

const DownloadApp = () => {
  const imageStyle = {
    width: '1000px',
    height: '500px',
    display: 'block',
    margin: 'auto',
  };

  return (
    <div className='downloadappdiv' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <img src={Downloadapp} alt="downloadappimg" style={imageStyle} />
    </div>
  );
};

export default DownloadApp;
