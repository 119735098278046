import React from 'react'
import Festival from '../../components/Festival'
import narsimha from "../../images/narsimha.jpg"
const NarsimhaChaturdashi = () => {
  return (
    <div>
    <Festival
      heading="Narsimha Chaturdashi"
      bold="Narasimha Chaturdashi: A Day of Lord Narasimha’s Appearance"
      img={narsimha}
      p1="Narsimha-chaturdasi is the divine appearance day of Narsimhadeva, Lord Krishna’s man-lion incarnation, who removes all obstacles on the path of devotional service out of His great compassion."
      p2="In the purport of 7.8.51 of srimad-bhagavatam, Srila Prabhupada writes, “the Supreme Personality of Godhead in his transcendental form of Lord Narsimhadev is always ready to kill the demons who always create disturbances in the minds of honest devotees. To spread the Krishna Consciousness movement, devotees have to face many dangers and impediments all over the world, but a faithful servant who preaches with great devotion to the lord must know that lord narsimhadev is always his protector."
      p3="Hiranyakashipu, a demon controlling the three world, was extremely proud and he hated Lord Vishnu. But little Prahlada, his five year old son, was a great devotee of Lord Vishnu. Therefore, Hiranyakashipu tried to kill Prahlada in several ways; but failed in all his attempts because of the protection given by Lord to his dear devotee."
      p4="Finally when Hiranyakashipu asked his little son where the Lord resides, Prahlada replied that the Lord resides everywhere. Mocking this response, Hiranyakashipu broke a pillar in his palace, and the Lord appeared from that pillar in his half man-half lion incarnation – Narasimha."
      p5="Lord Narsimhadev is therefore worshipped by the devotees as their Supreme protector and they also pray that He vanquishes all obstacles they may face on the path of devotional service. So it is with much enthusiasm that the devotees come to together to worship the Lord on this day of His appearance in the material world."
      p6="Anyone can offer pray to the Lord Narasimha on this occasion of Narasimha Chaturdashi. The reason behind this special worship is to request the Lord to guide all of us during the hard times just like he guided Prahlad."
    />
  </div>
  )
}

export default NarsimhaChaturdashi