import React from "react";
import "./../styles/gurukulteacher.css";
import Teacher from "./../images/Achievements.jpg";
const Gurukulteacher = () => {
  return (
    <section>
      <div className="gurukulteachermanagement">
        <h1>
          Management <span>Team</span>
        </h1>
        <div className="gurukulteachermanagementinner">
          <div className="gurukulteachername">
            <img src={Teacher} alt="" />
            <h2>Teacher Name</h2>
          </div>
          <div className="gurukulteachername">
            <img src={Teacher} alt="" />
            <h2>Teacher Name</h2>
          </div>
          <div className="gurukulteachername">
            <img src={Teacher} alt="" />
            <h2>Teacher Name</h2>
          </div>
          <div className="gurukulteachername">
            <img src={Teacher} alt="" />
            <h2>Teacher Name</h2>
          </div>
          <div className="gurukulteachername">
            <img src={Teacher} alt="" />
            <h2>Teacher Name</h2>
          </div>
          <div className="gurukulteachername">
            <img src={Teacher} alt="" />
            <h2>Teacher Name</h2>
          </div>
        </div>
      </div>
      <div className="gurukulteachermanagement">
        <h1>
          Our <span>Teacher</span>
        </h1>
        <div className="gurukulteachermanagementinner">
          <div className="gurukulteachername">
            <img src={Teacher} alt="" />
            <h2>Teacher Name</h2>
          </div>
          <div className="gurukulteachername">
            <img src={Teacher} alt="" />
            <h2>Teacher Name</h2>
          </div>
          <div className="gurukulteachername">
            <img src={Teacher} alt="" />
            <h2>Teacher Name</h2>
          </div>
          <div className="gurukulteachername">
            <img src={Teacher} alt="" />
            <h2>Teacher Name</h2>
          </div>
          <div className="gurukulteachername">
            <img src={Teacher} alt="" />
            <h2>Teacher Name</h2>
          </div>
          <div className="gurukulteachername">
            <img src={Teacher} alt="" />
            <h2>Teacher Name</h2>
          </div>
          <div className="gurukulteachername">
            <img src={Teacher} alt="" />
            <h2>Teacher Name</h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gurukulteacher;
