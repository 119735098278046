import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import '../styles/forgetpass.css'

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(false); // Changed the type to boolean
  const [showImage, setShowImage] = useState(false); // State to show/hide the image

  const handleResetPassword = async () => {
    try {
      if (!email) {
        setError('Please enter your email.');
        return;
      }

      await firebase.auth().sendPasswordResetEmail(email);
      alert('Password reset email sent. Please check your email inbox.');
      setSuccessMessage(true); // Set success message to true
      setShowImage(true); // Set showImage to true after success

      // Optional: You can reset the email input after sending the reset email
      setEmail('');

    } catch (error) {
      console.error('Error sending reset email:', error);
      if (error.code === 'auth/user-not-found') {
        setError('No account found with this email. Please check and try again.');
      } else {
        setError('Error sending reset email. Please try again.');
      }
    }
  };

  return (
    <div className='forgetpassdiv'>
        <div className='forgetpass'>
      <h2 className='forgetpassheading'>Forgot Password</h2>
      <input className='forgetpassinput'
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button className='forgetpassbtn' onClick={handleResetPassword}>
        Reset Password
      </button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {successMessage && showImage && (
       <a href='/login'>
       <img
          src="https://static.helpjuice.com/helpjuice_production/uploads/upload/image/2464/direct/1595552646015-1595552646015.png"
          alt="SuccessImage"
          style={{
            position: 'absolute',
            top: '170%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: '9999',
          }}
        />
        </a>
      )}
      {successMessage && (
        <p style={{ color: 'green' }}>
          Password reset email sent. Please check your email inbox.
        </p>
      )}
    </div>
    </div>
    
  );
};

export default ForgotPassword;
