import React from "react";
import AboutRight from "./AboutRight.jsx";
import AboutLeft from "./AboutLeft.jsx";
import "./../../styles/About.css";
const AboutMain = ({
  title,
  titlep,
  imageRight,
  paraRight,
  headingRight,
  imageLeft,
  paraLeft,
  headingLeft,
}) => {
  return (
    <div>
      <div className="gopaljiheading">
        <h1 className="headingtop">{title}</h1>
      </div>
      <p className="parap">{titlep}</p>
      <AboutLeft
        headingLeft={headingLeft}
        paraLeft={paraLeft}
        imageLeft={imageLeft}
      />
      <AboutRight
        headingRight={headingRight}
        paraRight={paraRight}
        imageRight={imageRight}
      />
    </div>
  );
};

export default AboutMain;
