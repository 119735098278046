import React, { useState } from "react";
import "./../styles/Header3.css";
import { Link, NavLink } from "react-router-dom";
import Icon from "../images/donateicon.png";
const Header3 = () => {
  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (index) => {
    setActiveItem(index);
  };
  
  return (
    <section>
      <div className="upperheader3">
        <div className="header3menu">
          <ul>
            <li
              className={activeItem === 0 ? "active" : ""}
              onClick={() => handleItemClick(0)}
            >
              <NavLink to="/donate-food-for-life" activeClassName="active">
                Nilachaldham
              </NavLink>
            </li>
            <li
              className={activeItem === 1 ? "active" : ""}
              onClick={() => handleItemClick(1)}
            >
              <NavLink to="/donate-gau-seva" activeClassName="active">
                Gau Seva
              </NavLink>
            </li>
            <li
              className={activeItem === 2 ? "active" : ""}
              onClick={() => handleItemClick(2)}
            >
              <NavLink
                to="/donate-temple-construction-seva"
                activeClassName="active"
              >
                Temple Construction Seva
              </NavLink>
            </li>
            <li
              className={activeItem === 3 ? "active" : ""}
              onClick={() => handleItemClick(3)}
            >
              <NavLink to="/donate-shastra" activeClassName="active">
                Gita Daan Seva
              </NavLink>
            </li>
            <li
              className={activeItem === 4 ? "active" : ""}
              onClick={() => handleItemClick(4)}
            >
              <NavLink to="/donate-deity-seva" activeClassName="active">
                Daily Deity Seva
              </NavLink>
            </li>
            <li
              className={activeItem === 5 ? "active" : ""}
              onClick={() => handleItemClick(5)}
            >
              <NavLink to="/donate-sadhu-bhojan-seva" activeClassName="active">
                Sadhu Bhojan Seva
              </NavLink>
            </li>
            <li
              className={activeItem === 5 ? "active" : ""}
              onClick={() => handleItemClick(5)}
            >
              <NavLink to="/sponsor-feast" activeClassName="active">
                Feast Sponsorship Seva
              </NavLink>
            </li>
          </ul>
        </div>
        <Link
                    style={{ textDecoration: "none", color: "#636B74" }}
                    to="https://razorpay.me/"
                  >
        <div className="header3icon">
          <img src={Icon} alt="" />
        </div>
        </Link>
      </div>
    </section>
  );
};

export default Header3;
