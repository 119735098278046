import React, { useEffect } from "react";
import "../styles/checkoutpage.css";
import Aos from "aos";
import "aos/dist/aos.css";
import logoimage from '../images/logooooo-removebg-preview-removebg-preview.png';
import { Link } from "react-router-dom";

export default function CheckoutPage() {
  useEffect(() => {
    Aos.init();
  }, []);

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "INR",

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const loadScript = (src) => {
    return new Promise((resovle) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resovle(true);
      };

      script.onerror = () => {
        resovle(false);
      };

      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async (amount) => {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      alert("You are offline... Failed to load Razorpay SDK");
      return;
    }

    const options = {
      key: "rzp_test_h9MQfOENPFcr6F",
      currency: "INR",
      amount: 50000,
      name: "ISKON PURI",
      description: "Thanks for Donating",
      image:{logoimage},
      handler: function (response) {
        alert(response.razorpay_payment_id);
        alert("Payment Successfully");
      },
      prefill: {
        name: "Saurav sable",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  return (
    <section className="checkoutSection">
      <div
        className="checkoutHeading"
        data-aos="fade-down"
        data-aos-easing="ease-in-sine"
        data-aos-delay="300"
      >
        <h1>Proceed to Donate</h1>
      </div>

      <div className="checkoutrow">
        <div className="checkoutcolumn">
          <div className="checkoutcolumn-heading">
            <h5>
              <b>DONOR INFORMATION</b>
            </h5>
          </div>
          <div
            className="checkoutpageinput"
            style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "20px",
                width: "50%",
              }}
            >
              <label
                htmlFor="firstName"
                style={{ marginBottom: "5px", fontWeight: "600" }}
              >
                First Name:
              </label>
              <input
                className="nameinput"
                type="text"
                id="firstName"
                name="firstName"
                style={{ padding: "8px", border: "1px solid #ccc" }}
              />
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "50%" }}
            >
              <label
                htmlFor="lastName"
                style={{ marginBottom: "5px", fontWeight: "600" }}
              >
                Last Name:
              </label>
              <input
                className="nameinput"
                type="text"
                id="lastName"
                name="lastName"
                style={{ padding: "8px", border: "1px solid #ccc" }}
              />
            </div>
          </div>

          <div className="checkoutpageinput" style={{ marginTop: "10px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "20px",
                width: "100%",
              }}
            >
              <label
                htmlFor="firstName"
                style={{ marginBottom: "5px", fontWeight: "600" }}
              >
                Company Name
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                style={{ padding: "8px", border: "1px solid #ccc" }}
              />
            </div>
          </div>

          <div className="checkoutpageinput" style={{ marginTop: "10px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "20px",
                width: "100%",
              }}
            >
              <label
                htmlFor="firstName"
                style={{ marginBottom: "5px", fontWeight: "600" }}
              >
                Country/Region
              </label>
              <select style={{ padding: "8px", border: "1px solid #ccc" }}>
                <option style={{ padding: "8px", border: "1px solid #ccc" }}>
                  India
                </option>
                <option style={{ padding: "8px", border: "1px solid #ccc" }}>
                  India
                </option>
              </select>
            </div>

            <div className="checkoutpageinput" style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px",
                  width: "100%",
                }}
              >
                <label
                  htmlFor="firstName"
                  style={{ marginBottom: "5px", fontWeight: "600" }}
                >
                  Street address
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="House Number and Street Name"
                  style={{
                    padding: "8px",
                    border: "1px solid #ccc",
                    textAlign: "center",
                  }}
                />
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="Apartment, suit, unit etc. (optional)"
                  style={{
                    padding: "8px",
                    border: "1px solid #ccc",
                    margin: " 10px 0px",
                    textAlign: "center",
                  }}
                />
              </div>
            </div>

            <div className="checkoutpageinput" style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px",
                  width: "100%",
                }}
              >
                <label
                  htmlFor="firstName"
                  style={{ marginBottom: "5px", fontWeight: "600" }}
                >
                  Town/City
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  style={{ padding: "8px", border: "1px solid #ccc" }}
                />
              </div>
            </div>

            <div className="checkoutpageinput" style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px",
                  width: "100%",
                }}
              >
                <label
                  htmlFor="firstName"
                  style={{ marginBottom: "5px", fontWeight: "600" }}
                >
                  State
                </label>
                <select style={{ padding: "8px", border: "1px solid #ccc" }}>
                  <option style={{ padding: "8px", border: "1px solid #ccc" }}>
                    Puri
                  </option>
                  <option style={{ padding: "8px", border: "1px solid #ccc" }}>
                    India
                  </option>
                </select>
              </div>
            </div>

            <div className="checkoutpageinput" style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px",
                  width: "100%",
                }}
              >
                <label
                  htmlFor="firstName"
                  style={{ marginBottom: "5px", fontWeight: "600" }}
                >
                  Pin Code
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  style={{ padding: "8px", border: "1px solid #ccc" }}
                />
              </div>
            </div>

            <div className="checkoutpageinput" style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px",
                  width: "100%",
                }}
              >
                <label
                  htmlFor="firstName"
                  style={{ marginBottom: "5px", fontWeight: "600" }}
                >
                  Phone
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  style={{ padding: "8px", border: "1px solid #ccc" }}
                />
              </div>
            </div>

            <div className="checkoutpageinput" style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px",
                  width: "100%",
                }}
              >
                <label
                  htmlFor="firstName"
                  style={{ marginBottom: "5px", fontWeight: "600" }}
                >
                  Email Address
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  style={{ padding: "8px", border: "1px solid #ccc" }}
                />
              </div>
            </div>

            {/* <div className="checkoutpageinput" style={{ marginTop: "10px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  id="townCity"
                  name="townCity"
                  style={{ marginRight: "5px" }}
                />
                <span>
                  <b>Create an Account?</b>
                </span>
              </div>
            </div> */}

            {/* <div className="checkoutpageinput" style={{ marginTop: "10px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  id="townCity"
                  name="townCity"
                  style={{ marginRight: "5px" }}
                />
                <span>
                  <b>Do you want 80G Benefits?</b>
                </span>
              </div>
            </div> */}
          </div>
        </div>

        <div className="checkoutcolumn">
          <div className="checkoutcolumn-heading">
            <h5>
              <b>DONATION DETAILS</b>
            </h5>
          </div>
          <table style={{ margin: "40px 0px", width: "100%" }}>
            <tbody>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td style={{ padding: "20px 10px" }}>
                  ISKCON Charity Donation - 1000&nbsp; <strong>×&nbsp;1</strong>{" "}
                </td>
                <td style={{ padding: "20px 10px" }}>
                  <span>
                    <bdi>
                      <span>₹</span>
                      1,000.00
                    </bdi>
                  </span>{" "}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                class="cart-subtotal"
                style={{
                  margin: "20px 0px",
                  fontSize: "20px",
                  borderBottom: "1px solid black",
                }}
              >
                <th style={{ padding: "20px 10px" }}>Subtotal</th>
                <td>
                  <span class="woocommerce-Price-amount amount">
                    <bdi>
                      <span class="woocommerce-Price-currencySymbol">₹</span>
                      1,000.00
                    </bdi>
                  </span>
                </td>
              </tr>

              <tr
                class="order-total"
                style={{ margin: "20px 0px", fontSize: "20px" }}
              >
                <th style={{ padding: "10px" }}>Total</th>
                <td>
                  <strong>
                    <span class="woocommerce-Price-amount amount">
                      <bdi>
                        <span class="woocommerce-Price-currencySymbol">₹</span>
                        1,000.00
                      </bdi>
                    </span>
                  </strong>{" "}
                </td>
              </tr>
            </tfoot>
          </table>

          <div id="payment" class="woocommerce-checkout-payment">
            <ul class="wc_payment_methods payment_methods methods">
              <li class="wc_payment_method payment_method_razorpay">
                <input
                  id="payment_method_razorpay"
                  type="radio"
                  class="input-radio"
                  name="payment_method"
                  value="razorpay"
                  checked="checked"
                  data-order_button_text=""
                  style={{ display: "none" }}
                />

                <label for="payment_method_razorpay">
                  UPI/Credit Card/Debit Card/NetBanking{" "}
                  <img
                    style={{ marginLeft: "20px" }}
                    src="https://cdn.razorpay.com/static/assets/logo/payment.svg"
                    alt="UPI/Credit Card/Debit Card/NetBanking"
                  />{" "}
                </label>
                <div class="payment_box payment_method_razorpay">
                  <p
                    style={{
                      border: "1px solid #ccc",
                      backgroundColor: "#ccc",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    Pay securely by UPI, Credit or Debit card or Internet
                    Banking through Razorpay.
                  </p>
                </div>
              </li>
            </ul>
            <div>
              <div class="woocommerce-terms-and-conditions-wrapper">
                <div style={{ marginTop: "20px" }}>
                  <p>
                    Your personal data will be used to process your donation,
                    support your experience throughout this website, and for
                    other purposes described in our{" "}
                    <Link
                      to="https://iskcondwarka.org/donations/?page_id=3"
                      class="woocommerce-privacy-policy-link"
                      target="_blank"
                    >
                      privacy policy
                    </Link>
                    .
                  </p>
                </div>
              </div>
              <button
                type="submit"
                name="woocommerce_checkout_place_order"
                // onClick={displayRazorpay}
                onClick={() => window.location.href = 'https://razorpay.me/'}
                value="Donate Now"
                data-value="Donate Now"
                fdprocessedid="bzxhzc"
                style={{
                  width: "100%",
                  backgroundColor: "#B799FF",
                  padding: "10px",
                  color: "white",
                  border: "none",
                  fontWeight: "600",
                }}
                
              >
                DONATE NOW
              </button>
              <input
                type="hidden"
                id="woocommerce-process-checkout-nonce"
                name="woocommerce-process-checkout-nonce"
                value="3d30277e98"
              />
              <input
                type="hidden"
                name="_wp_http_referer"
                value="/donations/?wc-ajax=update_order_review"
              />{" "}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
