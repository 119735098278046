import React from 'react';
import '../styles/whatsinside.css';
import TempleConstruction from '../images/TempleConstruction.jpeg';
import GurukulConstruction from '../images/GurukulConstruction.jpeg';
import prasadam from '../images/prasadam.JPG';
import guestHouse from '../images/guestHouse.JPG';
import GovindasRestaurant from '../images/GovindaRestaurant.JPG';
import MultipurposeHall from '../images/MultiPurposeHall.jpg';
import PrabhupadaQuerters from '../images/PrabhupadaQuarters.jpeg';
import Library from '../images/Library.JPG';
import GovindasBakery from '../images/GovindasBakery.jpeg';
import sweetstall from '../images/SweetStall.JPG';
import Diaroma from '../images/Diaroma.JPG';
import BookStall from '../images/BookStall.JPG';
// import GiftStall from '../images/GiftStall.JPG';

export default function WhatsInside() {
  return (
    <div className='whatsinsidediv'>
            <h1 className='whatsinsideheading'>What's Inside?</h1>
        <div className="whatsinsiderow">

            <div className="whatsinsidecolumn">
                    <img className='whatsinsideimage' src={TempleConstruction} alt="TempleConstruction"/>
                    <h3 className='whatsinsidepara'>Temple Construction</h3>
            </div>

            <div className="whatsinsidecolumn">
                    <img className='whatsinsideimage' src={GurukulConstruction} alt="GurukulConstruction"/>
                    <h3 className='whatsinsidepara'>Gurukul Construction</h3>
            </div>

            <div className="whatsinsidecolumn">
                    <img className='whatsinsideimage' src="https://akm-img-a-in.tosshub.com/lingo/ktak/images/story/202310/gaushala_grant-sixteen_nine.jpg?size=948:533" alt="prasadam" />
                    <h3 className='whatsinsidepara'>Gaushala Construction</h3>
            </div>

        </div>
      
        {/* <div className="whatsinsiderow">

            <div className="whatsinsidecolumn">
                    <img className='whatsinsideimage' src={guestHouse} alt="guestHouse"/>
                    <h3 className='whatsinsidepara'>Guest House</h3>
            </div>

        <div className="whatsinsidecolumn">
                    <img className='whatsinsideimage' src={GovindasRestaurant} alt="GovindasRestaurant"/>
                    <h3 className='whatsinsidepara'>Govinda's Restaurant</h3>
        </div>

        <div className="whatsinsidecolumn">
                     <img className='whatsinsideimage' src={MultipurposeHall} alt="MultipurposeHall" />
                     <h3 className='whatsinsidepara'>Multipurpose Hall</h3>
        </div>

        </div>

        <div className="whatsinsiderow">

            <div className="whatsinsidecolumn">
                    <img className='whatsinsideimage' src={PrabhupadaQuerters} alt="PrabhupadaQuerters"/>
                    <h3 className='whatsinsidepara'>Prabhupada's Quarters</h3>
            </div>

        <div className="whatsinsidecolumn">
                    <img className='whatsinsideimage' src={Library} alt="Library"/>
                    <h3 className='whatsinsidepara'>Library</h3>
        </div>

        <div className="whatsinsidecolumn">
                     <img className='whatsinsideimage' src={GovindasBakery} alt="GovindasBakery" />
                     <h3 className='whatsinsidepara'>Govinda's Bakery</h3>
        </div>

        </div>

        <div className="whatsinsiderow">

            <div className="whatsinsidecolumn">
                    <img className='whatsinsideimage' src={sweetstall} alt="sweetstall"/>
                    <h3 className='whatsinsidepara'>Sweet Stall</h3>
            </div>

        <div className="whatsinsidecolumn">
                    <img className='whatsinsideimage' src={Diaroma} alt="Diaroma"/>
                    <h3 className='whatsinsidepara'>Diaroma</h3>
        </div>

        <div className="whatsinsidecolumn">
                     <img className='whatsinsideimage' src={BookStall} alt="BookStall" />
                     <h3 className='whatsinsidepara'>Book Stall</h3>
        </div>

        </div> */}

    </div>
  )
}
