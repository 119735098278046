import React from 'react'
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import '../styles/newheader.css';
import {Link} from 'react-router-dom';

export default function NewHeader() {
  return (
    <div className='newheaderdiv'>
      <div className="newheaderrow">
        <div className="newheaderrow1column">
            <div className="newheaaderrow1column1">
            <CallIcon style={{marginRight:"10px"}}/>  +(91) 7008-496-872
            </div>

            <div className="newheaaderrow1column2">
            <MailIcon style={{marginRight:"10px"}}/>   info@nilachaldham.in
            </div>

            <div className="newheaaderrow1column3">
                <div style={{backgroundColor:"#B799FF",display:"flex",alignItems:"center",justifyContent:"center"}}>
                <marquee behavior="scroll" direction="left" scrollamount="10" loop="-1"><h3 className='newheaderheading'>Nilachal Dham Besalia is not just a place of worship; it is a holistic community where individuals can find solace, inspiration, and a deeper connection with their inner selves. Join us on this sacred journey as we strive to create an environment where spirituality, sustainable living, and community well-being converge in harmony. Together, let us explore the profound teachings of Lord Krishna and cultivate a way of life that is enriching for the mind, body, and soul, inspired by the enduring mission of Srila Prabhupad </h3>   </marquee>
                </div>
                <div className='statusdiv' style={{backgroundColor:"#B799FF",display:"flex",alignItems:"center",justifyContent:"center"}}>
                    Status
                </div>
            </div>

            <div className="newheaaderrow1column4">
              <Link style={{color:"white"}} to="/login"><AccountCircleIcon style={{fontSize:"40px"}}/></Link>
            
            </div>

        </div>
      </div>
    </div>
  )
}
