import React, { useEffect, useState } from "react";
import "../styles/home.css";
import ImageSlider from "./ImageSlider";
import Aos from "aos";
import "aos/dist/aos.css";

import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";

import { register } from "swiper/element/bundle";
register();

const firebaseConfig = {
  apiKey: "AIzaSyDh3E2F7oRTmxQHijwawr91tBYwxcd-tHU",
  authDomain: "nilachaldhamadmin.firebaseapp.com",
  projectId: "nilachaldhamadmin",
  storageBucket: "nilachaldhamadmin.appspot.com",
  messagingSenderId: "215659133248",
  appId: "1:215659133248:web:1fae766139c724a5b522a5",
  measurementId: "G-MEMWGC45FP"
};

export default function Home() {
  const [slides, setImageUrls] = useState([]);
  
  useEffect(() => {
    // Initialize Firebase app if not already initialized
    const app = initializeApp(firebaseConfig);

    // Reference to the Firebase Storage folder
    const storage = getStorage(app);
    const storageRef = ref(storage, "Nilachaldham-Slider");

    // Fetch all the images from the folder
    listAll(storageRef)
      .then((result) => {
        const urlsPromises = result.items.map((imageRef) =>
          getDownloadURL(imageRef)
        );
        return Promise.all(urlsPromises);
      })
      .then((urls) => {
        setImageUrls(urls);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <div className="homeee">
        <ImageSlider slides={slides} />
      </div>
      <div className="homeDescription">
        <h3
          data-aos="fade-up"
          data-aos-easing="ease-in-sine"
          data-aos-delay="300"
        >
        Guided by faith, forging a spiritual legacy, and shaping a harmonious 
          <br />
          society under divine light.
        </h3>
      </div>
    </>
  );
}
