import React from "react";
import "../styles/blognextpage.css";
import { CgProfile } from "react-icons/cg";
import { SlCalender } from "react-icons/sl";
import { FaRegCommentDots } from "react-icons/fa";
import govardhanpuja from "../images/Govardhan-Puja.jpg";
import LeaveReply from "./LeaveReply";
import History from "./History";

export default function GovardhanPooja() {
  return (
    <div className="blognextpagediv">
      <div className="blognextpagerow">
        <div className="blognextpagecolumn">
          <h1>Govardhan Puja 2023: Date, Time, Story & Muhurat</h1>
          <div className="headingdiv">
            <ul className="headingdivlist">
              <li className="headingdivlistitem">
                <span>
                  <CgProfile />
                </span>{" "}
                ISKON PURI
              </li>
              <li className="headingdivlistitem">
                <span>
                  <SlCalender />
                </span>{" "}
                November 17, 2023
              </li>
              <li className="headingdivlistitem">
                <span>
                  <FaRegCommentDots />
                </span>{" "}
                No Comments
              </li>
            </ul>
          </div>

          <div className="imagediv">
            <img className="mainimage" src={govardhanpuja} alt="ekadashi" />
          </div>

          <div className="paradiv">
            <div class="elementor-widget-container">
              <p>
                Govardhan Puja or Go Puja in 2023 will fall a day after Diwali.
                The celebration is also known as Annakut Puja, and is associated
                with{" "}
                <strong>
                  <a href="https://iskcondwarka.org/blogs/names-lord-krishna">
                    Lord Krishna
                  </a>
                </strong>
                . On this day, we prepare food made of cereals and offer it to
                the Lord.&nbsp;
              </p>

              <p>
                Govardhan Puja is celebrated in India, and abroad, and is one of
                the dearest festivals related to the Supreme Lord Krishna. At
                ISKCON Dwarka, this is a special day when devotees are served
                the holy bhog after the puja.&nbsp;
              </p>

              <h2 class="wp-block-heading">
                <strong>What is Meant by Govardhan?&nbsp;</strong>
              </h2>

              <p>
                Govardhan is located in Braj, about 150 kilometers from Delhi,
                in the Mathura district of Uttar Pradesh. Lord Krishna grew up
                in Braj, and many stories and miracles from His childhood days
                are associated with this place.&nbsp;
              </p>

              <p>
                An important occurrence, described in the Bhagavata Purana,
                involves Lord Krishna lifting the Govardhan hill, situated in
                the middle of Braj. Every year, devotees make a pilgrimage to
                Govardhan, and its sacred
                <a href="https://en.wikipedia.org/wiki/Govardhan_Hill">
                  {" "}
                  <strong>Govardhan Hill</strong>
                </a>
                , from different parts of India and the world.
              </p>

              <h2 class="wp-block-heading">
                <strong>What is the </strong>
                <strong>Story of Govardhan Puja</strong>
                <strong>?</strong>
              </h2>

              <div class="wp-block-image">
                <figure class="aligncenter size-large">
                  <img
                    decoding="async"
                    alt="story of Govardhan Puja"
                    data-src="https://iskcondwarka.org/wp-content/uploads/2020/11/Story-of-Govardhan-Puja.jpg"
                    className="middleimage"
                    src="https://iskcondwarka.org/wp-content/uploads/2020/11/Story-of-Govardhan-Puja.jpg"
                  />
                  <noscript>
                    <img
                      decoding="async"
                      src="https://iskcondwarka.org/wp-content/uploads/2020/11/Story-of-Govardhan-Puja.jpg"
                      alt="story of Govardhan Puja"
                      className="middleimage"
                    />
                  </noscript>
                </figure>
              </div>

              <p>
                Govardhan puja’s story revolves around Shri Krishna. Lord
                Krishna spent his childhood in Braj, and as a child, Krishna had
                shown great valor by doing heroic deeds. One such incident has
                led to this famous festival that we celebrate each year at
                ISKCON Dwarka.&nbsp;
              </p>

              <p>
                The{" "}
                <a href="http://bhagavata.org/">
                  <strong>Bhagavata Purana</strong>
                </a>{" "}
                mentions that cowherds who lived close to Mount Govardhan in
                Vrindavan used to appease Lord Indra by worshipping him,
                however, Lord Krishna had a different point of view.
              </p>

              <p>
                According to Him Mount Govardhan should have been worshipped as
                it provided livelihood to the villagers. The mountain trees give
                oxygen, and the grass is food for cattle. Lord Krishna explains
                in the Srimad Bhagavatam:&nbsp;
              </p>

              <p>
                “My dear father, our home is not in the cities or towns or
                villages. Being forest dwellers, we always live in the forest
                and on the hills.”
              </p>

              <p>
                So He advised the people to do so, and this irked Lord Indra. So
                Indra lashed down rain and storm to punish the people. But that
                plan did not go well as Lord Krishna lifted the Govardhan hill
                on His little finger and provided shelter to the people.&nbsp;
              </p>

              <p>
                For seven days and seven nights, Lord Indra brought rain and
                storm, while Shri Krishna continued to protect the people by
                holding the mountain over their heads on His little finger.
                Indra finally accepted defeat, and Shri Krishna proved to be the
                Lord of all Lords.
              </p>

              <h2 class="wp-block-heading">
                <strong>Significance of Govardhan Puja</strong>
              </h2>

              <p>
                Celebrated on the first day of Shukla Paksha in the month of
                Kartik, Govardhan Puja is a great day of rejoice at ISKCON
                Dwarka. The significance of this festival has huge importance on
                the spirituality of devotees.&nbsp;
              </p>

              <p>
                <a href="https://iskcondwarka.org/govardhana-puja/">
                  <strong>Govardhan puja</strong>
                </a>{" "}
                is done in the honor of Lord Krishna. Being celebrated a day
                after Diwali, Govardhan puja’s significance is manifold.
              </p>

              <p>
                First and foremost, Lord Krishna, the Lord of all Lords protects
                his devotees from all ills, from rain and storm. Just like He
                had done a long time ago by lifting a mountain with his little
                finger.&nbsp;
              </p>

              <p>
                Cows play an important role too during this festival, and cows
                get bathed in the morning on this day. They also get
                garlanded.In the Bhagavad Gita, the Lord instructs his devotees
                to{" "}
                <a href="https://iskcondwarka.org/blogs/cow-protection/">
                  <strong>protect the cows</strong>
                </a>{" "}
                as they give us milk, and that in turn produces ghee, which are
                used to perform Vedic ceremonies. Ghee and such milk products
                are also used to make offerings to Giriraja and Lord Giridhari.
              </p>

              <h2 class="wp-block-heading">
                <strong>How is Govardhan Puja Celebrated?</strong>
              </h2>

              <div class="wp-block-image">
                <figure class="aligncenter size-large">
                  <img
                    decoding="async"
                    alt="Govardhan Puja celebration"
                    data-src="https://iskcondwarka.org/wp-content/uploads/2020/11/How-is-Govardhan-Puja-Celebrated.jpg"
                    className="middleimage"
                    src="https://iskcondwarka.org/wp-content/uploads/2020/11/How-is-Govardhan-Puja-Celebrated.jpg"
                  />
                  <noscript>
                    <img
                      decoding="async"
                      src="https://iskcondwarka.org/wp-content/uploads/2020/11/How-is-Govardhan-Puja-Celebrated.jpg"
                      alt="Govardhan Puja celebration"
                      className="middleimage"
                    />
                  </noscript>
                </figure>
              </div>

              <p>
                <strong>Also Read:-</strong>{" "}
                <strong>
                  <a href="https://iskcondwarka.org/blogs/krishna-and-sudama-the-bond-of-true-friendship/">
                    Lord Krishna and Sudama True Bond of Friendship
                  </a>
                </strong>
              </p>

              <p>
                Govardhan Puja, like every year, is done with huge pomp and
                show. An effigy of Govardhan hill is made from cow dung. The
                puja itself involves loads of fruits, incense sticks, and even
                water, which are offered to Govardhan.&nbsp;
              </p>

              <p>
                Because cows have significance on this day, cows are also
                worshipped. The structure of Govardhan is then offered milk,
                honey, Ganga water, and once the puja is over, the prasad is
                distributed among the devotees.&nbsp;
              </p>

              <p>
                It is believed that Lord Krishna protects those who observe the
                puja on this auspicious day, and is in fact a way for the
                betterment of children.&nbsp;
              </p>

              <p>
                Apart from ISKCON Dwarka, and temples across the country, people
                also celebrate Govardhan puja at home by following the same
                rules. There are special mantras that are chanted during the
                Pujas, read on to know all about it.
              </p>

              <h2 class="wp-block-heading">
                <strong>Date &amp; Timings of Govardhan Puja 202</strong>3
              </h2>

              <p>
                Govardhan Puja for the year 2020 will fall on 14 November 2023.
                At ISKCON Dwarka the celebrations will take place in accordance
                with the divine timing.
              </p>

              <h2 class="wp-block-heading">
                <strong>FAQs on Govardhan Puja</strong>
              </h2>

              <h3 class="wp-block-heading">
                <strong>Why is Govardhan Puja Celebrated after Diwali?</strong>
              </h3>

              <p>
                Govardhan Puja is celebrated every year on the first lunar day
                of Shukla Paksha in the Hindu Calendar month of Karthik. This
                year it just so happened that the particular day fell after
                Diwali. So, technically Govardhan Puja is not celebrated after{" "}
                <strong>
                  <a href="https://iskcondwarka.org/blogs/auspicious-festival-diwali-and-its-significance">
                    auspicious festival Diwali
                  </a>
                </strong>
                .
              </p>

              <h3 class="wp-block-heading">
                <strong>How did Krishna Lift Govardhan?</strong>
              </h3>

              <p>
                Lord Krishna is the Lord of all lords, the Supreme Lord, and He
                possesses qualities that everyone aspires to have. Krishna has
                always shown an extraordinary streak since his boyhood, and He
                has been loved and adored by people through the ages.&nbsp;
              </p>

              <p>
                It is said that Lord Krishna picked the hill above His head with
                His little finger, and is hence given the name, Govardhandhari.
                Krishna being the Supreme Lord took on a beautiful form to
                protect the people of Gokul. Lord Krishna goes on to say:
              </p>

              <p>
                “I must therefore protect the cowherd community by My
                transcendental potency, for I am their shelter, I am their
                master, and indeed they are My own family. After all, I have
                taken a vow to protect My devotees.”
              </p>

              <p>
                Krishna declared to the people of Vrindavana that He Himself is
                Govardhan Hill, and hence the devotees understood that it is one
                and the same. Thus, they came to worship the hill as religiously
                as worshipping Lord Krishna himself.
              </p>

              <div class="wp-block-image">
                <figure class="aligncenter size-large">
                  <a href="https://iskcondwarka.org/donation/">
                    <img
                      decoding="async"
                      alt=""
                      data-src="https://iskcondwarka.org/wp-content/uploads/2021/01/ISKCON-FOOD-RELIF-FOUNDATION-1.jpg"
                      className="middleimage"
                      src="https://iskcondwarka.org/wp-content/uploads/2021/01/ISKCON-FOOD-RELIF-FOUNDATION-1.jpg"
                    />
                    <noscript>
                      <img
                        decoding="async"
                        src="https://iskcondwarka.org/wp-content/uploads/2021/01/ISKCON-FOOD-RELIF-FOUNDATION-1.jpg"
                        alt=""
                        className="middleimage"
                      />
                    </noscript>
                  </a>
                </figure>
              </div>

              <p>
                <strong>Also Read:-</strong>{" "}
                <strong>
                  <a href="https://iskcondwarka.org/aarti-darshan-timings/">
                    Arti-Darshan Timings
                  </a>
                </strong>
              </p>

              <h3 class="wp-block-heading">
                <strong>What is the Govardhan Puja Mantra?</strong>
              </h3>

              <p>
                The Govardhan Puja Mantra is chanted during the course of
                worship, both in homes and temples. It is as follows:&nbsp;
              </p>

              <p>
                <strong>govardhano jayati śaila-kulādhirājo</strong>
                <strong>
                  <br />
                </strong>
                <strong>yo gopikābhir udito hari-dāsa-varyaḥ</strong>
                <strong>
                  <br />
                </strong>
                <strong>kṛṣṇena śakra-makha-bhaṅga-kṛtārcito yaḥ</strong>
                <strong>
                  <br />
                </strong>
                <strong>saptāham asya kara-padma-tale ’py avātsīt</strong>
                <strong>
                  <br />
                </strong>
                <strong>
                  Śrīla sanātana Gosvāmī’s Śrī Bṛhad-bhāgavatāmṛta (1.7)
                </strong>
              </p>

              <p>
                All glories to the emperor of all the principal mountains, Śrī
                Girirāja-Govardhana, who is exalted in all splendor; who the
                gopīs proclaim to be haridāsa-varya, the best servant of Śrī
                Hari; whose worship was introduced by Śrī Kṛṣṇa when the Lord
                stopped the sacrifice for worshiping Indra, and who then
                received the good fortune of resting continuously on Śrī Kṛṣṇa’s
                lotus hand for one whole week
              </p>

              <p>
                On this day, we pray to Giriraj that one day we may be able to
                serve the divine couple when they sport in the various grooves
                on his hilltops, which are full with waterfalls and pristine
                lakes with blooming lotuses, the aroma of which attract numerous
                honey bees to them, fruit orchards which satisfy the cowherd
                friends of Shri Krishna and provide nectarian honey dripping
                from the branches, minerals with which they decorate themselves
                and beautiful caves for the pranay keli, of Shri Shri Rukmani
                Dwarkadhish.
              </p>

              <p>
                <strong>saptāham evācyuta-hasta-paṅkaje</strong>
                <strong>
                  <br />
                </strong>
                <strong>bhṛṅgāyamānaṁ phala-mūla-kandaraiḥ</strong>
                <strong>
                  <br />
                </strong>
                <strong>saṁsevyamānaṁ harim ātma-vṛndakair</strong>
                <strong>
                  <br />
                </strong>
                <strong>govardhanādriṁ śirasā namāmi</strong>
              </p>

              <p>
                Girirāja Govardhana rested like a bee upon the lotus hand of
                Acyuta for seven days. He expertly serves Śrī Hari and Śrī
                Hari’s dear associates by providing them with fruits, roots, and
                caves. I bow my head and offer praṇāma unto that Govardhana
                Hill.
              </p>

              <h3 class="wp-block-heading">
                <strong>
                  <br />
                </strong>
                <strong>
                  What are Some of the Rituals for Govardhan Puja?
                </strong>
              </h3>

              <p>
                Govardhan puja is celebrated by observing quite a few rituals
                that are a part of the festivity. One of the most important
                rituals is to create an effigy of the Govardhan Hill with cow
                dung. The other important thing is Annakut as mentioned before.
                This is a mountain of food that includes 108 types of dishes
                that are served as bhoga.&nbsp;
              </p>

              <p>
                At{" "}
                <strong>
                  <a href="https://iskcondwarka.org/">ISKCON Dwarka</a>
                </strong>
                , the Annakut is prepared with lots of love and care, while the
                deities are bathed with milk. Bhoga is of course distributed
                among the devotees. Devotees jubilantly circumambulate the hill
                of sweets and worship it as Govardhan Hill itself. A sumptuous
                feast is served on the occasion.
              </p>
            </div>
          </div>

          <LeaveReply />
        </div>

        <History />
      </div>
    </div>
  );
}
