import React from 'react'
import '../styles/whynilachaldham.css';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export default function WhyNilachaldham() {
  return (
    <div className='whynilachaldhamdiv'>
      
        <h1 className='whynilachalheading'>Why to <span style={{color:"#B799FF"}}>Choose</span> NilachalDham?</h1>

      <div className="whynilachaldhamrow">
        <div className="whynilachamdhamcolumn">
            <p>4C's required in 21st Century</p>
            <ul>
              <li><FiberManualRecordIcon style={{fontSize:"small"}}/> Creativity</li>
              <li><FiberManualRecordIcon style={{fontSize:"small"}}/> Communication skill</li>
              <li><FiberManualRecordIcon style={{fontSize:"small"}}/> Collaboration</li>
              <li><FiberManualRecordIcon style={{fontSize:"small"}}/> Critical thinking</li>
            </ul>

            <p>At VVG we focus on these 4C's while teaching our students</p>

            <p>Added advantages compared to another schooling.</p>
            <ul>
              <li><FiberManualRecordIcon style={{fontSize:"small"}}/> Holistic development of students (based on panchkosh vikas)</li>
              <li><FiberManualRecordIcon style={{fontSize:"small"}}/> Forget private coaching.</li>
              <li><FiberManualRecordIcon style={{fontSize:"small"}}/> Forget private career guidance and counselling.</li>
              <li><FiberManualRecordIcon style={{fontSize:"small"}}/> Forget peer pressure.</li>
            </ul>
        </div>
        <div className="whynilachamdhamcolumn"></div>
      </div>
      <div className="whynilachaldhamrow1">
        <button className='whynilachaldhambutton'>Read More</button>
      </div>
    </div>
  )
}
