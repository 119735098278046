import React from 'react'
import '../styles/activity.css';
import ladiesProgram from '../images/ladiesprogram.png';
import foodForLife from '../images/foodforlifeimage.JPG';
import lifemembership from '../images/lifemembership.jpeg';
import youthprogram from '../images/youthprogram.JPG';
import { Link } from 'react-router-dom';

export default function Activities() {
  return (
    <div className='activitydiv'>
            <h1 className='activityheading'>Activities</h1>

            <div className="activityrow">
            <div className="activitycolumn">
                    <div className='activitycolumnimage'>
                         <img className='activityimage' src={ladiesProgram} alt="ladiesProgram"/>
                    </div>
                    <div className='activitycolumndescription'>
                        <h2>Ladies Program</h2>
                        <p className='activitypara'>Ladies from all walks of life are welcome to learn more about ancient Vedic philosophy and culture.</p>
                    </div>     
                    <div className="activitycolumnlink">
                    <Link className='activitylink' to="/">Read More ❱</Link> 
                    </div>
            </div>

            <div className="activitycolumn">
                    <div className='activitycolumnimage'>
                         <img className='activityimage' src={foodForLife} alt="foodForLife"/>
                    </div>
                    <div className='activitycolumndescription'>
                        <h2>Monthly Krishna Katha</h2>
                        <p className='activitypara'>The Monthly Krishna Katha is a cherished spiritual gathering where devotees come together to hear and discuss the enchanting tales of Lord Krishna's divine leelas and teachings. This recurring event fosters a sense of community, fostering a deep connection with the divine through the timeless wisdom and stories of Lord Krishna.</p>
                    </div>  
                    <div className="activitycolumnlink">
                    <Link className='activitylink' to="/">Read More ❱</Link> 
                    </div>
            </div>

            <div className="activitycolumn">
                     <div className='activitycolumnimage'>
                         <img className='activityimage' src={lifemembership} alt="lifemembership"/>
                    </div>
                    <div className='activitycolumndescription'>
                        <h2>Gou Puja On Purnima</h2>
                        <p className='activitypara'>Gou Puja, observed on Purnima (full moon day), is a Hindu ritual dedicated to the worship of cows, symbolizing their sacred significance in Hindu culture. This celebration involves offering prayers, feeding cows, and expressing gratitude for their role in agriculture and sustenance. </p>
                        
                    </div> 
                    <div className="activitycolumnlink">
                    <Link className='activitylink' to="/">Read More ❱</Link> 
                    </div>
            </div> 
            </div>
           
            <div className="activityrow">
            <div className="activitycolumn">
                    <div className='activitycolumnimage'>
                         <img className='activityimage' src={youthprogram} alt="youthprogram"/>
                    </div>
                    <div className='activitycolumndescription'>
                        <h2>Youth Program</h2>
                        <p className='activitypara'>The establishment of youth activities at International Society for Krishna Consciousness (ISKCON) was a sparkling vi</p>
                      
                    </div>  
                    <div className="activitycolumnlink">
                    <Link className='activitylink' to="/">Read More ❱</Link> 
                    </div>
            </div> 

            <div className="activitycolumn">
                    <div className='activitycolumnimage'>
                         <img className='activityimage' src={youthprogram} alt="youthprogram"/>
                    </div>
                    <div className='activitycolumndescription'>
                        <h2>Youth Program</h2>
                        <p className='activitypara'>The establishment of youth activities at International Society for Krishna Consciousness (ISKCON) was a sparkling vi</p>
                     
                    </div>  
                    <div className="activitycolumnlink">
                    <Link className='activitylink' to="/">Read More ❱</Link> 
                    </div>
            </div> 

            <div className="activitycolumn">
                    <div className='activitycolumnimage'>
                         <img className='activityimage' src={youthprogram} alt="youthprogram"/>
                    </div>
                    <div className='activitycolumndescription'>
                        <h2>Youth Program</h2>
                        <p className='activitypara'>The establishment of youth activities at International Society for Krishna Consciousness (ISKCON) was a sparkling vi</p>
                        
                    </div> 
                    <div className="activitycolumnlink">
                    <Link className='activitylink' to="/">Read More ❱</Link> 
                    </div>
                   
            </div> 

            </div>
           
    </div>
  )
}
