import React from "react";
import "./../styles/aboutus.css";
import About from "./../images/aboutuss.jpg";
import Mission from "./../images/mission.png";
import Vision from "./../images/vision.png";
import IskconVideo from "./../images/iskconpuri3.mp4"
import VideoSection from "./VideoSection";
const Aboutus = () => {

  const formattedParagraph = `
    Welcome to Nilachal Dham Besalia! is an initiative of Sri Gaur
    Bhakta Vrinda Public Charitable Trust and Nilachaldham. It is a very
    unique educational concept especially designed for those who want
    their children to have a blend of both the worlds – a strong spiritual
    and cultural foundation to life and freedom to explore the educational
    academic world to have a promising career in future.
    The name itself says everything about Nilachaldham Gurukul- Here the
    word Vidya stands for all sorts of education, the word Vinay, which
    actually means humility, stands for education rooted in deep cultural
    values and ethics and Gurukul here does not just stand for house of
    the Guru/ teacher but methodology employed by teacher at his will to
    impart the best education ( शिक्षक अधिष्ठित शिक्षा ). We call
    it a Gurukul because we envision the school to be something like a
    guru’s ashrama where the student receives the care and nourishment for
    all round development. Nilachaldham Gurukul works on the overall
    development of students based on PanchakoshVikas model. Our existence
    is multidimensional and for students to groom no dimension should be
    neglected. This multidimensional development of students ensures that
    our students are physically well-built, mentally strong, emotionally
    stable, socially connected, intellectually astute, politically aware,
    culturally loyal and spiritually enlightened.
  `;
  return (
    <section className="aboutussection">
      <h1>About Us</h1>
      <VideoSection paragraph={formattedParagraph}/>
      <div className="aboutusinner">
        <h1>
          Our <span>Inspiration</span>
        </h1>
        <p>
          The guiding principles for Nilachaldham Gurukul comes from various
          inspirational sources enumerated below-
        </p>
        <ul>
          <li>
            The educational institution shall be conducted as a the Gurukul
            system, equipping the students with sound body and sound mind, so
            that they may be ideal householders in the latter part of their
            life, which will bring real peace and prosperity to the human
            society. - His Divine Grace A.C. Bhaktivedanta Swami Prabhupada,
            Founder of ISKCON.
          </li>
          <li>
            The purpose of education is to make man the highest of the mankind,
            but modern education is failing to do so. - His Divine Grace A.C.
            Bhaktivedanta Swami Prabhupada, Founder of ISKCON
          </li>
          <li>
            Education is the most powerful weapon which you can use to change
            the world” – Nelson Mandela.
          </li>
          <li>
            Literacy in itself is no education. Literacy is not the end of
            education or even the beginning. By education I mean an all-round
            drawing out of the best in the child and man-body, mind and spirit.-
            Mahatma Gandhi
          </li>
          <li>
            Education is not the learning of facts, but the training of minds to
            think.- Albert Einstein.
          </li>
        </ul>
      </div>
      <div className="abotusmission">
        <div className="aboutusmissioninner">
          <h1>
            Our <span>Mission</span>
          </h1>
          <img src={Mission} alt="" />
          <p>
            1. Spread the message of Lord Krishna which was given to Arjun
            before 5000 years ago and empowered the villagers with spiritual
            knowledge which will help them to simple living and high thinking.
            We are constructing a Jaganath ji temple where we can provide the
            teachings of lord Chaitanya. <br />
            2. To provide state-of-the-art modern education to students through
            the syllabus of NIOS and also impart value education as well as
            skill development from a very young age. <br />
            3. To provide stress-free education to aspiring students and give
            them the joy of learning. <br />
            4. To bring out latent talent and skills in students and make them a
            better student, better son/daughter in a family, and eventually
            better citizen of Bharata.
          </p>
        </div>
        <div className="aboutusmissioninner">
          <h1>
            Our <span>Vision</span>
          </h1>
          <img src={Vision} alt="" />
          <p>
            1. Weekly seminar on Bhagavad Gita
            <br />
            2. Training to local Framer to grow Organic Product
            <br />
            3. Cow Protection Training
            <br /> 4. Seminar on Build Village Economy
            <br />
            5. Ayurvedic training for local villagers
            <br />
            6. Free Medical Camp – Quarterly Once
            <br />
            7. CHILDREN’S EDUCATION(Weekly Once) - Our Children’s Education
            programs are a fun and interactive way to instill foundational
            spiritual and moral values in children from a young age. We
            recognize that today’s children are tomorrow’s leaders. We aim to
            facilitate children’s overall growth to become responsible,
            resourceful, and spiritually strong adults through a variety of
            activities that they will enjoy.(Age 5-15 Years)
          </p>
        </div>
      </div>
      <div className="aboutusmitho">
        <h1>
          Our <span>Approach and Methodology</span>
        </h1>
        <p>
          Nilachaldham Gurukul is founded on the principle of PanchakoshVikasie.
          the overall development of students from a very young age. Each day
          here is a new experience for each child because the school emphasizes
          more on the development of mind and intelligence rather than just
          focusing on marks.
        </p>
        <p>
          VVG firmly believes in drawing our kids towards Nature and
          Spirituality. We help kids to experience the divine and the divine
          love by closely associating with nature. This exposure to nature can
          be simply going for a walk along with the teacher, playing in the
          yard, or hiking in the woods, visiting nearby water bodiesand visiting
          goshalas. Nature also provokes children into thought, action, and
          formulating their own ideas. When children are involved in experiences
          with nature, they are absorbed and completely attentive to what is at
          hand.
        </p>
        <p>
          The Bal Satsang is the unique concept of our school. This satsang
          helps kids join together to chant holy names of the Lord,perform
          yajnas, attend spiritual discourses,celebrate festivals and so on....
          These spiritual activities help leading first to self-awareness, and
          later to the nurturing of basic and complex dispositions ignited by
          moments of wonderment, awe, joy, and inner peace.Eventually making
          them a good human being and a better citizen.
        </p>
        <p>
          The education offered here is Brain-Based Education. The brain accepts
          known facts first and then tries to go to the unknown, from near to
          far and so on…Nilachaldham Gurukul's primary section has taken this
          into consideration and helps the students to first understand the
          local and nearby culture and then extend their boundaries to the
          state, the country and the world.
        </p>
      </div>
    </section>
  );
};

export default Aboutus;
