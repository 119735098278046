import React from "react";
import Festival from "../../components/Festival";
import KrishnaBalram from "../../images/krishna-balram.jpg";
const BalaramJayanti = () => {
  return (
    <div>
      <Festival
        heading="Balaram Jayanti"
        bold="Ram Navami- Appearance day of great Lord Ram"
        img={KrishnaBalram}
        p1="Krishna is the Supreme Personality of the Godhead. He is the only source of all the incarnations. Balaram is better recognized as His 2nd body."
        p2="These two are not different and both of them have the same identity. As far as the dissimilarity is concerned, they vary just in the colour. Balaram is considered as the first physical expansion of Lord Krishna. He is the most powerful source of the whole religious world and the real religious master."
        p3="Lord Balaram presumes 4 different forms to serve Krishna. Lord Balaram himself helped Lord Krishna in the pastimes. He also helped in the creation of 4 other forms. These 4 forms are better known as ‘four armed’ or ‘catur vyuha’. These 4 forms describe Vasudeva, Pradyuman,Sankarshana, and Anirudha. Lord Balaram executes the whole order of Krishna’s creation work and as a Lord Sesa, He serves Lord Krishna is various ways. It was very hard to approach Lord Krishna without getting the Balaram’s mercy first."
        p4="Whenever Lord Krishna appears, He comes with His paraphernalia and associates. Around 5000 years ago when Lord Krishna appeared into the material planet, He was first headed by the Balaram.

        Balaram Ji is a 16-year-old boy, full of zeal and has a bright fair complexion. He wears blue clothes and a garland prepared with forest flowers. He usually ties His hair in a topknot. As far as the jewels are concerned, He wears earrings, bracelets, armlets and beautifully crafted anklets.
        
        When earrings touch His cheeks, He looks even more attractive. He puts tilaka on His forehead. He has a broad chest and has the graver voice. Physically He is very strong."
        p5="He is a great personality. His endless strength is enough to demolish many armies of devils. Even though, He is well aware of the super powers of His younger brother Krishna still, He never leaves Him alone in the forests. His love for Lord Krishna is beyond imagination. This is the reason that He is not just an elder brother, but a best friend too. His love for Krishna depicts the “Shanta Rasa”"
        p6="Mercy of Balaram

        Lord Balaram represents the attitude of service to Lord Krishna. Reason behind this is to please the Lord Krishna. He is a companion of Lord Krishna. He came as Lakshmana with Lord Sri Rama and later He appeared with Caitanya Mahaprabhu as Lord Nityananda.
        
        Lord Balaram is considered as the original master of religion. One who is seeking religious progress should get the Lord Balaram’s mercy first. The easiest way to approach Lord Krishna is to please Lord Balaram first. So, if you are willing to get the grace of Lord Krishna, follow the proper instructions of the spiritual master.
        
        To get the mercy of Lord Balram,we celebrate Balram Jayanti with great enthusiasm and devotion."
      />
    </div>
  );
};

export default BalaramJayanti;
