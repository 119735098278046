import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "./../images/logo2-removebg-preview.png";
import { FaBarsStaggered } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import Shrikrishna from "../images/Radha Krishna Holi.jpeg";
// import About from "../images/about.jpeg";
// import contactus from "../images/contactus.jpeg";
import temple from "../images/templetop.png";
import blog from "../images/blog.jpeg";
import Home from "./../images/home.png";
import Blog from "./../images/blog.png";
import Temple from "./../images/temples.png";
import donate from "./../images/donate.png";
import aboutus from "./../images/aboutus.png";
import contact from "./../images/contact.png";
import dharmikicon from "./../images/templee.png";
import School from "./../images/school.png"
import Info from "./../images/information.png";
import media from '../images/khata.png';

import "../styles/header.css";
// import { click } from "@testing-library/user-event/dist/click";

const Header = () => {
  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (index) => {
    setActiveItem(index);
  };

  const handleClose = () => {
    const closeBtn = document.getElementById("close-btn");
    if (closeBtn) {
      closeBtn.checked = true;
    }
  };

  return (
    <>
      <nav>
        <div className="wrapper">
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="" />
              
              {/* <h2 style={{fontWeight:"700"}}>NilachalDham</h2> */}
            </Link>
          </div>
          <input type="radio" name="slider" id="menu-btn" />
          <input type="radio" name="slider" id="close-btn" />
          <ul className="nav-links">
            <label for="close-btn" className="btn close-btn headermenu">
              <FaTimes />
            </label>
            <li
              className={activeItem === 0 ? "active" : ""}
              onClick={() => {
                handleItemClick(0);
                handleClose();
              }}
            >
              <NavLink to="/">
                <img src={Home} alt="" />
                Home
              </NavLink>
            </li>

            <li
              className={activeItem === 1 ? "active" : ""}
              onClick={() => {
                handleItemClick(1);
              }}
            >
              <NavLink to="/image-gallery" className="desktop-item">
                <img src={media} alt="" /> Media
              </NavLink>
              <input type="checkbox" id="showMega1" />
              <label for="showMega1" className="mobile-item headermenu">
              <img src={media} alt="" /> Media
              </label>
              <div className="mega-box">
                <div className="content">
                  <div className="rowww rowww0">
                    <img src={blog} alt="" />
                  </div> 

              <div className="rowww">
                    <header>Media</header>
                    <div className="rowww1">
                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                        <Link to="/image-gallery">
                          <img
                            src={dharmikicon}
                            className="dharmikicon"
                            alt=""
                          />{" "}Photo Gallery</Link>
                      </li>
                      </ul>
                      </div>

                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                        <Link to="/video-gallery">
                        <img
                            src={dharmikicon}
                            className="dharmikicon"
                            alt=""
                          />{" "}
                          Video Gallery</Link>
                      </li>
                      </ul>
                      </div>

                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                        <Link to="/construction-work-update">
                        <img
                            src={dharmikicon}
                            className="dharmikicon"
                            alt=""
                          />{" "}
                          Construction Work</Link>
                      </li>
                      </ul>
                      </div>

                    </div>
                  </div>
              </div>
              </div>
            </li>

            <li
              className={activeItem === 2 ? "active" : ""}
              onClick={() => {
                handleItemClick(2)
              }}
            >
              <NavLink to="/whats-inside" className="desktop-item">
                <img src={Temple} alt="" /> Temple
              </NavLink>
              <input type="checkbox" id="showMega2" />
              <label for="showMega2" className="mobile-item headermenu">
                <img src={Temple} alt="" /> Temple
              </label>
              <div className="mega-box">
                <div className="content">
                  <div className="rowww rowww0">
                    <img src={temple} alt="" />
                  </div>

                  <div className="rowww">
                    <header>Temple</header>
                    <div className="rowww1">
                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                        <Link to="/whats-inside" onClick={handleClose}>
                          <img
                            src={dharmikicon}
                            className="dharmikicon"
                            alt=""
                          />{" "}
                       What's Inside?
                        </Link>
                      </li>
                      <li>
                        <Link to="/timing" onClick={handleClose}>
                          <img
                            src={dharmikicon}
                            className="dharmikicon"
                            alt=""
                          />{" "}
                          Timing
                        </Link>
                      </li>
                      </ul>
                      </div>

                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                        <Link to="/activities" onClick={handleClose}>
                          <img
                            src={dharmikicon}
                            className="dharmikicon"
                            alt=""
                          />{" "}
                          Activities
                        </Link>
                      </li>
                      </ul>
                      </div>

                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                        <Link to="/events" onClick={handleClose}>
                          <img
                            src={dharmikicon}
                            className="dharmikicon"
                            alt=""
                          />{" "}
                          Events
                        </Link>
                      </li>
                      </ul>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li
              className={activeItem === 3 ? "active" : ""}
              onClick={() => handleItemClick(3)}
            >
              <NavLink to="/aboutus" className="desktop-item">
                <img src={Info} alt="" /> About Us
              </NavLink>
              <input type="checkbox" id="showMega3" />
              <label for="showMega3" className="mobile-item headermenu">
                <img src={Info} alt="" /> About Us
              </label>
              <div className="mega-box">
                <div className="content">
                  <div className="rowww rowww0">
                    <img src={temple} alt="" />
                  </div>

                  <div className="rowww">
                    <header>About Us</header>
                    <div className="rowww1">
                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                        <Link to="/aboutus" onClick={handleClose}>
                          <img
                            src={dharmikicon}
                            className="dharmikicon"
                            alt=""
                          />{" "}
                          Aboutus
                        </Link>
                      </li>
                      </ul>
                      </div>

                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                        <Link to="/faq" onClick={handleClose}>
                          <img
                            src={dharmikicon}
                            className="dharmikicon"
                            alt=""
                          />{" "}
                          FAQ
                        </Link>
                      </li>
                      </ul>
                      </div>

                      <div className="rowww1column">
                      
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li
              className={activeItem === 4 ? "active" : ""}
              onClick={() => handleItemClick(4)}
            >
              <NavLink to="/gurukula-board" className="desktop-item">
                <img src={School} alt="" /> Gurukul
              </NavLink>
              <input type="checkbox" id="showMega4" />
              <label for="showMega4" className="mobile-item headermenu">
                <img src={School} alt="" /> Gurukul
              </label>
              <div className="mega-box">
                <div className="content">
                  <div className="rowww rowww0">
                    <img src={temple} alt="" />
                  </div>

                  <div className="rowww">
                    <header>Gurukul</header>
                    <div className="rowww1">
                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                        <Link to="/gurukula-board" onClick={handleClose}>
                          <img
                            src={dharmikicon}
                            className="dharmikicon"
                            alt=""
                          />{" "}
                          OUR BOARD
                        </Link>
                      </li>
                      </ul>
                      </div>

                      <div className="rowww1column">
                      <ul className="mega-links">
                      <li>
                        <Link to="/admission-help" onClick={handleClose}>
                          <img
                            src={dharmikicon}
                            className="dharmikicon"
                            alt=""
                          />{" "}
                          Admission Help
                        </Link>
                      </li>
                      </ul>
                      </div>

                      <div className="rowww1column">
                      
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </li>

            {/* <li
              className={activeItem === 0 ? "active" : ""}
              onClick={() => handleItemClick(5)}
            >
              <NavLink
                to="/donate-temple-construction-seva"
                className="desktop-item"
              >
                <img src={donate} alt="" /> Donate Now
              </NavLink>
              <input type="checkbox" id="showMega4" />
              <label for="showMega4" className="mobile-item headermenu">
                <img src={donate} alt="" /> Donate Now
              </label>
              <div className="mega-box">
                <div className="content">
                  <div className="rowww">
                    <img src={Shrikrishna} alt="" />
                  </div>

                  <div className="rowww">
                    <header>Donate Now</header>
                    <ul className="mega-links">
                      <li>
                        <Link
                          to="https://razorpay.me/"
                          onClick={handleClose}
                        >
                          {" "}
                          <img
                            src={dharmikicon}
                            className="dharmikicon"
                            alt=""
                          />{" "}
                          Quick Donate
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/donate-temple-construction-seva"
                          onClick={handleClose}
                        >
                          <img
                            src={dharmikicon}
                            className="dharmikicon"
                            alt=""
                          />{" "}
                          Temple Construction Seva
                        </Link>
                      </li>
                      <li>
                        <Link to="/donate-shastra" onClick={handleClose}>
                          <img
                            src={dharmikicon}
                            className="dharmikicon"
                            alt=""
                          />{" "}
                          Gita Daan Seva
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/donate-sadhu-bhojan-seva"
                          onClick={handleClose}
                        >
                          <img
                            src={dharmikicon}
                            className="dharmikicon"
                            alt=""
                          />{" "}
                          Sadhu Bhojan Seva
                        </Link>
                      </li>
                      <li>
                        <Link to="/donate-food-for-life" onClick={handleClose}>
                          <img
                            src={dharmikicon}
                            className="dharmikicon"
                            alt=""
                          />{" "}
                          Nilachaldham
                        </Link>
                      </li>
                      <li>
                        <Link to="/donate-deity-seva" onClick={handleClose}>
                          <img
                            src={dharmikicon}
                            className="dharmikicon"
                            alt=""
                          />{" "}
                          Daily Deity Seva
                        </Link>
                      </li>
                      <li>
                        <Link to="/donate-gau-seva" onClick={handleClose}>
                          <img
                            src={dharmikicon}
                            className="dharmikicon"
                            alt=""
                          />{" "}
                          Gau Seva
                        </Link>
                      </li>
                      <li>
                        <Link to="/sponsor-feast" onClick={handleClose}>
                          <img
                            src={dharmikicon}
                            className="dharmikicon"
                            alt=""
                          />{" "}
                          Feast Sponsorship Seva
                        </Link>
                      </li>
                      <li>
                        <Link to="/nitya-seva" onClick={handleClose}>
                          <img
                            src={dharmikicon}
                            className="dharmikicon"
                            alt=""
                          />{" "}
                          Nitya Sevak
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li> */}
            {/* <li
              className={activeItem === 0 ? "active" : ""}
              onClick={() => {
                handleItemClick(4);
                handleClose();
              }}
            >
              <NavLink to="/about-srila-prabhupada">
                <img src={aboutus} alt="" /> About Us
              </NavLink> */}
            {/* <input type="checkbox" id="showMega3" />
              <label for="showMega3" className="mobile-item headermenu">
                About Us
              </label> */}
            {/* <div className="mega-box">
                <div className="content">
                  <div className="rowww">
                    <img src={About} alt="" />
                  </div>

                  {/* <div className="rowww">
                    <header>About Us</header>
                    <ul className="mega-links">
                      <li>
                        <Link to="/about-srila-prabhupada">
                          About Srila Prabhupada
                        >
                      </li>
                      <li>
                        <Link to="/history-Of-ISKCON">History Of ISKCON>
                      </li>
                      <li>
                        <Link to="/our-philosophy">Our Philosophy>
                      </li>
                      <li>
                        <Link to="/Best-way-to-do-charity">
                          Best way to do charity
                        >
                      </li>
                      <li>
                        <Link to="/hare-krishna-movement">
                          Hare Krishna Movement
                        >
                      </li>
                      <li>
                        <Link to="/gopal-krishna-goswami">
                          Gopal Krishna Goswami
                        >
                      </li>
                      <li>
                        <Link to="/ask-questions-ISKCON">Ask questions ISKCON>
                      </li>
                    </ul>
                  </div> */}
            {/* </div>
              </div> */}
            {/* </li> */}
            <li
              className={activeItem === 5 ? "active" : ""}
              onClick={() => {
                handleItemClick(5);
                handleClose();
              }}
            >
              <NavLink to="/Donation">
                <img src={donate} alt="" /> Donation
              </NavLink>
            </li>
            <li
              className={activeItem === 6 ? "active" : ""}
              onClick={() => {
                handleItemClick(6);
                handleClose();
              }}
            >
              <NavLink to="get-connect-locally">
                <img src={contact} alt="" /> Contact us
              </NavLink>
              {/* <input type="checkbox" id="showMega2" />
              <label for="showMega2" className="mobile-item headermenu">
                Contact us
              </label> */}
              {/* <div className="mega-box">
                <div className="content">
                  <div className="rowww">
                    <img src={contactus} alt="" />
                  </div> */}

              {/* <div className="rowww">
                    <header>Contact us</header>
                    <ul className="mega-links">
                      <li>
                        <Link to="/get-connect-locally">GET CONNECTED LOCALLY</Link>
                      </li>
                      <li>
                        <Link to="/connect-us-online">CONNECT ONLINE</Link>
                      </li>
                      <li>
                        <Link to="/how-to-reach">HOW TO REACH</Link>
                      </li>
                      <li>
                        <Link to="#">CALENDAR</Link>
                      </li>
                      <li>
                        <Link to="/get-email-update">GET EMAIL UPDATES</Link>
                      </li>
                      <li>
                        <Link to="/download-wallpaper-calender">
                          DOWNLOAD WALLPAPER
                        </Link>
                      </li>
                      <li>
                        <Link to="/download-app">DOWNLOAD APP (ANDROID)</Link>
                      </li>
                    </ul>
                  </div> */}
              {/* </div>
              </div> */}
            </li>
          </ul>
          <label for="menu-btn" className="btn headermenu menu-btn">
            <FaBarsStaggered />
          </label>
        </div>
      </nav>
    </>
  );
};

export default Header;
