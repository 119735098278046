import React, { useState, useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";
import "./../styles/socialMedia.css";
import { EffectCoverflow, Pagination } from "swiper/modules";


const firebaseConfig = {
  apiKey: "AIzaSyDh3E2F7oRTmxQHijwawr91tBYwxcd-tHU",
  authDomain: "nilachaldhamadmin.firebaseapp.com",
  projectId: "nilachaldhamadmin",
  storageBucket: "nilachaldhamadmin.appspot.com",
  messagingSenderId: "215659133248",
  appId: "1:215659133248:web:1fae766139c724a5b522a5",
  measurementId: "G-MEMWGC45FP"
};

const SocialMediaGallery = () => {
  const [imageUrls, setImageUrls] = useState([]);
  console.log(imageUrls);
  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const storageRef = ref(storage, "NilachalDham-SchoolActivities");

    listAll(storageRef)
      .then((result) => {
        const urlsPromises = result.items.map((imageRef) =>
          getDownloadURL(imageRef)
        );
        return Promise.all(urlsPromises);
      })
      .then((urls) => {
        setImageUrls(urls);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);

  return (
    <>
    <div className="socialMediaBack">
      {/* <h2 className="headingOfSocialMedia">Social Media Gallery</h2> */}
      <div className="imagesSocial">
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          modules={[EffectCoverflow, Pagination]}
          className="mySwiper"
          autoplay={{ delay: 1000 }}
          speed={800}
          // loop={true}
        >
          {imageUrls.map((url, index) => (
            <SwiperSlide key={index} className="socialmediaimagediv">
              <img key={index} src={url} alt="schoolactivityimage" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
    {/* <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d955029.6994133346!2d84.85181020631718!3d20.76761776945974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1f45addc8a3765%3A0xa93028411e8f687c!2sNilachal%20Dham%2C%20Besalia!5e0!3m2!1sen!2sin!4v1706163529166!5m2!1sen!2sin"
        width="100%"
        height="350"
        allowFullScreen=""
        loading="lazy"
        style={{ marginTop: "30px" }}
        referrerPolicy="no-referrer-when-downgrade"
        className="mobile-margin-top"
      ></iframe> */}
    </>
  );
};

export default SocialMediaGallery;
