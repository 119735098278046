import React from "react";
import Hare1 from "./../../images/hare.jpg";
import Hare2 from "./../../images/hare.jpg";
import AboutMain from "./AboutMain";
const KrishnaMovement = () => {
  return (
    <div>
      <AboutMain
        title="Hare Krishna Movement"
        titlep="We have already given you the glimpse of ISKCON, it’s mission and basic principles. To further answer to your questions on Who they are? Why do they keep singing & daning on the streets? Why they only sing one mantra repeatedly? Let us take you through the Hare Krishna movement’s origin & history."
        headingLeft="How it started?"
        paraLeft="Five hundred years ago, that same supreme cause, Lord Sri Krsna, appeared as Sri Caitanya Mahaprabhu and declared that the chanting of His holy names Hare Krsna, Hare Rama would spread beyond the shores of India to every town and village in the world. Hundreds of years passed, and Lord Caitanyas faithful followers were left wondering just how and when His bold prediction would come true.

        Then, In 1965, at the age of 69, A. C. Bhaktivedanta Swami Prabhupada boarded the steamship ‘Jaladuta’ from Mumbai on his way to United States. At an age when most people think of retiring Srila Prabhupada undertook this arduous journey to fulfill the mission of Lord Caitanya and follow the instruction of his spiritual master Bhaktisiddhanta Saraswati Thakur Prabhupada to carry the message of the Bhagavad-gita and the Srimad Bhagavatam to Western world.
        
        After arriving in New York City in September 1965, Srila Prabhupada struggled alone for the first year to establish his Krishna conscious movement. He lived simply, lectured whenever and wherever he got the opportunity and gradually began to attract some small interest in his teaching. In July of 1966, while still working from an obscure storefront on New York City’s Lower East Side, Srila Prabhupada nonetheless founded a spiritual society intended for worldwide participation. He called it International Society for Krishna Consciousness or ISKCON for short and thus began the Hare Krishna movement in America.
        
        At the time of incorporation, Srila Prabhupada had not attracted even one committed follower. Undeterred, he enlisted volunteers from among the small group of regular attendees at his evening lectures to act as ISKCON’s first trustees."
        imageLeft={Hare1}
        headingRight="What does it mean?"
        paraRight="Hare Krsna movement makes spiritual reality and culture derived from it known to the widest public. This makes it unique.

        The transcendental vibration established by the chanting of Hare Krsna, Hare Krsna, Krsna Krsna, Hare Hare/ Hare Rama, Hare Rama, Rama Rama, Hare Hare is the sublime method of reviving our transcendental consciousness. Krsna means the all-attractive person, Rama means the highest pleasure, and Hare refers to Krsnas internal pleasure potency known as Srimati Radharani.
        
        Hare Krishna Movement
        By chanting of this transcendental vibration we can cleanse our hearts of all impurities.
        
        Krsna consciousness is not an artificial imposition on the mind. This consciousness is the original natural energy of the living entity.
        
        Loud chanting has the added benefit of allowing other living beings to hear the holy names. This fits well with one of the characteristics of real religion: everyone should benefit from its performance. The Vedas proclaim, sarve sukhino bhavantu: Let everyone be happy. More than any other religious process, harinama-sankirtana fulfills that need in human society.
        
        Devotees in the Hare Krsna movement perform the traditional method of congregational chanting called kirtana. During kirtana, whether in the temple or outside, devotees usually stand or dance. One person leads the singing, and everyone else responds. Devotees hold kirtanas in the public places (so-called harinamas) so that others may benefit by hearing the Lords holy names. Krsna considers such unintentional hearing devotional service, and it entitles one to render more service in the future.
        
        “harer-nama harer-nama harer-namaiva kevalam kalau nasty-eva nasty-eva nasty-eva gatir anyatha”
        
        ‘Harinama, Harinama, Harinama is the only and foremost means to achieve emancipation. There is no other way, no other way, no other way in this age of Kali.’"
        imageRight={Hare2}
      />
    </div>
  );
};

export default KrishnaMovement;
