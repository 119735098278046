import React from "react";
import "../styles/blognextpage.css";
import { CgProfile } from "react-icons/cg";
import { SlCalender } from "react-icons/sl";
import { FaRegCommentDots } from "react-icons/fa";
import ekadashi2023 from "../images/ekadashi-2023.jpg";
import LeaveReply from "./LeaveReply";
import History from "./History";

export default function Ekadashi2023() {
  return (
    <div className="blognextpagediv">
      <div className="blognextpagerow">
        <div className="blognextpagecolumn">
          <h1>
            Ekadashi 2023 – Calendar, Vrat Timings, Puja Vidhi & Significance
          </h1>
          <div className="headingdiv">
            <ul className="headingdivlist">
              <li className="headingdivlistitem">
                <span>
                  <CgProfile />
                </span>{" "}
                ISKON PURI
              </li>
              <li className="headingdivlistitem">
                <span>
                  <SlCalender />
                </span>{" "}
                November 17, 2023
              </li>
              <li className="headingdivlistitem">
                <span>
                  <FaRegCommentDots />
                </span>{" "}
                No Comments
              </li>
            </ul>
          </div>

          <div className="imagediv">
            <img className="mainimage" src={ekadashi2023} alt="ekadashi" />
          </div>

          <div className="paradiv">
            <div class="elementor-widget-container">
              <h2 class="wp-block-heading">
                <strong>What is Ekadashi?</strong>
              </h2>

              <p>
                The literal meaning of Ekadashi is ‘the eleventh day’. There are
                two Ekadashis in a month: on the eleventh day after the new
                moon, and on the eleventh day after the full moon. Of all vratas
                (vows of austerity), Lord Vishnu is most pleased with those who
                observe the Ekadashi vrat.
                <br />
                Therefore, anyone who does not observe the vrata and eats grains
                on Ekadashi is said to eat sin.
              </p>

              <h2 class="wp-block-heading">
                <strong>Significance of Ekadashi</strong>
              </h2>

              <p>
                Observing a vrata (usually fasting) is meant to control our
                senses and mind because without which, it may be hard for us to
                focus our attention on the Supreme. Therefore, Ekadashis are
                days meant for purification of the mind and curtailing bodily
                demands.
              </p>

              <p>
                Abstaining from food will control our urge for taste and
                appetite and if we observe the vrata regularly, we can bridle
                our senses by controlling the mind. The vrata is intended to
                control the tongue as well. During the Ekadashi-vrata, we should
                never even think of using foul language.{" "}
                <strong>
                  <a href="https://iskcondwarka.org/blogs/names-lord-krishna">
                    Lord Krishna
                  </a>
                </strong>{" "}
                tells in Uddhava Gita (14.36):
              </p>

              <p>
                <strong>
                  “shamo man-nishthata buddher dama indriya samyamah
                </strong>
                <strong>
                  <br />
                </strong>
                <strong>
                  titiksha duhkha-sammarsho jihvopastha-jayo drdhih”
                </strong>
              </p>

              <p>
                <strong>This means:</strong>
              </p>

              <p>
                “Control of mind is engaging the intelligence in the love of Me
                and self-control is controlling the senses. Tolerance is
                subsiding mental distress and firm determination is conquering
                the tongue, genitals, and lusty desires.”
              </p>

              <p>
                Among all the senses, the tongue is the most important. Anyone
                who can control the taste of tongue and speech can control the
                mind and such a person alone can become a perfect devotee of
                God. Therefore, Ekadashi vratas are very important for a devotee
                to perfect his love of God and a true lover of Krishna should
                not miss these opportunities.
              </p>

              <h2 class="wp-block-heading">
                <strong>What are the Ekadashi fasting rules?</strong>
              </h2>

              <p>
                His Divine Grace,
                <a href="https://en.wikipedia.org/wiki/A._C._Bhaktivedanta_Swami_Prabhupada">
                  {" "}
                  <strong>A.C. Bhaktivedanta Swami Prabhupada</strong>
                </a>
                , has given quotes in books, lectures and especially in
                conversations with other devotees. The quotes below come from
                his Divine Grace:
              </p>

              <p>
                “On Ekadashi, everything is cooked for Vishnu, including regular
                grains and dal, but it is enjoined that a Vaishnava should not
                even take vishnu-prasadam on Ekadashi. It is said that a
                Vaishnava does not accept anything edible that is not offered to
                Lord Vishnu, but on Ekadashi a Vaishnava should not touch even
                maha-prasadam offered to Vishnu, although such prasadam may be
                kept for being eaten the next day.”
              </p>

              <p>
                Srila Prabhupada goes on to say, “Since the living entities of
                Kali yuga are short lived and lusty, they are unable to perform
                severe austerity. The living entities of Kali yuga subsist on
                food grains and cannot survive without eating these grains.
                People of Satya, Treta and Dvapara yugas were capable of
                undergoing severe austerity and could tolerate physical
                distress.”
              </p>

              <p>
                He adds, “This is why a minimum austerity in the form of fasting
                twice a month on the days of Ekadashi has been prescribed for
                the people of Kali yuga. If they are able they should eat only
                once the day before Ekadashi, fast totally on the day of
                Ekadashi and eat only once the day after Ekadashi.”
              </p>

              <p>
                If one is unable to follow even this, in other words if one is
                unable to eat only once on the day before and after Ekadashi,
                then he must observe complete fast on the day of Ekadashi.
              </p>

              <p>
                If one is still unable to follow this, then he should give up
                eating five types of grains and observe the vow of Ekadashi
                simply by partaking some fruits and roots. The five types of
                grains to be avoided are:
              </p>

              <ul>
                <li>
                  Rice or other products made from rice such as flat rice and
                  puffed rice
                </li>

                <li>Wheat flour and white flour</li>

                <li>Barley</li>

                <li>Pulses such as moong, chickpea, green peas, lentil etc.</li>

                <li>Mustard oil and sesame oil</li>
              </ul>

              <p>
                The way Srila Prabhupada asked us to follow Ekadashi was to fast
                from grains and beans. Full{" "}
                <strong>
                  <a href="https://iskcondwarka.org/blogs/benefits-ekadashi-fasting/">
                    Ekadashi fasting
                  </a>
                </strong>{" "}
                was never encouraged by His Divine Grace.
              </p>

              <p>
                Rather than emphasizing various dry austerities, he put greater
                emphasis on service (which is sometimes hampered by fasting, and
                perhaps further hampered for many days by staying up all night).
              </p>

              <p>
                Therefore, Srila Prabhupada only insisted on 4 full fast days a
                year for certain Appearance Days and eating very simply on
                Ekadashis.
              </p>

              <p>
                Full fasting, and optionally for every Ekadashi, is an optional
                vrata and good if it inspires a deeper feeling of love for
                Krishna.
              </p>

              <p>
                <strong>What is the ISKCON Ekadashi 2023 Calendar?</strong>
              </p>

              <p>
                The ISKCON Ekadashi 2023 Calendar is a subset of the Besalia, Angul
                Vaishnava Calendar or simply the Hare Krishna Calendar. It gives
                a list of all the Ekadashi in a particular year. Let us try to
                understand the calendar. There are two types of ancient vedic
                calendar systems, the Lunar (moon) calendar and the Solar (sun)
                calendar.
              </p>

              <p>
                Most of the scholars who have studied these old vedic calendars
                are of the opinion that the lunar system is more ancient. The
                lunar phases may have an effect on agriculture, and according to
                scriptures like Manu-samhita (The Law of Manu) they also
                influence subtler aspects of human life.
              </p>

              <p>
                Gaurabda is the name of the lunar calendar based on Lord Sri
                Caitanya Mahaprabhu’s appearance year. Lord Sri Caitanya
                Mahaprabhu is Krishna’s incarnation in Kali-yuga. He appeared in
                1486 at Sridhama Besalia, Angula in the city of Navadvipa, West Bengal,
                India.
              </p>

              <p>
                Lord Caitanya was also known as Gaura because of His beautiful
                golden complexion. So the calendar based on this Golden
                Incarnation of Krishna is called Gaurabda. It is used as the
                main calendar of the International Society for Krishna
                Consciousness (
                <a href="https://iskcondwarka.org/">
                  <strong>ISKCON</strong>
                </a>
                ) and Gaudiya vaishnava followers.
              </p>

              <p>
                The years in the Gaurabda calendar are counted from the
                appearance day of Lord Sri Caitanya Mahaprabhu. In the Gaurabda
                vaisnava calendar the times for various celebrations are
                determined by the tithi, sometimes with nakshatra and other
                elements of the calendar taken into account. Each month, or
                ‘masa’ is known by the name of Vishnu.
              </p>

              <p>
                <strong>
                  The ISKCON Ekadashi 2020 Calendar is as follows:
                </strong>
              </p>

              <figure class="wp-block-table">
                <table>
                  <tbody  style={{"border-collapse": "collapse"}}>
                    <tr >
                      <td>
                        <strong>Putrada Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>January 6</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Sat Tila Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>January 20</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Bhaimi Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>February 5</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Vijaya Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>February 19</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Amalaki Vrata Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>March 6</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Papamochani Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>March 20</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Kamada Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>April 4</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Varuthini Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>April 18</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Mohini Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>May 4</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Apara Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>May 18</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Pandava Nirjala Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>June 2</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Yogini Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>June 17</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Sayana Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>July 1</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Kamika Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>July 16</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Pavitropana Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>July 30</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Annada Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>August 15</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Parsva Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>August 29</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Indira Ekadasi –</strong>
                      </td>
                      <td>
                        <strong>September 13</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Parama Ekadasi –</strong>
                      </td>
                      <td>
                        <strong>October 13</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Pasankusa Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>October 27</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Rama Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>November 11</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Utthana Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>November 26</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Utpanna Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>December 11</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Moksada Ekadashi –</strong>
                      </td>
                      <td>
                        <strong>December 25</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </figure>

              <h2 class="wp-block-heading">
                <strong>What are the Vrat Timings of Ekadashi in 2023?</strong>
              </h2>

              <p>
                The Vrat timings are different from city to city within India as
                well as across the world. Please visit the ISKCON temple in your
                city to know the exact timing for the upcoming{" "}
                <a href="https://iskcondwarka.org/ekadashi/">
                  <strong>Ekadashi</strong>
                </a>
                .
              </p>

              <h2 class="wp-block-heading">
                <strong>What is the Puja Vidhi of Ekadashi?</strong>
              </h2>

              <p>
                Devotees who observe the Puja Vidhi wake up before sunrise and
                take a bath. After that, they offer prayers to Lord Vishnu and
                Lord Krishna. They offer tulsi leaves, flowers and dhoop to the
                deity while worshipping. They also{" "}
                <a href="https://iskcondwarka.org/blogs/krishna-mantra/">
                  <strong>chant hare Krishna</strong>
                </a>{" "}
                mantras and sing bhajans for the almighty.
              </p>

              <p>
                Devotees fast on this day, observe vigil the whole night and do
                Japa, Hari Kirtan and meditation. Some do not take even a drop
                of water. Those who are unable to fast completely can take some
                light fruit and milk. If one observes the Ekadashi fast
                regularly, all sins are washed away and the mind is purified.
              </p>

              <figure class="wp-block-image size-full is-resized">
                <img
                  decoding="async"
                  alt=""
                  className="middleimage"
                  data-src="https://iskcondwarka.org/wp-content/uploads/2023/11/food-for-life.jpg"
                  src="https://iskcondwarka.org/wp-content/uploads/2023/11/food-for-life.jpg"
                />
                <noscript>
                  <img
                    decoding="async"
                    width="848"
                    height="200"
                    src="https://iskcondwarka.org/wp-content/uploads/2023/11/food-for-life.jpg"
                    alt=""
                    class="wp-image-15711"
                    style={{"width":"840px","height":"auto"}}
                    srcset="https://iskcondwarka.org/wp-content/uploads/2023/11/food-for-life.jpg 848w, https://iskcondwarka.org/wp-content/uploads/2023/11/food-for-life-300x71.jpg 300w, https://iskcondwarka.org/wp-content/uploads/2023/11/food-for-life-768x181.jpg 768w, https://iskcondwarka.org/wp-content/uploads/2023/11/food-for-life-441x104.jpg 441w, https://iskcondwarka.org/wp-content/uploads/2023/11/food-for-life-600x142.jpg 600w, https://iskcondwarka.org/wp-content/uploads/2023/11/food-for-life-150x35.jpg 150w"
                    sizes="(max-width: 848px) 100vw, 848px"
                  />
                </noscript>
              </figure>

              <p>
                During Ekadashi,{" "}
                <a href="https://iskcondwarka.org/blogs/disappearance-day-of-srila-prabhupada">
                  <strong>Srila Prabhupada</strong>
                </a>{" "}
                advised to chant the Mahamantra as much as possible. The
                Mahamantra we follow in ISKCON is “Hare Krishna Hare Krishna
                Krishna Krishna Hare Hare / Hare Rama Hare Rama Rama Rama Hare
                Hare.” One should try as many rounds of the Mahamantra as
                possible on the Ekadashi days.
              </p>

              <h2 class="wp-block-heading">
                <strong>FAQs related to Ekadashi 202</strong>3
              </h2>

              <h3 class="wp-block-heading">
                <strong>How many Ekadashi are there in 2020?</strong>
              </h3>

              <p>
                There are 25 Ekadashis in 2020 because July had an additional
                one. The following Ekadashis are yet to come in this year:
              </p>

              <figure class="wp-block-table">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <strong>27 Oct 2020 –</strong>
                      </td>
                      <td>
                        <strong>Pasankusa Ekadashi</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>11 Nov 2020 –</strong>
                      </td>
                      <td>
                        <strong>Rama Ekadashi</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>26 Nov 2020 –</strong>
                      </td>
                      <td>
                        <strong>Utthana Mahadvadashi</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>11 Dec 2020 –</strong>
                      </td>
                      <td>
                        <strong>Utpanna Ekadashi</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>25 Dec 2020 –</strong>
                      </td>
                      <td>
                        <strong>Moksada Ekadashi</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </figure>

              <h3 class="wp-block-heading">
                <strong>Can we wash hair on Ekadashi?</strong>
              </h3>

              <p>
                Men should refrain from shaving their beard and women should
                avoid washing their hair on Ekadashi.
              </p>

              <h3 class="wp-block-heading">
                <strong>How can I Break my Ekadashi fast?</strong>
              </h3>

              <p>
                The observers of vrata on Ekadashis sometimes prefer to fast,
                and/or fast without drinking water, and/or spend a sleepless
                night. Sometimes devotees chant and praise the Lord always and
                keep awake the whole night.
              </p>

              <p>
                Such observers of vrata should break the fast methodically. It
                may be safe to drink one or more glasses of water with lemon
                juice and a little bit of salt and black pepper as an appetizer
                and eat food after a couple of hours.
              </p>

              <h3 class="wp-block-heading">
                <strong>When Should we Start Ekadashi Fast?</strong>
              </h3>

              <p>
                The Ekadashi fast starts from sunrise on the day of Ekadashi to
                sunrise on the following day.
              </p>

              <h2 class="wp-block-heading">
                <strong>Which is the Most Important Ekadashi?</strong>
              </h2>

              <p>
                The Pandava is the most important Ekadashi. It is observed on
                the eleventh day of the bright fortnight of Moon (shukla-paksha)
                in the month of Jyeshtha (May-June). It is called Nirjala
                Ekadashi because on that day the observer of the vrata should
                not drink even water.
              </p>
            </div>
          </div>

          <LeaveReply />
        </div>

        <History />
      </div>
    </div>
  );
}
