import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase/compat/app'; // Use 'firebase/compat/app' instead of 'firebase/app' for Firebase version 9+
import 'firebase/compat/auth'; // Import the authentication module for Firebase version 9+
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
const firebaseConfig = {
  apiKey: "AIzaSyDh3E2F7oRTmxQHijwawr91tBYwxcd-tHU",
  authDomain: "nilachaldhamadmin.firebaseapp.com",
  projectId: "nilachaldhamadmin",
  storageBucket: "nilachaldhamadmin.appspot.com",
  messagingSenderId: "215659133248",
  appId: "1:215659133248:web:1fae766139c724a5b522a5",
  measurementId: "G-MEMWGC45FP"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const auth = getAuth(firebase.initializeApp(firebaseConfig));
// Set persistence to 'local' so that the user remains logged in across browser restarts
setPersistence(auth, browserLocalPersistence);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
