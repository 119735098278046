import React from 'react'
import Festival from '../../components/Festival'
import chandan from "../../images/chandan-yatra.jpg"

const ChandanYatra = () => {
  return (
    <div>
      <Festival
        heading="Chandan Yatra"
        bold="Chandan Yatra starts from Akshaya Tritiya"
        img={chandan}
        p1="This Yatra starts in the summers and lasts up to 21 days. During the auspicious ChandanYatra, all the devotees anoint the Deities with sandalwood paste. It is among the most sacred Yatra in India.        "
        p2="According to the Vedic calendar, AkshayaTritiya is considered as the most significant and auspicious date. Traditionally, the day of AkshayaTritiya is extremely important because people who are aware of the advantages of this special day used to schedule major events on this day."
        p3=" It is believed that when you schedule events like marriages, business venture, initiations, and GrihaPravesh on AkshayaTritiya, it would be more blissful and auspicious."
        p4="This beautiful Yatra begins on the 3rd lunar day of the Vaisakha month and lasts up to 20-21 days. It is believed that Lord Jagannath instructed the great King Indradyuma to celebrate this beautiful festival at this particular phase of time. Devotees used to anoint the sandalwood paste over the body of the Lord. We all know that Vaisakha is one of the hottest months, so the cooling properties of the sandalwood paste pleases the body of the Lord."
        p5="Apart from the eyes, the sandalwood paste is applied all over the body of Lord Jagannath. The functional deities are taken in the long march and are put in a boat. To honor this sacred festival, Lord Caitanya also carried out for water activities with His associates."
        p6="We also at ISKCONDwarka,hold this festival every year,where devotees come and make sandalwood paste which is applied to the Deities.Beautiful artwork is also done on the bodies of the Deities to decorate them."
      />
    </div>
  )
}

export default ChandanYatra