import React from "react";
import Festival from "../../components/Festival";
import ekadashi from "../../images/ek.jpg";

export default function Ekadashi() {
  return (
    <div>
      <Festival
        heading="Ekadashi"
        bold="Ekadashi is considered as an important day for Vaishnavites. This day comes twice in a Lunar month, in the dark fortnight and in the bright fortnight. One the day of Ekadashi, it is recommended to not eat or drink anything for the entire day. According to scriptures,it is said that sin resides in food grains on Ekadasi. So,one should abstain oneself from grains and lentils on this day. Vaishnavites fast on Ekadasi for the pleasure of Lord Krishna."
        img={ekadashi}
        p1="Things to do on Ekadashi Day :
 
        Wake up early and take a shower. Stay calm and relax your mind and body.
        Do not eat grains and lentils.
        Give maximum time for spiritual activities like chanting the Holy Name, reading and hearing about the glories of The Lord.
        If it is not possible to remain empty stomach,then one can have fruits or Ekadasi food prasad.
        If have unintentionally missed Ekadashi, you can also fast on the next day of Ekadashi and discontinue fast on the third day. To avoid such mistakes, stay updated with Ekadashi calendar always.
        Do not sleep in the daytime and also avoid shaving and oil massage this day.
        If you don’t fast on Ekadashi, avoid having a meal more than one time in a day. It is recommended to not to have a meal after midday during Ekadashi.
        Always make sure to break the fast during the Paran Time.
        Also, avoid having heavy food initially as your stomach was empty during fasting and it might pain your stomach to eat heavy food all of sudden. Make sure to start a day with fresh fruit juices or light food.
        Also, make sure to not overeat a day before fasting as it might become difficult for you to stay empty stomach on the day of fasting.
        Why is Ekadashi Fasting good for you?
        
        Good for digestion:
        
        There is no doubt that fasting on such an auspicious day of Ekadashi brings spiritual benefits in your life, but fasting is also good for your health. Since, you keep your body away from the heavy meals three times in a day on the fasting day,so your body as well as the mind feels more relaxed than other days.
        
        
        Connects your soul with the super soul:
        
        To get rid of your sins and bad karma, it is essential for the people who fast on Ekadashi to remember supreme power for the entire day. If you don’t keep God in your thoughts, your fasting may go useless. So, fasting done with a pure heart can connect your soul with the super soul.
        
        
        Liberation from the life and death cycle:
        
        Hindu sages believe that the Ekadashi fasting can help you to liberate from the large life and death cycles if fasting is done without any feeling of greediness.
        
        At Iskcon Dwarka, Ekadashi is celebrated with a great enthusiasm and reverence. 24 hours Sankirtan is held. So, come and get yourself filled with a great devotion."
        p2="What is the purpose of celebrating Ekadashi?
        Ekadashi, derived from the Sanskrit words ‘eka,’ which means ‘one,’ and ‘Dasha,’ which means ‘ten,’ occurs on the eleventh day of each lunar fortnight. This day is extremely spiritually significant in the Hindu religion, representing a time for thought, meditation, and communion with the divine Supreme Personality of Godhead, Lord Krishna.
        
        Ekadashi represents a break from daily life, a chance to purify the body, mind, and spirit. Devotees attempt to achieve greater consciousness and draw closer to the divine energies that rule the universe by abstaining from particular meals(grains, lentils, beans etc.)  and engaging in spiritual practices.
        
        Importance of Donating on the Auspicious Day of Ekadashi
        Ekadashi is a wonderful occasion to show goodwill to needy people in the spirit of selfless generosity. Donating on this auspicious day helps society and aligns you with the beneficial divine energies throughout Ekadashi.
        
        Donating on Ekadashi is a gesture that goes beyond the physical sphere. It is an expression of gratitude for the abundance in our lives and a realisation of all beings’ connectivity. Your donation to Ekadashi becomes a sacred offering, enhancing the impact of your generosity, whether it be supporting educational projects, providing meals to the impoverished, or contributing to the care of sacred locations."
        p3=" Why Donate on Ekadashi to ISKCON Dwarka?
        ISKCON Dwarka is a spiritual wisdom lighthouse that embodies Lord Krishna’s teachings. Your Ekadashi donation to ISKCON goes beyond a monetary contribution; it becomes a spiritual investment. The proceeds are used for various projects, including educational programmes, community development, and temple maintenance.
        
        Supporting ISKCON Dwarka on Ekadashi is a conscientious decision to connect your resources with an uplifting and enlightening cause. You actively disseminate the divine message of love, peace, and harmony as you contribute to the blooming of this spiritual paradise.
        
        Can I get a Tax benefit on the Ekadashi donation?
        Yes, your great act of donating to Ekadashi has concrete consequences. Donations to ISKCON Dwarka are tax-deductible under Section 80G of the Income Tax Act. This means you contribute to the greater good and obtain a financial benefit through tax exemption.
        
        Your gift becomes a two-fold investment: one in the spiritual realm and one in good citizenship. This tax break serves as a reminder that your acts, even if they are charitable, can enhance both your spiritual well-being and your financial portfolio."
        p4="What should be donated to Ekadashi?
        Ekadashi promotes a multifaceted approach to giving. While donating money to charity is always welcome, consider expanding your giving to include non-monetary contributions. Donating necessities, sponsoring meals for the less fortunate, and supporting educational efforts are all worthwhile ways to contribute on this auspicious day.
        
        ISKCON Dwarka accepts various contributions, allowing you to select a way of giving that aligns with your values and purposes. Your contribution, no matter how large or small, can change lives and contribute to the community’s overall well-being.
        
        What not to do on Ekadashi?
        As you embark on this sacred journey of Ekadashi observance, it is critical to remember specific practices that are forbidden on this day. It is usual to abstain from grains, beans, and certain vegetables throughout Ekadashi fasting. Furthermore, avoiding negative thoughts, speech, and acts becomes critical to completely harness Ekadashi’s spiritual powers.
        
        Ekadashi’s essence is an intentional and disciplined commitment to self-purification. You can increase the transforming potential of this hallowed day by avoiding certain behaviours and embracing a positive mindset."
        p5="Can we give money to Ekadashi?
        Your commitments and contributions can support this auspicious day’s different endeavours and projects. Giving to associations that effectively advance the profound meaning of Ekadashi, like ISKCON Dwarka, guarantees that your money turns into a course for positive change.
        
        Your financial support helps to sustain and build spiritual communities, thereby preserving and transmitting old wisdom in modern times. Thanks to such donations, Ekadashi’s teachings continue to thrive and touch the lives of people seeking spiritual progress.
        
        Importance of Ekadashi Observance
        Observing Ekadashi is a changing occasion that rises above existence limits. It is a day to divert inside and withdraw from the rest of the world, plunging into the profundities of one’s profound cognisance. Ekadashi is seen by fasting, prayer, and contemplation, establishing a helpful climate for self-awareness and reflection.
        
        Ekadashi’s elevated spiritual vibrations allow for a stronger connection with the divine. Individuals can experience inner calm, clarity, and heavenly grace by immersing themselves in prayer and meditation. Ekadashi’s significance rests not only in the act of observation but also in its tremendous impact on one’s spiritual journey."
        p6="Remembering Lord Krishna on Ekadashi Day
        As a day dedicated to Lord Krishna, Ekadashi occupies a particular place in the hearts of devotees. Krishna’s teachings, as the highest personality of Godhead, reverberate with everlasting wisdom, guiding individuals on the path of righteousness and spiritual awakening. Devotees immerse themselves in Lord Krishna’s recollection of Ekadashi, seeking His heavenly blessings and guidance.
        
        Your Ekadashi observance and donation to ISKCON Dwarka become a means for you to honour Lord Krishna and embrace His teachings. Connecting your life with Lord Krishna’s love, compassion, and devotion ideals is a purposeful decision.
        
        Harmony in Generosity: Uniting Spirituality and Selfless Giving on Ekadashi
        To summarise, Ekadashi is more than just a calendar date; it is a sacred occasion for spiritual elevation and unselfish offerings. By donating to ISKCON Dwarka on Ekadashi, you actively support establishing a spiritual community that propagates the divine word. Your gift connects the physical and spiritual worlds, promoting positive change.
        
        Iskcon Dwarka invites you to join the common journey of spiritual enlightenment and compassionate generosity via food donations for the poor and needy. Your contribution is more than a transaction; it is a sacred offering that resonates in the realms of eternity, connecting with Ekadashi’s everlasting knowledge."
      />
    </div>
  );
}
