import React from 'react'
import srilaprabhu from '../images/Srila-Prabhu-3.png';
import dailydarshan from '../images/dailydarshan.jpg';
import upcomingevent from '../images/upcomingEvent.jpg';

export default function History() {
  return (
    <div className="blognextpagecolumn blognextpagecolumn1">
            <div className="historyofiskon">
                <img className="historyofiskonimage" src={srilaprabhu} alt="/srilaprabhu" />
                <p style={{fontWeight:"700"}}>History of ISKON</p>
            </div>

            <div className="contributiondiv">
                <p style={{backgroundColor:"brown",padding:"10px",color:"white",fontWeight:"800",borderRadius:"10px 10px 0px 0px",marginBottom:"0px"}}>Contribute</p>
                <img style={{width:"100%"}} src={dailydarshan} alt="dailydarshan" />
            </div>

            <div className="contributiondiv">
              
                <p style={{backgroundColor:"black",padding:"10px",color:"white",fontWeight:"800",marginBottom:"0px"}}>Daily Darshan</p>
                <img style={{width:"100%"}} src={dailydarshan} alt="dailydarshan" />
            </div>

            <div className="contributiondiv">
                <p style={{backgroundColor:"black",padding:"10px",color:"white",fontWeight:"800",marginBottom:"0px"}}>Upcoming Events</p>
                <img style={{width:"100%"}} src={upcomingevent} alt="dailydarshan" />
                
            </div>
        </div>
  )
}
