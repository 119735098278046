import React from "react";
import Donation from "../../components/Donation";
import food1 from "../../images/food1.jpg";
import food2 from "../../images/food2.png";
import Header3 from "../../components/Header3";
import Foodforlife2 from "../../components/Foodforlife2";
import Donate from "../../components/Donate";
import CompFoodForLife from "./../../components/FoodForLife.jsx"
import VideoSection from "../../components/VideoSection.jsx";
const FoodForLifeobj = [
  { title: "5 Meals", price: "₹250" },
  { title: "10 Meals", price: "₹500" },
  { title: "20 Meals", price: "₹1000" },
  { title: "50 Meals", price: "₹2500" },
  { title: "100 Meals", price: "₹5000" },
  { title: "250 Meals", price: "₹12500" },
];

const FoodForLife = () => {
  const paragraph =
    "At Nilachal Dham Besalia, we are dedicated to not only spiritual enrichment but also the holistic well-being of our community. As a part of our commitment to local empowerment, we provide training programs for local farmers, imparting Vedic agricultural techniques and sustainable practices. Our emphasis on Cow Protection extends beyond the spiritual significance, promoting the well-being of these gentle creatures and fostering a sense of responsibility towards the environment.";
  return (
    <>
      <div>
        {/* <Header3/> */}
        <Donation
          topheading="Nilachaldham Besalia"
          toppara="“Nilachal Dham Besalia is not just a place of worship; it is a holistic community where individuals can find solace, inspiration, and a deeper connection with their inner selves. Join us on this sacred journey as we strive to create an environment where spirituality, sustainable living, and community well-being converge in harmony. Together, let us explore the profound teachings of Lord Krishna and cultivate a way of life that is enriching for the mind, body, and soul, inspired by the enduring mission of Srila Prabhupad.”"
          para1="Our mission encompasses the joyous chanting of the holy name through 'Hariname Sankirtan fostering spiritual education through the establishment of 'Gurukuls,' dedicated service to cows through 'Gou Seva,' and the dissemination of profound wisdom encapsulated in the teachings of the Bhagavad Gita."
          
          para2="We are committed to creating an environment where the mellifluous resonance of the divine name, the pursuit of knowledge in the ancient gurukul tradition, compassionate care for sacred cows, and the sharing of the timeless wisdom from the Bhagavad Gita are integral parts of our collective journey towards spiritual enlightenment. Through these pillars, we aspire to cultivate a harmonious and spiritually enriching community devoted to the principles of love, service, and wisdom."
          li1="In our pursuit of spiritual and educational excellence, we embrace the concept of Gurukul. Gurukuls are traditional Indian educational institutions where students live with their spiritual teacher, imbibing not just academic knowledge but also immeasurable wisdom about life, ethics, and spirituality. The Gurukul system places a strong emphasis on a holistic approach to education, fostering not only intellectual growth but also character development. Students in Gurukuls learn through close association with their Guru (teacher), imitating their conduct, and imbibing values through daily life experiences. This ancient educational model reflects a profound understanding of the interconnectedness of knowledge, values, and spiritual growth. Our commitment to the Gurukul tradition underscores our dedication to nurturing well-rounded individuals who not only excel in their academic pursuits but also contribute positively to society with a deep sense of moral and spiritual integrity."
          li2=""
          li3=""
          img1="https://qph.cf2.quoracdn.net/main-qimg-842ad5cd9cf7247f3fc2ed464a17b78d-lq"
          img2="https://www.bhaktivedantavidyabhavan.com/wp-content/uploads/2023/10/students-and-teachers-1024x681-1.jpg"
        />
        {/* <div>
        <div className="donaLower">
          <h1>Contribute For Food Distribution!!</h1>
          <div className="donationdona">
            {FoodForLifeobj.map((i, key) => (
              <div className="donadona" key={key}>
                <div className="contentdon">
                  <h2>{i.title}</h2>
                  <p>{i.price}</p>
                </div>
                <button
                  className="btnDona"
                  onClick={() =>
                    (window.location.href = "https://razorpay.me/")
                  }
                >
                  Donation
                </button>
              </div>
            ))}
          </div>
        </div>
      </div> */}
      </div>
      <Foodforlife2 />
      <VideoSection paragraph={paragraph}/>
      <CompFoodForLife />

      {/* <Donate /> */}
    </>
  );
};

export default FoodForLife;
