import React from 'react';
import "../styles/blognextpage.css";
import LeaveReply from "./LeaveReply";
import History from "./History";
import { CgProfile } from "react-icons/cg";
import { SlCalender } from "react-icons/sl";
import { FaRegCommentDots } from "react-icons/fa";
import { useLocation } from "react-router-dom";

export default function BlogNext() {
  const location = useLocation();
  const entryData = location.state?.entryData || {};

  console.log("Received Data",entryData);

  return (
    <div className="blognextpagediv">
      <div className="blognextpagerow">
        <div className="blognextpagecolumn">

          <h1>{entryData.title}</h1>
          <div className="headingdiv">
            <ul className="headingdivlist">
              <li className="headingdivlistitem"><span><CgProfile /></span>{" "}ISKON PURI</li>
              <li className="headingdivlistitem"><span><SlCalender /></span>{" "}{entryData.date}</li>
              <li className="headingdivlistitem"><span><FaRegCommentDots /></span>{" "}No Comments</li>
            </ul>
          </div>

          <div className="imagediv">
            <img className="mainimage" src={entryData.imageUrl} alt="ekadashi" />
          </div>

          <div className="paradiv" style={{marginTop:"30px"}}>

          <h2 className="wp-block-heading">{entryData.heading1}</h2>

          <p>{entryData.paraghaph1}</p>

          <h2 className="wp-block-heading">{entryData.heading2}</h2>

           <p>{entryData.paragraph2}</p> 

          </div>

           <LeaveReply/>

        </div>

        <History/>
        
      </div>
    </div>
  )
}
