import React from 'react'
import IskconVideo from "./../images/iskconpuri3.mp4"
import '../styles/videosection.css';

export default function VideoSection({paragraph}) {
  return (
    <div className='videosectiondiv'>
        <div className="videosectioncolumn">
        <video className='videosectionvideo'controls>
          <source src={IskconVideo} type="video/mp4" />
          <source src={IskconVideo} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
        </div>

        <div className="videosectioncolumn videosectioncolumn1">
            <p className='videosectionparagraph'>{paragraph}</p>
        </div>
    
    </div>
  )
}
