import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
const firebaseConfig = {
  apiKey: "AIzaSyDh3E2F7oRTmxQHijwawr91tBYwxcd-tHU",
  authDomain: "nilachaldhamadmin.firebaseapp.com",
  projectId: "nilachaldhamadmin",
  storageBucket: "nilachaldhamadmin.appspot.com",
  messagingSenderId: "215659133248",
  appId: "1:215659133248:web:1fae766139c724a5b522a5",
  measurementId: "G-MEMWGC45FP"
};

// Initialize Firebase if it's not already initialized
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

// Get the Firebase Storage instance
const storage = getStorage(app);
export { db };
// Get the Firebase Authentication instance
const auth = getAuth(app);
// Set persistence to 'local' so that the user remains logged in across browser restarts
setPersistence(auth, browserLocalPersistence);

export { storage, auth}; // Export the 'storage' and 'auth', Firestore instance modules
export default app; // Export the Firebase app instance




// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBq9tcd5-P6VM1gYs-di4hA_oieZWQcT2w",
//   authDomain: "nilachaldham-ad428.firebaseapp.com",
//   projectId: "nilachaldham-ad428",
//   storageBucket: "nilachaldham-ad428.appspot.com",
//   messagingSenderId: "107017346701",
//   appId: "1:107017346701:web:b8ef72762cfe3879798cc4",
//   measurementId: "G-YF33VM0V1P"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

