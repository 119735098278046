import React from "react";
// import About from "./../../images/aboutsection.jpg";
import "./../../styles/About.css";
const AboutRight = ({headingRight,paraRight,imageRight}) => {
  return (
    <section>
      <div className="aboutupperright">
        <img
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
          src={imageRight}
          alt=""
        />
        <div className="aboutinner">
          <h2>{headingRight}</h2>
          <p>
           {paraRight}
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutRight;
