import React from "react";
// import Donate from "../components/Donate";
import Home from "../components/Home";
import VideoGallery from "../components/VideoGallery.jsx";
// import FloatButton from "../components/FloatButton.jsx";
import News from "../components/News";
import SocialMediaGallery from "../components/SocialMediaGallery";
// import Header from "../components/Header";
import InfoIskcon from "../components/InfoIskcon";
import FoodForLifes from "./Donation/FoodForLife.jsx";
import HomeinfoSlider from "../components/HomeinfoSlider.jsx";
import Hometable from "../components/Hometable.jsx";
import DonationChild from "../components/DonationChild.jsx";
import FAQ1 from "../components/FAQ.jsx";
import ProposedPlan from "../components/ProposedPlan.jsx";
import VideoSection from "../components/VideoSection.jsx";
import WhyNilachaldham from "../components/WhyNilachaldham.jsx";



const HomeScreen = () => {
  return (
    <div className="App">
      {/* <Content/> */}
      <Home />
      <FoodForLifes />
      <WhyNilachaldham/>
      <ProposedPlan />

      {/* <HomeinfoSlider /> */}
      
      {/* <Hometable /> */}
      {/* <DonationChild /> */}

      {/* <FAQ1 /> */}
      {/* <GetNewsData/> */}

      {/* <News/>  */}
      {/* <VideoGallery /> */}

      {/* <SocialMediaGallery /> */}
      {/* <InfoIskcon /> */}

      {/* <FloatButton /> */}
    </div>
  );
};

export default HomeScreen;
