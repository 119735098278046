import React from "react";
import AboutMain from "./AboutMain";
import Donate1 from "./../../images/donate1.jpg";
import Donate2 from "./../../images/donate2.jpg";

const Donate = () => {
  return (
    <div>
      <AboutMain
        title="Best way to do charity"
        titlep="‘Donate’ means helping others, spreading kindness. This not only helps others but also helps us.

        It keeps our heart soft, gives us peace of mind and purifies our earnings. . Doing work for a noble cause always brings positivity to a person.
        
        SB 11.27.51 – Lord Krishna tells Uddhava: “One who offers the Deity gifts of land, markets, cities and villages so that the regular daily worship and special festivals of the Deity may go on continually will achieve opulence equal to My own”"
        headingLeft="Why donate to ISKCON?"
        paraLeft="The more we help others, the more we feel happier. The various researches show that people who give time, money, or support to others are more likely to be happy and satisfied with their lives. There are plenty of reasons to donate or do charity at ISKCON. We have been working for humanity for the last many years. Your small contribution can help us do something for others in a bigger way. Providing help to the needy is our major and only concern. If you are willing to help and donate some amount, you can visit our website and donate online charity as convenient to you."
        imageLeft={Donate1}
        headingRight="Where to donate?"
        paraRight="As said in Bhagavad Gita 18.5- acts of sacrifice, charity and penance are not to be given up; they must be performed. Indeed, sacrifice, charity and penance purify even the great souls.

        To donate you can click on our following links / projects as per your interest.
        
        Sudama Seva : Show your love for God by serving the temple.
        
        Bhagavad Gita : Give others the opportunity to learn about the teachings of Bhagavad Gita.
        
        Shravan Kumar Seva : Join our program to feed expecting mothers and senior citizens
        
        Nilachaldham : Join our mission to end world hunger
        
        "
        imageRight={Donate2}
      />
    </div>
  );
};

export default Donate;
