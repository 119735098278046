import React from 'react'
import '../styles/admissionhelp.css';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import HomeIcon from '@mui/icons-material/Home';
import admission from '../images/mission.png'
export default function AdmissionHelp() {
  return (
    <div className='admissionhelpdiv'>
      <h3 className="script"><span><em className="text-color">Admission</em> Help</span></h3>

      <div className="admissionhelprow">

        <div className="admissionhelpcolumn1">
            <div className="admissionhelpcolumninnerdiv">
                <div className="admissionhelpicondiv">
                    <CallIcon/>
                </div>
                <div className="admissionhelpinfodiv">
                    <p>+91 7008-496-872</p>
                    <p>+91 7008-496-872</p>
                </div>
            </div>

            <div className="admissionhelpcolumninnerdiv">
                <div className="admissionhelpicondiv">
                    <MailIcon/>
                </div>
                <div className="admissionhelpinfodiv">
                    <p>if@nilachaldham.in</p>
                </div>
            </div>

            <div className="admissionhelpcolumninnerdiv">
                <div className="admissionhelpicondiv">
                    <HomeIcon/>
                </div>
                <div className="admissionhelpinfodiv">
                    <p>Nilachaldham Besalia Gurukula and International School</p>
                </div>
            </div>
        </div>

        <div className="admissionhelpcolumn2">

            <div className="admissionhelpcolumn2column1">
                <img src={admission} alt="" style={{width:"300px"}}/>
            </div>

            <div className="admissionhelpcolumn2column2">
            <div className="admissionhelpicondiv" style={{color:"white",backgroundColor:"red"}}>
                    Upcoming Admission Test Dates:
                </div>
                <div className="admissionhelpinfodiv" style={{boxShadow:"none",backgroundColor:"#B799FF",color:"black",fontWeight:"700"}}>
                    <ul style={{display:"flex",justifyContent:"space-evenly",paddingLeft:"0px"}}> 
                        <li className='admissionhelpli'>Date</li>
                        <li className='admissionhelpli'>Venue</li>
                        <li className='admissionhelpli'>Apply Now</li>
                    </ul>
                </div>
                <div className="admissionhelpinfodiv">
                <ul style={{display:"flex",justifyContent:"space-evenly",paddingLeft:"0px"}}> 
                        <li className='admissionhelpli'>4th Feb 2024</li>
                        <li className='admissionhelpli'>Vrindaranyam Campus</li>
                        <li className='admissionhelpli'>Apply Now</li>
                    </ul>
                </div>
                <div className="admissionhelpicondiv" style={{color:"red",backgroundColor:"red",borderRadius:"0px 0px 10px 10px"}}>
                    Upcoming Admission Test Dates:
                </div>
            </div>
              

        </div>
      </div>
    </div>
  )
}
