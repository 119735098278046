import React from "react";
// import About from "./../../images/aboutsection.jpg";
import "./../../styles/About.css";
const aboutLeft = ({ headingLeft, paraLeft, imageLeft }) => {
  return (
    <section>
      <div className="aboutupper">
        <img
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          src={imageLeft}
          alt=""
        />
        <div className="aboutinner">
          <h2>{headingLeft}</h2>
          <p>{paraLeft}</p>
        </div>
      </div>
    </section>
  );
};

export default aboutLeft;
