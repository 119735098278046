import React from "react";
import Festival from "../../components/Festival";
import jagannath from "../../images/jagannath.jpg";
const JagannathRathYatra = () => {
  return (
    <div>
      <Festival
        heading="Jagannath Rath Yatra"
        bold="The Rath Yatra festival is celebrated in Orissa with great enthusiasm and devotion. It is a very beautiful pastime of the Lord which is celebrated as Rath Yatra."
        img={jagannath}
        p1="16 days before the Rath Yatra, when the moon is full, the Lord Jagannath takes bath and become sick. After that, He is confined to take rest for the next 14 days. He is then provided with special care until He becomes fit for the Rath Yatra.

      The special bath takes place in the month of Jyestha during Purnima. This memorializes the appearance of great Lord Jagannath. According to the sacred Skanda Purana, when king Indradyumna placed the wooden-made Deities, he organized this special bathing ceremony. The day is considered as the appearance day of Lord Jagannath. A spiritual text written in the Oriya language is known as the ‘Niladri Mohadaya’. In this text, we could find the records of this fabulous festival. The earliest form of Lord Jagannath is known as the ‘Nilamadhava’. If you are willing and curious enough to find the story of Lord Jagannath, then it is beautifully described in the Jagannath Priyan Natakam. It is the most popular drama of Lord Jagannath. From that time, Sauras and Daitas are the people who have the exclusive right to organize this auspicious festival."
        p2="One day before the Snana Yatra, all the Deities, including Lord Jagannath, Subhadra, Balabhadra, and Sudarshana are brought out from the room to the pandals. The height of the pandals is extremely high. All the devotees stand outside and get a glance of all the Deities.

      After that Deities are taken for the Pahandi or Pahandi Vijay ceremony. All the bathing pandals are decorated beautifully and the paintings of gardens and trees will surely mesmerize you. Arches and flags are prepared with the mango leaves. All the Deities are decorated with fresh flower and then perfumes like Aguru and dhupa offered to them."
        p3="Puri is the main center point of this auspicious Rath Yatra. The whole process is as follows, firstly Mangala Aarti is held. Then, the Mahasuaras and Suaras walk off in the ritual parade to carry some sacred water from the famous Golden Well in 108 vessels of gold and copper. They also cover their mouth with cloths so that the purity will remain same. They preserved water and bring it to the Bhoga Mandap. They use Benachera, Aguru, Turmeric, Chandan, Fresh Flowers, Medicinal Herbs, and Perfumes for the purification of the water."
        p4="On the Purnima Tithi, the bathing festival takes place. All the filled vessels then moved to Snana Vedi from the Bhoga Mandap by the Sauras.

      Once the Snana Yatra is done, all the Deities are kept away from the view of the public for the next 15 days. During these 15 days, all the regular activities of temples stay suspended. When Lord Jagannath is not available in the temple, Caitanya Mahaprabhu felt a deep distant as he could not see the Lord Jagannath. It is believed that Lord Jagannath instructed Caitanya Mahaprabhu himself for this ceremony. During these 15 days, all the Deities are kept in a special sick-room which is called the Ratan Vedi. It is present in the temple usually."
        p5="The 15 days time period is defined as the Anabasara Kaal, which simply means ‘improper time of worshiping’. In this particular time period, all the youngsters restore, repaint and decorate all the Deities. On the sixteenth day, the Deities become ready for the public view. This Jagannath Rath Yatra is also popular as Netrotsava. The Gaudiya Vaishnavism founder Sri Chaitanya Mahaprabhu lived and performed many of his pastimes in Jagannath Puri and personally participated in Lord Jagannath’s pastimes.

      As a small child, Srila Prabhupada was very attracted to the forms of Jagannatha, Baladev and Subhadra, and he had his father build a small ratha cart so that he and his friends could hold their own festival. In 1976, ISKCON held the first Ratha-yatra festival outside India, along the streets of San Francisco. Since, Ratha-yatra is held along the main streets of most major cities of the world. Some cities organize Ratha-yatra with three separate chariots as in Jagannatha Puri, but in other cities, Jagannatha, Baladeva and Subhadra sit together in the same chariot."
        p6="Every year at ISKCON Dwarka,a special Rath Yatra Festival is organized.Lord Jagannatha, Mother Subhadra and Lord Baladeva are so merciful that they come out of the temple to bless the people. Amidst the roaring kirtan, a huge procession is carried out on the roads of Dwarka. Devotees sing the sacred mahamantra and dance. The Rath of the Lords is pulled by thousands of devotees to get the mercy.It is a mesmerizing sight and blissful experience."
      />
    </div>
  );
};

export default JagannathRathYatra;
