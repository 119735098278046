import { useCallback, useEffect, useRef, useState } from "react";
import "../styles/imageslider.css";

const ImageSlider = ({ slides}) => {
  const timerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  console.log(slides);
  
  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = useCallback(() => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }, [currentIndex, slides]);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      goToNext();
    }, 5000);

    return () => clearTimeout(timerRef.current);
  }, [goToNext]);

  const slidesContainerStyles = {
    display: "flex",
    height: "80vh",
    transition: "transform ease-out 1.8s",
    width: `${slides.length * 100}%`,
    transform: `translateX(${-(currentIndex * (100 / slides.length))}%)`,
  };


  return (
    <div className="sliderStyles">
      <div > 
        <div onClick={goToPrevious} className="rightArrowStyles">
          ❰
        </div>
        <div onClick={goToNext} className="leftArrowStyles">
          ❱
        </div>
      </div>
      <div className="slidesContainerOverflowStyles">
        <div style={slidesContainerStyles}>
        {slides.map((slide, slideIndex) => (
          <div key={slideIndex} className="slidestyle">
            <img
              src={slide}
              alt={`Slide ${slideIndex}`}
              className="imgStyles"
            />
          </div>
        ))}
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;
