import React, { useState, useEffect } from "react";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";
import '../styles/VideoGallery.css';

const firebaseConfig = {
  apiKey: "AIzaSyDh3E2F7oRTmxQHijwawr91tBYwxcd-tHU",
  authDomain: "nilachaldhamadmin.firebaseapp.com",
  projectId: "nilachaldhamadmin",
  storageBucket: "nilachaldhamadmin.appspot.com",
  messagingSenderId: "215659133248",
  appId: "1:215659133248:web:1fae766139c724a5b522a5",
  measurementId: "G-MEMWGC45FP"
};

const VideoGallery = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const app = initializeApp(firebaseConfig);

  useEffect(() => {
    const storage = getStorage(app);
    const storageRef = ref(storage, "Nilachaldham-Video");
    listAll(storageRef)
      .then((result) => {
        const urlsPromises = result.items.map((videoRef) => getDownloadURL(videoRef));
        return Promise.all(urlsPromises);
      })
      .then((urls) => {
        setImageUrls(urls);
      })
      .catch((error) => {
        console.error("Error fetching videos:", error);
      });
  }, [app]);

  const[model,setmodel]=useState(false);
  const [tempimgSrc,settempimgSrc]=useState('');
  const getImg=(imgSrc)=>{
    settempimgSrc(imgSrc);
    setmodel(true);
  }

  return (
    <>
    <div className={model?"model open":"model"}>
    <img className='modelimage' src={tempimgSrc} alt="" />
    <button className='modelcloseButton' onClick={()=>{setmodel(false)}}>
          &times;
    </button>
    </div>
    <div className='videogallery'>
  {
    imageUrls.map((item, index) => {
      if (index % 3 === 0) {
        return (
          <div className='row1' key={index / 3}>
            {imageUrls.slice(index, index + 3).map((itemInRow, innerIndex) => (
              <div className='videogalleryvideodiv' key={index + innerIndex} onClick={() => { getImg(itemInRow) }} data-aos="zoom-out">
                <video className="videogalleryvideo" src={itemInRow} muted controls autoPlay></video>
              </div>
            ))}
          </div>
        );
      }
      return null;
    })
  }
</div>
    </>
  );
};

export default VideoGallery;


