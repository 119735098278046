import React from 'react'
import Festival from "../../components/Festival";
import janmashtami from '../../images/ISKCON-Janmashtami-Celebration.jpg';

export default function Janmashtami() {
  return (
    <div>
      <Festival
        heading="ISKCON Janmashtami Celebration"
        bold="The celebrations at ISKCON revolve around the all-encompassing Lord Krishna, and Janmashtami is perhaps the biggest of them all. ISKCON Janmashtami celebration happens all across the world with great Devotion. Janmashtami refers to the day when the Appearance of Lord Krishna, the supreme Lord, is celebrated."
        img={janmashtami}
        p1="On this day, you will find that ISKCON temples around the globe are decorated and devotees come in large numbers to rejoice. A supremely auspicious day for Hare Krishna devotees, here now we will understand the meaning of Janmashtami in full depth."
        p2="On the day of Sri Krishna Janmashtami, it is imperative that you visit the temple. At the ISKCON Temples, the festivities are in full swing. The grand festival goes on for the entire day, and besides puja, there are a lot of other activities happening. Every ISKCON Temple comes up with something unique during this time, and involve large numbers of people in the festivities."
        p3=" ISKCON prepares Prasadam for devotees, which is first offered to the Supreme Lord as Bhoga, and then there are games and activities organized for children in some temples. The Deities of Radha Krishna are adorned with beautiful flowers, amazing ornaments, and celebrations of Janmashtami are conducted with love and devotion. "
        p4="What is the Story of Janmashtami?
        The story of Janmashtami is the story of the Advent of Lord Krishna. It is celebrated each year on the eighth day of the Bhadrapada month. The Supreme Lord appeared in Mathura in a dungeon to Queen Devaki and King Vasudeva."
        p5=""
        p6=""
      />
    </div>
  )
}
