import React from 'react'
import Festival from "../../components/Festival";
import govardhanpuja from '../../images/Govardhan-Puja.jpg';

export default function GovardhanPuja() {
  return (
    <div>
      <Festival
        heading="Govardhana Puja"
        bold="The 10th canto of the Srimad Bhagavatam tells so many interesting pastimes of Lord Krishna. ‘The Lifting of Govardhan Hill’ is one of the favorites of devotees."
        img={govardhanpuja}
        p1="It narrates the pastime of the Goverdhan Hill that was lifted by the Lord Krishna. According to the pastime, the Lord Krishna ,who was just 7 years old, lifted the hill on the tip of His little finger for seven days and seven nights.. The aim of lifting the hill was to provide the shelter for all the Vrndavan residents and animals from the dangerous rainstorm which was sent by the ‘King of Heaven’ and ‘God of rain’, Lord Indra. As soon as he realized the power of Lord Krishna, he surrendered to Him."
        p2="When we see this festival traditionally, it is one of the most auspicious occasion in Vrndavan. A huge amount of mouth-watering bhoga is prepared and offered to Lord Krishna. It is arranged by the devotees in the form of the Govardhan Hill. It is better known as Anna (grains) Kuta (mountain). It takes place just after the Diwali."
        p3="At ISKCOND warka, all the devotees celebrate this festival with lots of happiness and reverence. They cook lots of sweets and unique halvah. After that, the food is arranged in the shape of the Govardhan Hill. Later, all the devotees revolve around the replica hill. Puja of Govardhan Hill is performed with the devotional kirtan filling the whole atmosphere with ecstasy. In the end, the prasadamis served to all the devotees and visitors."
        p4=""
        p5=""
        p6=""
      />
    </div>
  )
}
