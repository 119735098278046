import React, { useEffect } from "react";
import "./../styles/footer.css";
import Fb from "./../images/fb.png";
import Insta from "./../images/insta.png";
import Tweet from "./../images/twee.png";
import Youtube from "./../images/youtube.png";
import Linkedin from "./../images/linkidin.png";
import logo from "../images/logooooo-removebg-preview-removebg-preview.png";
import { TiTickOutline } from "react-icons/ti";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import TelegramIcon from '@mui/icons-material/Telegram';
const Footer = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <>
        <footer className="footer--light">
          <div className="footer-big">
            <div className="container">
              <div className="footerIcon">
                <ul>
                  <li>
                    <Link
                      data-aos="zoom-in"
                      data-aos-easing="ease-in-sine"
                      data-aos-delay="300"
                      to="https://www.facebook.com/iskconpuri123?mibextid=ZbWKwL"
                      target="_blank"
                      rel="noopener noreferrer"
                      whileHover="hover"
                      whileTap="tap"
                    >
                      <div>
                        <img src={Fb} alt="" />
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-aos="zoom-in"
                      data-aos-easing="ease-in-sine"
                      data-aos-delay="400"
                      to="https://x.com/iskconpuri12?t=NAh6IYHVBeXdIMv8tNrjBA&s=09"
                      target="_blank"
                      rel="noopener noreferrer"
                      whileHover="hover"
                      whileTap="tap"
                    >
                      <div>
                        <img src={Tweet} alt="" />
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-aos="zoom-in"
                      data-aos-easing="ease-in-sine"
                      data-aos-delay="500"
                      to="https://instagram.com/iskconpuri?igshid=YTQwZjQ0NmI0OA=="
                      target="_blank"
                      rel="noopener noreferrer"
                      whileHover="hover"
                      whileTap="tap"
                    >
                      <div>
                        <img src={Insta} alt="" />
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-aos="zoom-in"
                      data-aos-easing="ease-in-sine"
                      data-aos-delay="600"
                      to="https://www.linkedin.com/in/iskcon-puri-5395a4269?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                      target="_blank"
                      rel="noopener noreferrer"
                      whileHover="hover"
                      whileTap="tap"
                    >
                      <div>
                        <img src={Linkedin} alt="" />
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-aos="zoom-in"
                      data-aos-easing="ease-in-sine"
                      data-aos-delay="600"
                      to="https://youtube.com/@ISKCONPURI?si=DPGKZg46A5BooRp6"
                      target="_blank"
                      rel="noopener noreferrer"
                      whileHover="hover"
                      whileTap="tap"
                    >
                      <div>
                        <img src={Youtube} alt="" />
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="footer-widget">
                    <div className="widget-about">
                      {/* <img
                        decoding="async"
                        src="images/bg-1.png"
                        alt=""
                        className="img-fluid"
                      /> */}
                      <h1 className="footerheading">Nilachaldham Besalia</h1>
                      <p>
                        Spread the message of Lord Krishna which was given to
                      </p>
                      <ul className="footerul" style={{paddingLeft:"0px"}}>
                        <li className="footerli">
                          <a href="#" className="footerlia" >
                          <LocationOnIcon/> 
                             Besalia, Nabamunda, Odisha 759118
                          </a>
                        </li>
                        <li className="footerli">
                          <a href="#" className="footerlia" >
                          <CallIcon/>  +(91) 7008-496-872
                          </a>
                        </li>
                        <li className="footerli">
                          <a href="#" className="footerlia" >
                          <MailIcon />  info@nilachaldham.in
                          </a>
                        </li>
                      </ul>

                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="footer-widget">
                    <div className="footer-menu">
                      <h4 className="footer-widget-title">Quick Links</h4>
                      <ul className="footerul" style={{paddingLeft:"0px"}}>
                        <li style={{color:"black"}}>
                          <a href="/">
                          <FiberManualRecordIcon style={{fontSize:"small"}}/> Home
                          </a>
                        </li>
                        <li>
                          <a href="/Donation">
                          <FiberManualRecordIcon style={{fontSize:"small"}}/>  Contribute
                          </a>
                        </li>
                        <li>
                          <a href="/gurukula-board">
                          <FiberManualRecordIcon style={{fontSize:"small"}}/>  Gurukul
                          </a>
                        </li>
                        <li>
                          <a href="/blog">
                          <FiberManualRecordIcon style={{fontSize:"small"}}/> Blog
                          </a>
                        </li>
                        <li>
                          <a href="/events">
                          <FiberManualRecordIcon style={{fontSize:"small"}}/> Events
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="footer-widget">
                    <div className="footer-menu">
                      <h4 className="footer-widget-title">Gallery
</h4>
                      <ul className="footerul" style={{paddingLeft:"0px"}}>
                        <li>
                        <a href="/get-connect-locally">
                          <FiberManualRecordIcon style={{fontSize:"small"}}/> Contact Us

                          </a>
                        </li>

                        <li>
                        <a href="/terms-and-condition">
                          <FiberManualRecordIcon style={{fontSize:"small"}}/> Terms & Conditions

                          </a>
                        </li>
                        <li>
                        <a href="/privacy-policy">
                          <FiberManualRecordIcon style={{fontSize:"small"}}/> Privacy Policy

                          </a>
                        </li>
                    
                        <li>
                        <a href="https://www.google.com/maps/dir//Nilachaldham+Besalia/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3a1f45addc8a3765:0xa93028411e8f687c?sa=X&ved=2ahUKEwj9gM6TqYyEAxVGTWcHHb8GDlIQ9Rd6BAhNEAA">
                          <FiberManualRecordIcon style={{fontSize:"small"}}/> Site Map</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="footer-widget">
                    <div className="footer-menu ">
                      <h4 className="footer-widget-title">Our Newsletter</h4>
                      <ul className="footerul" style={{paddingLeft:"0px"}}>
                        <li>
                          <p>Spread the message of Lord Krishna which was given to</p>
                        </li>
                        <li className="footeremailli">
                         <div><input className="footeremailinput" type="text" placeholder="Email"/></div>
                         <div className="footertelegramicondiv"><TelegramIcon/></div>
                        </li>
                        <li className="footercheckboxli">
                        <input className="footercheckbox" type="checkbox" id="yourCheckboxId" />
                           <label htmlFor="yourCheckboxId">I agreed that my submitted data is being collected and stored.</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mini-footer">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="copyright-text">
                    <p>
                      Copyright © 2024  Nilachaldham. All rights reserved. Developed by <a href="https://glowtechmor.com/">GlowTechMor</a>  
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* <div>
          <br />
        </div> */}
        <div
          style={{ textAlign: "center", fontSize: "20px", color: "#495468" }}
        ></div>
      </>
    </>
  );
};

export default Footer;
